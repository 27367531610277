<template>
    <!-- NOTES:
            - This component is controlled by the causes module of the store
            - some others have their own modules in store or are controlled by the non conformite module of the store
     -->
    <v-container fluid>
        <h2>Causes</h2>

        <!-- button new cause -->
        <div class="mb-2 mt-5">
            <v-layout row justify-left>
                <v-btn
                    color="green darken-2"
                    dark
                    @click="openCausesDialog(null)"
                >
                    Nouvelle cause
                </v-btn>
            </v-layout>
        </div>

        <!-- causes table -->
        <v-data-table
            :headers="causesHeaders"
            :items="getAllCauses"
            class="elevation-1"
        >
            <template v-slot:items="props">
                <td>{{ props.item.id }}</td>
                <td>{{ props.item.nom }}</td>
                <td>{{ props.item.createdAt }}</td>
                <td>
                    <v-icon class="mr-2" title="Éditer" @click="openCausesDialog(props.item.id)">edit</v-icon>
                    <v-icon title="Supprimer" @click="deleteCause(props.item.id)">delete</v-icon>
                </td>
            </template>
        </v-data-table>

        <!-- DIALOGS -->
        <v-dialog v-model="causesDialog" persistent max-width="500">
            <v-card>
                <v-card-title class="headline">Ajout - Cause</v-card-title>

                <v-card-text>
                    <v-text-field
                        label="Nom de la cause"
                        v-model="activeCauseName"
                        box
                    />
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>

                    <v-btn color="green darken-1" flat @click="saveCause">Enregistrer</v-btn>
                    <v-btn color="red darken-1" flat @click="closeCausesDialog">Fermer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import { mapGetters, mapMutations, mapActions } from 'vuex';

    export default {
        name: "ListeCauses",
        async mounted() {
            //fetching all causes
            await this.fetchCauses();
        },
        data: function() {
            return {
                //dialog v-model
                causesDialog: false,

                //name of cause being created or modified (text field v-model)
                activeCauseName: '',

                //v-data-table headers
                causesHeaders: [
                    {
                        text: 'Id',
                        value: 'id',
                        sortable: true,
                        class: 'headers-lightgray'
                    },
                    {
                        text: 'Nom',
                        value: 'nom',
                        sortable: true,
                        class: 'headers-lightgray'
                    },
                    {
                        text: 'Date création',
                        value: 'createdAt',
                        sortable: true,
                        class: 'headers-lightgray'
                    },
                    {
                        text: 'Actions',
                        value: 'actions',
                        class: 'headers-lightgray'
                    },
                ],
            }
        },
        computed: {
            //VUEX
            ...mapGetters(['getAllCauses', 'getActiveCause']),
        },
        methods: {
            //VUEX
            ...mapMutations(['setActiveCause']),

            ...mapActions([
                'fetchCauses',
                'storeCause',
                'editCause',
                'destroyCause']
            ),

            //CAUSES
            //opens edit dialog
            openCausesDialog(id) {
                this.setActiveCause(id);

                if(!!id) {
                    this.activeCauseName = this.getActiveCause.nom;
                }

                //opens dialog
                this.causesDialog = true;
            },

            //closes edit dialog
            closeCausesDialog() {
                //resets dialog fields
                this.activeCauseName = '';

                //closes dialog
                this.causesDialog = false;
            },

            //saves cause
            async saveCause() {
                let category = {
                    id: this.getActiveCause.id,
                    nom: this.activeCauseName,
                };

                //editing cause
                !!this.getActiveCause.id && await this.editCause(category);
                //storing cause
                !this.getActiveCause.id && await this.storeCause(category);

                //closes dialog
                this.closeCausesDialog();
            },

            //deletes cause
            async deleteCause(id) {
                if(confirm("Êtes-vous sûre de vouloir supprimer cette categorie ?")) {
                    await this.destroyCause(id);
                }
            },
        },
    }
</script>

