const utils = {
    findFromList: findFromList,
    numberFormat: numberFormat,
    copyObject: copyObject,
    getFileExtension: getFileExtension
};

function findFromList(list, keyword) {
    return list.filter((el) => search(el, keyword));
}

function numberFormat(number){
    let int = new Intl.NumberFormat();
    return int.format(number);
}

function copyObject(obj){
    if (obj != null) {
        return JSON.parse(JSON.stringify(obj));
    }
}

function getFileExtension(filename){
    return filename.substring(filename.lastIndexOf(".")+1);
}

function search(el, keyword) {
    const type = Array.isArray(el) ? 'array' : typeof el;

    if( el === null){
        return false;
    }
    switch (type) {
        case 'string':
            return searchInText(el, keyword);
        case 'number':
            return searchInText(el, keyword);
        case 'boolean':
            return searchInText(el, keyword);
        case 'array':
            return searchInArray(el, keyword);
        case 'object':
            return searchInObject(el, keyword);
        default:
            return false;
    }
}

function searchInText(text, keyword) {
    return (text.toString().toLowerCase().indexOf(keyword) !== -1);
}

function searchInObject(obj, keyword) {
    return searchInArray(Object.values(obj), keyword);
}
function searchInArray(arr, keyword) {
    return arr.find((el) => search(el, keyword)) !== undefined;
}

export default utils;
