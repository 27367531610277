import $axios from '../../api';

const state = {
    //all departments
    departments: [],

    //current active department
    activeDepartment: {},

    //default department object
    defaultDepartment: {
        parentId: null,
        nom: '',
    },
};

const getters = {
    //gets list of all departments
    getAllDepartments(state) {
        return state.departments;
    },
    //gets current active departments
    getActiveDepartment(state) {
        return state.activeDepartment;
    },
};

const mutations = {
    //sets list of all departments
    setDepartments(state, departments) {
        state.departments = departments;
    },
    //sets the current department
    setActiveDepartment(state, id) {
        if(!!id) {
            state.activeDepartment = state.departments.find(c => c.id === id);
        }
        else {
            state.activeDepartment = JSON.parse(JSON.stringify(state.defaultDepartment));
        }
    },
    //adds department to list when storing a new one
    addDepartment(state, cause) {
        state.departments.push(cause);
    },
    //modifies a department in the list
    modifyDepartment(state, department) {
        const departments = [...state.departments];
        const index = state.departments.findIndex(d => d.id === department.id);
        departments[index] = department;
        state.departments = departments;
    },
    //removes department of lists after deleting one
    removeDepartment(state, department) {
        state.departments = state.departments.filter(c => c.id !== department.id);
    },
};

const actions = {
    //fetches list of all departments (sub departments included)
    async fetchDepartments({ commit }) {
        try {
            const { data } = await $axios.get('nc/departments/all');
            commit('setDepartments', data.departments);
        } catch(error) {
            console.log(error);
        }
    },

    //stores a new department
    async storeDepartment({ commit }, department) {
        try {
            const { data } = await $axios.post('nc/departments', department);
            commit('addDepartment', data.department);
        } catch (error) {
            console.log(error);
        }
    },

    //modifies an existing department
    async editDepartment({ commit }, department) {
        try {
            const { data } = await $axios.put(`nc/departments/${department.id}`, department);
            commit('modifyDepartment', data.department);
        } catch (error) {
            console.log(error);
        }
    },

    //deletes a department
    async destroyDepartment({ commit }, id) {
        try {
            const { data } = await $axios.delete(`nc/departments/${id}`);
            commit('removeDepartment', data.department);
        } catch (error) {
            console.log(error);
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
