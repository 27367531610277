import $axios from '../../api';

const state = {
    //all causes
    causes: [],

    //current active cause
    activeCause: {},

    //default cause object
    defaultCause: {
        nom: '',
    },
};

const getters = {
    //gets list of all causes
    getAllCauses(state) {
        return state.causes;
    },
    //gets current active cause
    getActiveCause(state) {
        return state.activeCause;
    },
};

const mutations = {
    //sets list of all causes
    setCauses(state, causes) {
        state.causes = causes;
    },
    //sets the current cause
    setActiveCause(state, id) {
        if(!!id) {
            state.activeCause = state.causes.find(c => c.id === id);
        }
        else {
            state.activeCause = JSON.parse(JSON.stringify(state.defaultCause));
        }
    },
    //adds cause to list when storing a new one
    addCause(state, cause) {
        state.causes.push(cause);
    },
    //modifies a cause in the list
    modifyCause(state, cause) {
        const causes = [...state.causes];
        const index = state.causes.findIndex(c => c.id === cause.id);
        causes[index] = cause;
        state.causes = causes;
    },
    //removes cause of lists after deleting one
    removeCause(state, cause) {
        state.causes = state.causes.filter(c => c.id !== cause.id);
    },
};

const actions = {
    //fetches list of all causes
    async fetchCauses({ commit }) {
        try {
            const { data } = await $axios.get('nc/causes');
            commit('setCauses', data.causes);
        } catch(error) {
            console.log(error);
        }
    },

    //stores a new cause
    async storeCause({ commit }, cause) {
        try {
            const { data } = await $axios.post('nc/causes', cause);
            commit('addCause', data.cause);
        } catch (error) {
            console.log(error);
        }
    },

    //modifies an existing cause
    async editCause({ commit }, cause) {
        try {
            const { data } = await $axios.put(`nc/causes/${cause.id}`, cause);
            commit('modifyCause', data.cause);
        } catch (error) {
            console.log(error);
        }
    },

    //deletes a cause
    async destroyCause({ commit }, id) {
        try {
            const { data } = await $axios.delete(`nc/causes/${id}`);
            commit('removeCause', data.cause);
        } catch (error) {
            console.log(error);
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
