<template>
    <nav>
        <Snackbar/>
        <v-toolbar app>
            <v-toolbar-side-icon @click="drawer = !drawer" id="btn_menu"/>

            <v-toolbar-title class="hidden-sm-and-down">
                <v-list-tile class="test" active-class="test-active" :to="home.link" id="home-link">
                   <v-img :src="images.logo" width="75"/>
                </v-list-tile>
            </v-toolbar-title>
            <v-spacer/>

            <v-toolbar-title>
                <v-btn v-if="loggedIn" color="login" fab small dark>{{ initials }}</v-btn>
            </v-toolbar-title>

            <v-toolbar-title v-if="loggedIn">
                <v-menu :nudge-width="75"
                        content-class="dropdown-menu"
                        offset-y
                        transition="slide-y-transition">

                    <template v-slot:activator="{ on }">
                        <v-toolbar-title v-on="on" class="pa-3">
                            <v-badge overlap color="orange">
                                <template v-slot:badge>
                                    <span v-if="nbNewNotifications > 0">{{ nbNewNotifications }} </span>
                                </template>
                            <v-icon class="icon-link" @click="pushNotifications">notifications</v-icon>
                            </v-badge>
                        </v-toolbar-title>
                    </template>

                    <v-list dense>
                        <v-list-tile v-for="notification in recentesNotification"
                            :key="notification.id"
                            @click="" class="notifications-item"
                            :href="`/nc/edit/${notification.data.nonConformiteId}`"
                        >
                            <v-list-tile-title v-text="notification.data.description" class="hidden-sm-and-down"/>
                            <v-list-tile-title v-text="`NC : ${!!notification.data.nonConformite ? notification.data.nonConformite.numeroNc : '' }`" class="hidden-md-and-up"/>
                        </v-list-tile>
                    </v-list>
                </v-menu>
            </v-toolbar-title>

            <v-toolbar-title>
                <v-btn id="button-quit" flat class="btn-exit" right @click="loggedOut" v-if="loggedIn">
                    <v-icon class="hidden-md-and-up" right>exit_to_app</v-icon>
                    <span class="hidden-sm-and-down">Quitter</span>
                </v-btn>
            </v-toolbar-title>

        </v-toolbar>

        <v-navigation-drawer id="drawer" app v-model="drawer">
          <v-toolbar flat>
            <v-list>
              <v-list-tile>
                <v-list-tile-title class="title">
                  Stageline - GNC
                </v-list-tile-title>
                  <v-toolbar-side-icon @click="drawer = !drawer" class="hidden-md-and-up"/>
              </v-list-tile>
            </v-list>
          </v-toolbar>

          <v-divider/>

          <v-list dense class="pt-0" v-if="loggedIn">
            <v-list-tile
              v-for="item in items"
              :key="item.title"
              :to="getLink(item.link)"
              active-class="drawer-activeLink"
              :class="item.link.name === $route.name ? 'drawer-activeLink' : 'drawer'"
              :id="`menu-${item.link.name}`"
            >
              <v-list-tile-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-tile-action>

              <v-list-tile-content>
                <v-list-tile-title>{{ item.title }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-navigation-drawer>
    </nav>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';
    import { LOGIN_STORE } from "../../constants";
    import Snackbar from './Snackbar';

    export default {
        name: "Navbar",

        data() {
            return {
                drawer: false,
                home: {
                    link: { name: 'dashboard'}
                },
                images: {
                    logo: require("../../assets/gnc_fulltext_logo_50px.png")
                }
            }
        },

        components: {
            Snackbar
        },

        computed: {
            ...mapState({
                loginStore: state => state.loginStore,
                nonConformiteStore: state => state.nonConformiteStore
            }),

            ...mapGetters({
                loggedIn: LOGIN_STORE.getters.LOGGED_IN,
                items: LOGIN_STORE.getters.GET_NAV_ITEMS,
                initials: LOGIN_STORE.getters.GET_INITIALS
            }),

            nbNewNotifications(){
                return this.nonConformiteStore.listeNotifications.filter(n => {
                    return !n.read_at;
                }).length;
            },

            //On affiche les 5 plus récentes notifications dans la navbar
            recentesNotification(){
                return this.nonConformiteStore.listeNotifications.slice(0,5);
            },
        },

        methods: {
            loggedOut(){
                this.$store.commit(LOGIN_STORE.actions.LOGGED_OUT, null, {root: true})
                this.$router.push({ name: 'home'})
            },

            pushNotifications(){
                this.$router.push({ name: 'notifications'})
            },

            getLink(link) {
                if (link.name === 'create' && this.nonConformiteStore.mode === 'maintenance') {
                    return '/nc/create?mode=maintenance';
                }
                return link;
            }
        }
    }
</script>

<style scoped>
    .icon-link{
        cursor: pointer;
    }
    .header-link{
        font-size: 24px;
        color: black;
    }
</style>
