<template>
    <v-container fluid>

        <h2 class="mb-1"> Usagers </h2>
        <div class="mb-4">
            <v-layout row justify-left>
                <v-dialog v-model="dialog" persistent max-width="500">
                    <template v-slot:activator="{ on }">
                        <v-btn color="green darken-2" dark v-on="on">Ajout - Utilisateur </v-btn>
                    </template>
                    <v-card>
                        <v-card-title class="headline">Ajout - Utilisateur</v-card-title>
                        <v-card-text>
                            <v-flex>
                                <v-autocomplete v-model="userToAdd" :items="entrepriseStore.noneGncUsers"
                                                label="Nom :" item-value="id" item-text="fullName" clearable>
                                </v-autocomplete>

                                <v-select
                                    v-model="listeGroupesToAdd"
                                    :items="entrepriseStore.groupes"
                                    item-text="description"
                                    item-value="id"
                                    :menu-props="{ maxHeight: '400' }"
                                    label="Choix des groupes"
                                    multiple
                                ></v-select>
                            </v-flex>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" flat :disabled="ajoutUserValid" @click="addNewUser">Enregister</v-btn>
                            <v-btn color="red darken-1" flat @click="dialog = false">Fermer</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-layout>
        </div>

        <!--Table - Liste des usagers-->
        <div style="overflow-x:auto;">
            <table>
                <thead>
                    <tr>
                        <th class="">Usager</th>
                        <th v-for="groupe in entrepriseStore.groupes" :key="groupe.id">
                            {{ groupe.description }}
                        </th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in entrepriseStore.users" :key="user.id">
                        <td class="">{{ user.fullName }}</td>
                        <td class="" v-for="groupe in entrepriseStore.groupes" :key="groupe.id" @click="toggleGroup(user,groupe)">
                            <v-icon color="green" v-if="userIsInGroup(user, groupe)">check_circle</v-icon>
                            <v-icon color="red" v-else>block</v-icon>
                        </td>
                        <td>
                            <v-btn flat icon color="red darken-1" @click="deleteUser(user.id)">
                                <v-icon>clear</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!--Fin Table - Liste des usagers-->
    </v-container>
</template>

<script>
    import { ENTREPRISE_STORE } from "../../constants";
    import { mapState } from 'vuex';

    export default {
        name: "ListeUsagers",
        computed: {
            ...mapState({
                entrepriseStore: state => state.entrepriseStore,
            }),

            ajoutUserValid(){
                return !(this.listeGroupesToAdd.length > 0 && this.userToAdd !== null);
            }
        },

        data(){
            return {
                dialog: false,
                userToAdd: null,
                listeGroupesToAdd: [],
            }
        },

        methods: {
            deleteUser(userId){
                let user = this.entrepriseStore.users.find( u => u.id == userId);
                if(confirm(`Confirmation de suppresion de l'utilisateur | ${user.fullName} ?`)){
                    this.$store.dispatch(ENTREPRISE_STORE.actions.DELETE_USER, user).then( response => {
                        this.$forceUpdate();
                    });
                }
            },
            userIsInGroup(user, groupe) {
                return !!user.groups.find(g => {
                    return g.id === groupe.id;
                });
            },
            toggleGroup(user, groupe) {
                let data = {
                    userId: user.id,
                    groupeId: groupe.id
                };

                if(this.userIsInGroup(user, groupe)){
                    this.$store.dispatch(ENTREPRISE_STORE.actions.REMOVE_USER_FROM_GROUP, data).then( response => {
                        this.$forceUpdate();
                    });
                } else {
                    this.$store.dispatch(ENTREPRISE_STORE.actions.ADD_USER_TO_GROUP, data).then( response => {
                        this.$forceUpdate();
                    });
                }


            },
            addNewUser(){
                let data = {
                    userId: this.userToAdd,
                    groups: this.listeGroupesToAdd
                };
                this.$store.dispatch(ENTREPRISE_STORE.actions.ADD_USER_TO_APPLICATION, data).then( response => {
                    this.userToAdd = null;
                    this.listeGroupesToAdd = [];
                    this.$forceUpdate();
                    this.dialog = false;
                });
            }
        }
    }
</script>

<style scoped>
    table {
        border-collapse: collapse;
    }
    table {
        border: 1px solid black;
    }
    table, th, td {
        border: 1px solid black;
    }
    th {
        text-align: center;
    }
    td {
        height: 50px;
        vertical-align: bottom;
    }
    th, td {
        padding: 15px;
        text-align: center;
    }
    th, td {
        border-bottom: 1px solid #ddd;
    }
    tr:hover {background-color: #8def75;}
    th {
        background-color: #4CAF50;
        color: white;
    }
    td:hover{
        cursor: pointer
    }
    .error-msg {
        color: red;
        font-weight: bold;
    }
</style>
