//import $ from "jquery";
const subDomain = process.env.VUE_APP_SUBDOMAIN != null ? process.env.VUE_APP_SUBDOMAIN : '';

export const ENV = Object.freeze({
    API_URL: 'https://' + subDomain + 'gnc.groupestageline.com/api/v2',
    URL: 'https://' + subDomain + 'gnc.groupestageline.com',
    //CSRF_TOKEN: $('meta[name=csrf-token]').attr('content'),
    APPLICATION_ID: 2,
    ERROR_CODE_CONNECTION_TIMEOUT: 'ECONNABORTED',
    ERROR_TOKEN_EXPIRED: 'Token is Expired',
    ERROR_TOKEN_NOT_FOUND: 'Authorization Token not found'
});

export const APPLICATION_STORE = Object.freeze( {
   actions: {
       AFFICHER_MESSAGE: 'afficherMessage'
   }
});

export const LOGIN_STORE = Object.freeze({
    actions: {
        VALIDATE_LOGIN: 'validateLogin',
        GET_USER: 'getUser',
        LOGGED_OUT: 'loggedOut',
        FETCH_USER_INFOS: 'fetchUserInfos',
    },
    getters: {
        LOGGED_USER: 'loggedUser',
        LOGGED_IN: 'loggedIn',
        IS_GNC_ADMIN: 'isGncAdmin',
        IS_GNC_QUALITE: 'isGncQualite',
        IS_GNC_SUPERVISOR: 'isGncSupervisor',
        IS_GNC_USER: 'isGncUser',
        GET_NAV_ITEMS: 'getNavItems',
        GET_INITIALS: 'loggedUserInitials',
        GET_ACCESS_TOKEN: 'getAccessToken'
    },
    mutations: {
        UPDATE_TOKEN_INFOS: 'updateTokenInfo',
        UPDATE_LOGIN_INFOS: 'updateLoginInfos'
    },
});

export const NON_CONFORMITES_STORE = Object.freeze({
    getters: {
        GET_CAUSE_EMPLOYE: 'getCauseEmploye',
        GET_CAUSE_FOURNISSEUR: 'getCauseFournisseur',
        GET_LISTE_NON_CONFORMITES : 'getListeNonConformites',
        GET_LISTE_NON_CONFORMITES_PAR_ETAT: 'getListeNonConformitesByEtat',
        GET_LISTE_NON_CONFORMITES_ARCHIVES: 'getListeNonConformitesArchives',
        GET_NON_CONFORMITE_BY_ID: 'getNonConformiteByID',
        GET_NON_CONFORMITE_ETATS: 'getNonConformiteEtats',
        GET_ETAT_LABEL: 'getEtatLabel',
        GET_LISTE_NOTIFICATIONS: 'getListeNotifications'
    },
    actions: {
        AJOUTER_NON_CONFORMITE: 'ajouterNonConformites',
        AJOUTER_NOTE_NON_CONFORMITE: 'ajouterNoteNonConformites',
        AJOUTER_IMAGES_NON_CONFORMITE: 'ajouterImageNonConformites',
        AJOUTER_FICHIERS_NON_CONFORMITES: 'ajouterFichiersNonConformite',
        AJOUTER_NOUVELLE_CATEGORIE: 'ajouterNouvelleCategorie',
        AJOUTER_NOUVELLE_CAUSE: 'ajouterNouvelleCause',
        APPLY_TRANSITION: 'applyTransition',
        SET_NOTIFICATIONS_READ_STATUS: 'setNotificationsReadStatus',
        FECTH_NON_CONFORMITE_BY_ID: 'fetchNonConformiteById',
        FETCH_LISTE_NON_CONFORMITES: 'fetchListeNonConformites',
        FETCH_LISTE_NON_CONFORMITES_ARCHIVES: 'fetchListeNonConformitesArchives',
        FETCH_PARAMS_NON_CONFORMITE: 'fetchParamsNonConformite',
        FETCH_LISTE_NOTIFICATIONS: 'fetchListeNotifications',
        ABONNER_USER_NON_CONFORMITE: 'abonnerUserNonConformite',
        DESABONNER_USER_NON_CONFORMITE: 'desabonnerUserNonConformite',
        RESET_LISTE_NON_CONFORMITES_ARCHIVES: 'resetListeNonConformitesArchives'
    },
    etats: {
        DEMANDE: 'demande',
        DEMANDE_REJETEE: 'demande_rejetee',
        ATTENTE_SOLUTION: 'attente_solution',
        SOLUTION_REJETEE: 'solution_rejetee',
        SOLUTION_ACCEPTEE: 'solution_acceptee',
        TRAVAIL_A_APPROUVER: 'travail_a_approuver',
        TRAVAIL_REJETE: 'travail_rejete',
        TRAVAIL_APPROUVE: 'travail_approuve'
    },
    transitions: {
        APPROUVER_DEMANDE: 'approuver_demande',
        APPROUVER_DEMANDE_NO_SOLUTION: 'approuver_demande_no_solution',
        REJETER_DEMANDE: 'rejeter_demande',
        ACCEPTER_SOLUTION: 'accepter_solution',
        REJETER_SOLUTION: 'rejeter_solution',
        SOUMETTRE_TRAVAIL: 'soumettre_travail',
        APPROUVER_TRAVAIL: 'approuver_travail',
        REJETER_TRAVAIL: 'rejeter_travail',
    }
});

export const ENTREPRISE_STORE = Object.freeze({
    actions: {
        FETCH_DATA_DEPARTEMENTS_ENTERPRISE_STORE: 'fetchDataDepartementsEntrepriseStore',
        FETCH_DATA_PROJECT_NUMBER: 'fetchDataProjectNumber',
        FETCH_FUTURE_PROJECTS: 'fetchFutureProjects',
        FETCH_STAGE_UNITS: 'fetchStageUnits',
        FETCH_STAGES: 'fetchStages',
        FETCH_EMPLOYEES: 'fetchEmployees',
        FETCH_SUPPLIERS: 'fetchSuppliers',
        FETCH_GROUPS: 'fetchGroups',
        FETCH_USERS: 'fetchUsers',
        FETCH_NONE_GNC_USERS: 'fetchNoneGncUsers',
        ADD_USER_TO_GROUP: 'addUserToGroup',
        ADD_USER_TO_APPLICATION: 'addUserToApplication',
        DELETE_USER: 'deleteUser',
        REMOVE_USER_FROM_GROUP: 'removeUserFromGroup'
    },
    getters: {
        GET_DEPARTEMENTS_BY_PARENT: 'getDepartementsByParent',
        GET_PARENT_DEPARTEMENTS: 'getParentDepartements',
        GET_DEPARTEMENT: 'getDepartement',
        GET_LISTE_SOUS_DEPARTEMENT: 'getListeSousDepartement',
        GET_STAGE_UNIT_BY_ID: 'getStageUnitByID',
        GET_GROUPS: 'getGroups',
        GET_USERS: 'getUsers',
        GET_STAGE_MODEL_NAME: 'getStageModelName',
        GET_STAGE_UNIT_NAME: 'getStageUnitName',
    }
});


export default {
    APPLICATION_STORE, LOGIN_STORE, NON_CONFORMITES_STORE, ENTREPRISE_STORE, ENV
}
