//this is similar to lodash _.get()
//will return the value of a prop from an object based on a string (dot notation)
function getProp(object, keys, defaultVal) {
    keys = Array.isArray(keys) ? keys : keys.split('.');
    object = object[keys[0]];
    if(object != null && keys.length > 1){
        return getProp(object, keys.slice(1), defaultVal);
    }
    return object == null ? defaultVal : object;
}

export default {
    methods: {
        //global filter function for all v-data-table index
        filterTableData(array, headers) {
            //initialize the filtered data to a pure copy of the original array
            let filteredData = [...array];

            //each header must define a search term, and all filters are applied one after the other on the array
            headers.forEach(header => {
                filteredData = filteredData.filter(item => {
                    if (header.search != null && header.search.length > 0) {
                        //get the value of the prop
                        const property = getProp(item, header.value, '');
                        //what's missing is the multiple strings and special characters
                        if (property != null) {
                            return property.toString().toLowerCase().includes(header.search.toLowerCase());
                        }

                    }
                    //else apply no filter
                    return true;
                });
            });

            return filteredData;
        },
    }
};
