<template>
    <TagsTable
        :useAsSelector="false"
    />
</template>

<script>
    import TagsTable from '../controls/TagsTable';

    export default {
        name: "IndexTags",
        components: { TagsTable },
    }
</script>
