<template>
    <!-- NOTES:
            - Categories have been renamed to Détection Des Non-Conformités but are still called categories in the code(we only changed the labels)
            - This component is controlled by the categories module of the store
            - Some others have their own modules in store or are controlled by the non conformite module of the store
     -->
    <v-container fluid>
        <h2>Détections des non-conformités</h2>

        <!-- button new category -->
        <div class="mb-2 mt-5">
            <v-layout row justify-left>
                <v-btn
                    color="green darken-2"
                    dark
                    @click="openCategoriesDialog(null)"
                >
                    Nouvelle détection de non-conformités
                </v-btn>
            </v-layout>
        </div>

        <!-- categories table -->
        <v-data-table
            :headers="categoriesHeaders"
            :items="getAllCategories"
            class="elevation-1"
        >
            <template v-slot:items="props">
                <td>{{ props.item.id }}</td>
                <td>{{ props.item.nom }}</td>
                <td>{{ props.item.createdAt }}</td>
                <td>
                    <v-icon class="mr-2" title="Éditer" @click="openCategoriesDialog(props.item.id)">edit</v-icon>
                    <v-icon title="Supprimer" @click="deleteCategory(props.item.id)">delete</v-icon>
                </td>
            </template>
        </v-data-table>

        <!-- DIALOGS -->
        <v-dialog v-model="categoriesDialog" persistent max-width="500">
            <v-card>
                <v-card-title class="headline">Ajout - Détection de non-conformités</v-card-title>

                <v-card-text>
                    <v-text-field
                        label="Nom de la détection de non-conformités"
                        v-model="activeCategoryName"
                        box
                    />
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>

                    <v-btn color="green darken-1" flat @click="saveCategory">Enregistrer</v-btn>
                    <v-btn color="red darken-1" flat @click="closeCategoriesDialog">Fermer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import { mapGetters, mapMutations, mapActions } from 'vuex';

    export default {
        name: "ListeCategories",
        async mounted() {
            //fetching all categories
            await this.fetchCategories();
        },
        data: function() {
            return {
                //dialog v-model
                categoriesDialog: false,
                //name of category being created or modified (text field v-model)
                activeCategoryName: '',

                //v-data-table headers
                categoriesHeaders: [
                    {
                        text: 'Id',
                        value: 'id',
                        sortable: true,
                        class: 'headers-lightgray'
                    },
                    {
                        text: 'Nom',
                        value: 'nom',
                        sortable: true,
                        class: 'headers-lightgray'
                    },
                    {
                        text: 'Date création',
                        value: 'createdAt',
                        sortable: true,
                        class: 'headers-lightgray'
                    },
                    {
                        text: 'Actions',
                        value: 'actions',
                        class: 'headers-lightgray'
                    },
                ],
            }
        },
        computed: {
            //VUEX
            ...mapGetters(['getAllCategories', 'getActiveCategory']),
        },
        methods: {
            //VUEX
            ...mapMutations(['setActiveCategory']),

            ...mapActions([
                'fetchCategories',
                'storeCategory',
                'editCategory',
                'destroyCategory']
            ),

            //CATEGORIES
            //opens edit dialog
            openCategoriesDialog(id) {
                this.setActiveCategory(id);

                if(!!id) {
                    this.activeCategoryName = this.getActiveCategory.nom;
                }

                //opens dialog
                this.categoriesDialog = true;
            },

            //closes edit dialog
            closeCategoriesDialog() {
                //resets dialog fields
                this.activeCategoryName = '';

                //closes dialog
                this.categoriesDialog = false;
            },

            //saves corrective action
            async saveCategory() {
                let category = {
                    id: this.getActiveCategory.id,
                    nom: this.activeCategoryName,
                };

                //editing category
                !!this.getActiveCategory.id && await this.editCategory(category);
                //storing category
                !this.getActiveCategory.id && await this.storeCategory(category);

                //closes dialog
                this.closeCategoriesDialog();
            },

            //deletes category
            async deleteCategory(id) {
                if(confirm("Êtes-vous sûre de vouloir supprimer cette categorie ?")) {
                    await this.destroyCategory(id);
                }
            },
        },
    }
</script>

<style scoped>

</style>
