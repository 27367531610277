<template>
    <div>
        <v-layout row>
            <!--DateDébut-->
            <v-flex xs12 sm6 md4 lg4>
                <v-dialog
                ref="dialogDebut"
                v-model="dialogDateDebut"
                :return-value.sync="applicationStore.datesDebutFin.dateDebut"
                persistent
                lazy
                full-width
                width="290px"
            >
                <template v-slot:activator="{ on }">
                    <v-text-field
                        v-model="applicationStore.datesDebutFin.dateDebut"
                        label="Début de la période"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker v-model="applicationStore.datesDebutFin.dateDebut" scrollable locale="fr-CA">
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="dialogDateDebut = false">Cancel</v-btn>
                    <v-btn flat color="primary" @click="$refs.dialogDebut.save(applicationStore.datesDebutFin.dateDebut)">OK</v-btn>
                </v-date-picker>
            </v-dialog>
            </v-flex>

            <!--DateFin-->
            <v-flex xs12 sm6 md4 lg4>
            <v-dialog
                ref="dialog"
                v-model="dialogDateFin"
                :return-value.sync="applicationStore.datesDebutFin.dateFin"
                persistent
                lazy
                full-width
                width="290px"
            >
                <template v-slot:activator="{ on }">
                    <v-text-field
                        v-model="applicationStore.datesDebutFin.dateFin"
                        label="Fin de la période"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker v-model="applicationStore.datesDebutFin.dateFin" scrollable locale="fr-CA">
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="dialogDateFin = false">Cancel</v-btn>
                    <v-btn flat color="primary" @click="$refs.dialog.save(applicationStore.datesDebutFin.dateFin)">OK</v-btn>
                </v-date-picker>
            </v-dialog>
        </v-flex>

            <!--ListeChoixDePeriode-->
            <v-flex xs12 md4 md6 lg6 ml-2>
                <v-select
                    v-model="choixPeriode"
                    :items="items"
                    label="Choix de période..."
                    item-text="nom"
                    item-value="id"
                    @change="changeChoixPeriode"
                ></v-select>
            </v-flex>

        </v-layout>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import moment from 'moment';

    export default {
        name: "DatesDebutFin",
        computed: {
            ...mapState({
                applicationStore: state => state.applicationStore,
            }),
        },
        data(){
            return {
                dialogDateDebut: false,
                dialogDateFin: false,
                choixPeriode: null,
                items: [
                    {
                        id: 0,
                        nom: ''
                    },
                    {
                        id: 1,
                        nom: 'Semaine passée'
                    },
                    {
                        id: 2,
                        nom: '2 dernières semaines'
                    },
                    {
                        id: 3,
                        nom: 'Le mois passé'
                    },
                    {
                        id: 4,
                        nom: '3 derniers mois'
                    },
                    {
                        id: 5,
                        nom: '6 derniers mois'
                    },
                    {
                        id: 6,
                        nom: '9 derniers mois'
                    }
                ]
            }
        },
        methods: {
            changeChoixPeriode(){
                let number = null;
                let str = null;
                switch(this.choixPeriode){
                    case 1:
                        number = 7;
                        str = 'days';
                        break;
                    case 2:
                        number = 14;
                        str = 'days';
                        break;
                    case 3:
                        number = 1;
                        str = 'months';
                        break;
                    case 4:
                        number = 3;
                        str = 'months';
                        break;
                    case 5:
                        number = 6;
                        str = 'months';
                        break;
                    case 6:
                        number = 9;
                        str = 'months';
                        break;
                }

                this.applicationStore.datesDebutFin.dateDebut = moment().subtract(number, str).format('YYYY-MM-DD');
                this.applicationStore.datesDebutFin.dateFin = moment().format('YYYY-MM-DD');
            }
        }
    }
</script>

<style scoped>

</style>
