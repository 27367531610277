<template>
    <div>
        <!-- add new members autocomplete -->
        <v-autocomplete
            v-model="abonnementNouvelUtilisateur"
            label="Choix de l'utilisateur"
            :items="users"
            item-value="id"
            item-text="fullName"
            @change="ajouterAbonne"
            clearable
            :readonly="readonly"
        />

        <!-- shows all members -->
        <div class="text-xs-center">
            <v-chip
                v-for="user in this.abonnes"
                :key="user.id"
                color="primary"
                close
                text-color="white"
                @input="supprimerAbonne(user.id)"
            >
                {{user?.fullName}}
            </v-chip>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: "NC-Abonnements",
        props: ['nonConformite', 'type', 'readonly', 'items', 'users'],
        computed: {
            ...mapState({
                loginStore: state => state.applicationStore,
                nonConformiteStore: state => state.nonConformiteStore,
                entrepriseStore: state => state.entrepriseStore
            }),
        },
        data() {
            return {
                abonnementNouvelUtilisateur: null,
                abonnes: [],
            }
        },
        mounted() {
            this.abonnes = this.items;
        },
        methods: {
            //VUEX
            ...mapActions([
                //non conformites store
                'abonnerUserNonConformite',
                'desabonnerUserNonConformite',
            ]),

            //adds a member
            ajouterAbonne(){
                //if there is a user to add has a member
                if(this.abonnementNouvelUtilisateur != null){
                    //checks if selected user is already a member
                    let found = this.abonnes.find( user => user.id === this.abonnementNouvelUtilisateur);

                    //if selected user is not a member yet
                    if(found === undefined){
                        //adds selected user to NC members list
                        this.setAbonnementNonconformite(this.abonnementNouvelUtilisateur);
                    }
                }

                //resets selected user
                this.abonnementNouvelUtilisateur = null;
            },

            //adds selected user to NC members list
            setAbonnementNonconformite(userId){

                let abonnement = {
                    nonConformiteId: this.nonConformite.id,
                    userId: userId,
                    type: this.type
                };

                //si l'id de la non-conformité est null c'est qu'on n'a encore jamais sauvegardé
                if (this.nonConformite.id !== null) {
                    this.$store.dispatch('abonnerUserNonConformite', abonnement).then(response => {
                        //this.nonConformite.abonnes = response.data.nonConformite.abonnes;
                        if (this.type != null) {
                            this.abonnes = response.data.nonConformite.abonnesActionCorrective;
                        }
                        else {
                            this.abonnes = response.data.nonConformite.abonnes;
                        }
                        this.emitChange();
                    });
                }
                else {
                    let user = this.entrepriseStore.users.find(u => u.id === userId);

                    if (!!user) {
                        this.abonnes.push(user);
                        this.emitChange();
                    }
                }
            },

            //deletes selected subscribed user
            async supprimerAbonne(userId) {
                if (this.nonConformite.id == null) {
                    this.abonnes = this.abonnes.filter(a => a.id !== userId);
                }
                else {
                    //subscription to delete
                    let subscription = {
                        nonConformiteId: this.nonConformite.id,
                        userId: userId,
                        type: this.type
                    };

                    //deletes subscription
                    let nc = await this.desabonnerUserNonConformite(subscription);

                    //if subscription has been deleted successfully
                    if(!!nc) {
                        //if deleted subscription of type correctives actions
                        if (this.type != null) {
                            //feeds subscribers list with the nc correctives actions subscribers list
                            this.abonnes = nc.abonnesActionCorrective;
                        }
                        else {
                            //feeds subscribers list with the nc subscribers list
                            this.abonnes = nc.abonnes;
                        }

                        //emits new subscribers list
                        this.emitChange();
                    }
                }
            },

            emitChange() {
                this.$emit('change', this.abonnes);
            }
        },
        watch: {
            items(newVal, oldVal) {
                this.abonnes = newVal;
            }
        }
    }
</script>

<style scoped>

</style>
