<template>
    <v-container fluid>
        <!-- archives search checkbox -->
        <v-layout row wrap>
            <v-flex xs12 sm6 md3>
                <v-checkbox v-model="rechercheArchives" label="Inclure les résultats d'archives" @change="refreshArchives"/>
            </v-flex>
        </v-layout>

        <!-- search input -->
        <v-layout row wrap>
            <v-flex xs10 sm6 md3>
                <v-text-field label="Recherche" clearable clear v-model="inputSearch" @click:clear="clearSearch"/>
            </v-flex>
            <v-flex xs2>
                <v-btn @click="research" fab small class="my-1"><v-icon>search</v-icon></v-btn>
            </v-flex>
        </v-layout>

        <v-layout row class="hidden-sm-and-up">
            <v-btn fab @click="swipe('Left')"><v-icon>keyboard_arrow_left</v-icon></v-btn>
            <v-spacer/>
            <v-btn fab @click="swipe('Right')"><v-icon>keyboard_arrow_right</v-icon></v-btn>
        </v-layout>

        <!-- slider for card view -->
        <v-layout>
            <v-btn  class="hidden-sm-and-down" fab @click="swipe('Left')"><v-icon>keyboard_arrow_left</v-icon></v-btn>
            <v-tabs v-model="active" class="mt-3">
                <v-tab v-for="etat in etats" :key="etat.name" ripple >
                    {{ etat.label_fr }}
                </v-tab>
                <v-tab-item v-for="etat in etats" :key="etat.name" v-touch="{ right: () => swipe('Left'), left: () => swipe('Right') }">
                    <ListeCarteNonConformite :initialListe="etat.visible" :initialEtat="etat"/>
                </v-tab-item>
            </v-tabs>
            <v-btn fab class="hidden-sm-and-down" @click="swipe('Right')"><v-icon>keyboard_arrow_right</v-icon></v-btn>
        </v-layout>
    </v-container>
</template>

<script>
    import { mapState } from 'vuex'
    import { NON_CONFORMITES_STORE } from '../constants';
    import CarteNonConformitePaginator from './CarteNonConformitePaginator';
    import ListeCarteNonConformite from './ListeCarteNonConformite';
    import utils from '../utils';


    export default {
        components: {ListeCarteNonConformite, CarteNonConformitePaginator},

        async created(){
            const initData = await this.getInitData();
            this.initData();
        },

        mounted(){

        },

        data(){
            return {
                active: null,
                etats: [],
                inputSearch: "",
                rechercheArchives: false
            }
        },

        methods: {
            async getInitData(){
                const response = await Promise.all([this.$store.dispatch(NON_CONFORMITES_STORE.actions.FETCH_PARAMS_NON_CONFORMITE),
                    this.$store.dispatch(NON_CONFORMITES_STORE.actions.FETCH_LISTE_NON_CONFORMITES)]);
                return response;
            },
            initData(){
                this.etats = this.$store.getters[NON_CONFORMITES_STORE.getters.GET_NON_CONFORMITE_ETATS].filter( etat => {
                    return etat.name !== NON_CONFORMITES_STORE.etats.DEMANDE_REJETEE && etat.name !== NON_CONFORMITES_STORE.etats.TRAVAIL_APPROUVE && !etat.name.includes('maintenance')
                }).map( etat => {
                    let ncParEtat = this.$store.getters[NON_CONFORMITES_STORE.getters.GET_LISTE_NON_CONFORMITES_PAR_ETAT](etat.name);
                    etat.visible = ncParEtat;
                    etat.liste = ncParEtat;
                    return etat;
                });
            },
            swipe (direction) {
                const active = parseInt(this.active);
                switch (direction) {
                    case 'Left':
                        this.active = (active > 0 ? active - 1 : 0);
                        break;
                    case 'Right':
                        this.active = (active < this.etats.length-1 ? active + 1 : this.etats.length-1);
                        break;
                    default:
                        break;
                }
            },

            etatListeAllVisible(){
                this.etats.map( etat => {
                    etat.visible = etat.liste;
                });
            },

            clearSearch() {
                this.inputSearch = null;
                this.research();
            },

            research() {
                this.refreshArchives().then(() => {
                    if (this.inputSearch !== null){
                        let search = this.inputSearch.toString().toLowerCase();
                        this.etats.map( etat => {
                            etat.visible = utils.findFromList(etat.liste, search);
                        });
                    } else {
                        this.etatListeAllVisible();
                    }
                    this.$forceUpdate();
                });
            },

            refresh(){
                this.getInitData().then( r => {
                    this.initData();
                });
            },

            async refreshArchives() {
                if (this.rechercheArchives) {
                    if (this.inputSearch){
                        this.$store.dispatch(NON_CONFORMITES_STORE.actions.FETCH_LISTE_NON_CONFORMITES_ARCHIVES, this.inputSearch.toString().toLowerCase()).then(response => {
                            //flusher les etats archivés
                            this.etats = this.etats.filter(etat => {
                                return etat.name !== NON_CONFORMITES_STORE.etats.TRAVAIL_APPROUVE;
                            });
                            //récuperer la liste des archives
                            let listeNCArchives = this.$store.getters[NON_CONFORMITES_STORE.getters.GET_LISTE_NON_CONFORMITES_ARCHIVES];
                            //créer l'objet et l'ajouter au tableau
                            let etat = this.$store.getters[NON_CONFORMITES_STORE.getters.GET_NON_CONFORMITE_ETATS].find( etat => {
                                return etat.name === NON_CONFORMITES_STORE.etats.TRAVAIL_APPROUVE;
                            });
                            etat.visible = listeNCArchives;
                            etat.liste = listeNCArchives;
                            this.etats.push(etat);
                        });
                    }
                }
                else {
                    this.etats = this.etats.filter( etat => {
                        return etat.name !== NON_CONFORMITES_STORE.etats.DEMANDE_REJETEE && etat.name !== NON_CONFORMITES_STORE.etats.TRAVAIL_APPROUVE
                    })
                }
            }
        },

        computed: {
            ...mapState({
                nonConformiteStore: state => state.nonConformiteStore,
                loginStore: state => state.loginStore
            }),
        }
    }
</script>

<style scoped>
    .v-tabs {
        width: 100%;
    }
</style>
