<template>
    <div>
        <!--Rapport nombre par produit-->
        Filtres : <label v-if="errorDates"> <span class="errorMessage">{{ errorMessage }}</span></label>
        <v-layout row wrap>
            <v-flex xs12 sm4 md8 lg8>
                <DatesDebutFin></DatesDebutFin>
            </v-flex>
            <v-spacer></v-spacer>
            <v-btn color="success" :loading="loading" @click="fetchData">Appliquer</v-btn>
        </v-layout>

        <v-layout row wrap>
            <v-flex xs12>
                <v-card>
                    <v-card-text>
                        <GChart
                            type="PieChart"
                            :data="rapportNombreNcParDepartement.data"
                            :options="rapportNombreNcParDepartement.options"
                        />
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
        <!--Fin Rapport nombre par produit-->
    </div>
</template>

<script>
    import { GChart } from 'vue-google-charts';
    import $axios from '../../api';
    import DatesDebutFin from '../controls/DatesDebutFin';
    import { mapState } from 'vuex';

    export default {
        name: "NombreNcParDepartement",
        components: { GChart, DatesDebutFin },
        computed: {
            ...mapState({
                applicationStore: state => state.applicationStore,
            }),
        },
        data() {
            return {
                loading: false,
                errorDates: false,
                errorMessage: "",
                rapportNombreNcParDepartement: {
                    data: [['Département', 'Nombre']],
                    options: {
                        title: 'Nombre de non-conformites par département',
                        is3D: true,
                        height: 500,
                        legend:{
                            position: 'right'
                        },
                        sliceVisibilityThreshold: .01
                    }
                }
            }
        },
        methods: {
            async fetchData() {
                let vm = this;
                this.loading = true;

                if (!this.applicationStore.datesDebutFin.dateDebut || !this.applicationStore.datesDebutFin.dateFin) {
                    this.errorDates = true;
                    this.errorMessage = 'Veuillez sélectionner vos dates.';
                    this.loading = false;
                    return;
                } else {
                    this.errorDates = false;
                }
                try {
                    this.rapportNombreNcParDepartement.data = [['Département', 'Nombre']];
                    const dataRapportNombreNcParDepartement = await $axios.get('/nc/rapports/nombreNcParDepartement',
                        {
                            params: {
                                startDate: this.applicationStore.datesDebutFin.dateDebut,
                                endDate: this.applicationStore.datesDebutFin.dateFin
                            }
                        });
                    vm.rapportNombreNcParDepartement.data = vm.rapportNombreNcParDepartement.data
                        .concat(dataRapportNombreNcParDepartement.data.map(e => [e.departement + ` | ${e.nombre}`, e.nombre]));
                    this.loading = false;
                } catch (e) {
                    console.error(e);
                }
            }
        }
    }
</script>

<style scoped>

</style>
