export default {
    methods: {
        //insert the chosen corrective actions from the autocomplete into the proposed correctives actions text(actionCorrectiveProposee)
        insertIntoProposedCorrectiveActions(chosenCorrectiveAction) {
            //if proposed corrective action text is not empty
            if(!!this.nonConformite.actionCorrectiveProposee) {
                this.nonConformite.actionCorrectiveProposee = `${this.nonConformite.actionCorrectiveProposee} \n${chosenCorrectiveAction},`;
            }
            else {
                this.nonConformite.actionCorrectiveProposee = `${chosenCorrectiveAction},`;
            }
        },

        //sets if corrective action is required or not
        setCorrectiveActionRequired(isCorrectiveActionRequired) {
            this.nonConformite.actionCorrectiveRequise = isCorrectiveActionRequired;

            //NOTE: if corrective action is not required, we reset the proposed corrective action and corrective action members list
            if(!isCorrectiveActionRequired) {
                this.nonConformite.actionCorrectiveProposee = '';

                this.nonConformite.abonnesActionCorrective = [];
            }
        },

        //sets proposed corrective action
        setProposedCorrectiveAction(proposedCorrectiveAction) {
            this.nonConformite.actionCorrectiveProposee = proposedCorrectiveAction;
        },

        //sets if corrective action is completed or not
        setCorrectiveActionCompleted(isCorrectiveActionCompleted) {
            this.nonConformite.actionCorrectiveComplete = isCorrectiveActionCompleted;
        },
    },
};
