<template>
    <v-container fluid>
        <div class="viewTitle mb-2 pa-2">Rapports</div>
        <v-flex xs12 sm6 d-flex>
            <v-select
                v-model="rapport"
                :items="listeRapports"
                item-text="label"
                item-value="component"
                label="Sélectionnez un rapport :"
                box
            ></v-select>
        </v-flex>
        <component v-bind:is="currentRapport"></component>
    </v-container>
</template>

<script>
    import NombreNcParProduit from '../components/rapports/NombreNcParProduit';
    import CoutParProduit from '../components/rapports/CoutParProduit';
    import NombreNcParDepartement from '../components/rapports/NombreNcParDepartement';
    import CoutParDepartement from '../components/rapports/CoutParDepartement';
    import CoutNombreParSousDepartement from '../components/rapports/CoutNombreParSousDepartement';
    import CoutParDepartementColonnes from '../components/rapports/CoutParDepartementColonnes';
    import TopCoutNcParDepartement from '../components/rapports/TopCoutNcParDepartement';
    import { ENTREPRISE_STORE } from "../constants";

    export default {
        name: "Rapports",
        components: { NombreNcParProduit, CoutParProduit, NombreNcParDepartement, CoutParDepartement,CoutParDepartementColonnes,
            CoutNombreParSousDepartement, TopCoutNcParDepartement },
        computed: {
            currentRapport(){
                return !!this.rapport ? this.rapport : this.listeRapports[0].component;
            }
        },
        data () {
            return {
                listeRapports: [
                    {
                        id: 1,
                        label: 'Nombre de Non-Conformite par produit',
                        component: 'NombreNcParProduit'
                    },
                    {
                        id: 2,
                        label: 'Coût par produit',
                        component: 'CoutParProduit'
                    },
                    {
                        id: 3,
                        label: 'Nombre de Non-Conformite par département',
                        component: 'NombreNcParDepartement'
                    },
                    {
                        id: 4,
                        label: 'Coût par département',
                        component: 'CoutParDepartement'
                    },
                    {
                        id: 5,
                        label: 'Coût par département (Colonnes)',
                        component: 'CoutParDepartementColonnes'
                    },
                    {
                        id: 6,
                        label: 'Coût/Nombre par sous-département',
                        component: 'CoutNombreParSousDepartement'
                    },
                    {
                        id: 7,
                        label: 'TOP Coût par département',
                        component: 'TopCoutNcParDepartement'
                    },
                ],
                rapport: null,
            }
        },
        methods: {

        },
        mounted() {
            this.rapport = this.listeRapports[0].component;
            this.$store.dispatch(ENTREPRISE_STORE.actions.FETCH_DATA_DEPARTEMENTS_ENTERPRISE_STORE);
        }
    }
</script>

<style scoped>
</style>
