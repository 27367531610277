<template>
    <v-container fluid>
        <!-- PAGE TITLE -->
        <div class="viewTitle pa-2">
            <label>{{ title }} ({{ nonConformite.type }})</label>
        </div>

        <!-- FLOATING SAVE BUTTON -->
        <v-btn v-if="!nonConformiteEstTerminee" :disabled="!formValid || nonConformiteEstTerminee || imageStructure.length === 0" @click="submit"
               class="success" id="btn_save" :loading="loading" fab dark fixed right bottom>
            <v-icon>save</v-icon>
        </v-btn>

        <!-- TOP INFOS AND FAVORITE BUTTON -->
        <v-layout class="my-2" row wrap>
            <!-- infos -->
            <v-layout row wrap>
                <v-flex>
                    <label class="font-weight-bold">Date de création : </label>
                    <span v-if="!!nonConformite.createdAt"><label>{{ nonConformite.createdAt }}</label></span>
                    <br/>

                    <label class="font-weight-bold">Demandeur : </label>
                    <span><label>{{ !!nonConformite.user ? nonConformite.user.fullName : loggedUser.fullName }}</label></span>
                    <br/>

                    <label class="font-weight-bold" v-if="!!nonConformite.numeroNc">Numéro de Non-Conformité : </label>
                    <span v-if="!!nonConformite.numeroNc" id="nc-numero-label"><label>{{ nonConformite.numeroNc }}</label></span>
                    <br/>

                    <label class="font-weight-bold" v-if="!!nonConformite.projet">Numéro du project : </label>
                    <span v-if="!!nonConformite.projet" id="projet-label"><label>{{ nonConformite.projet || 'N/D' }}</label></span><br/><br/>
                </v-flex>
            </v-layout>

            <!--  favorite button (nc  membership) -->
            <v-layout
                row
                wrap
                align-end
                justify-end
                fill-height
                class="text-xs-right"
            >
                <v-flex md12>
                    <div class="text-xs-right d-inline-flex">
                        <v-btn
                            v-if="!isCreating"
                            :color="abonnementBackgroundIconeColor"
                            icon
                            @click="setAbonnementNonconformite(loginStore.loggedUser.id)"
                            :disabled="nonConformiteEstTerminee"
                        >
                            <v-icon :color="abonnementIconeColor">star</v-icon>
                        </v-btn>
                    </div>
                </v-flex>
            </v-layout>
        </v-layout>

        <!-- GALLUP ASSIGNATION INFOS (if NC has a question_answer_id)-->
        <div class="shadow my-3">
            <GallupInfos
                v-if="!!nonConformite.questionAnswerId"
                :gallupInfos="gallupInfos"
            />
        </div>

        <!-- FORM VALIDATION -->
        <v-form ref="form" v-model="formValid" lazy-validation>
            <!-- LAYOUT SECTION (SHOWS LAYOUT FOR EACH NC TYPES) -->
            <component
                :is="currentCreateLayout"
                :nonConformite="nonConformite"
                :isDisabled="nonConformiteEstTerminee"
                :readOnly="nonConformiteEstTerminee"
                :isGNCQualite="isGNCQualite"
                :isGNCSupervisor="isGNCSuperviseur"
                :isGNCAdmin="isGNCAdmin"
                :isLoadingTransition="loadingTransition || loading"
                :correctivesActions="getCorrectivesActions"
                :employeeListSIGM="entrepriseStore.employes"
                :imagesStructure="imageStructure"
                :imagesSolution="imagesSolution"
                :projectIncludesUnit="projectIncludesUnit"
                :projectMessages="projectMessages"
                :projectErrorMessages="projectErrorMessages"
                :stageUnits="stageUnitsAutocompleteItems"
                :partNos="entrepriseStore.partNos"
                :projectPartNos="projectAvailableParts"
                :stageModels="entrepriseStore.stages"
                :categories="nonConformiteStore.categories"
                :departments="getAllDepartments"
                :causes="nonConformiteStore.causes"
                :employees="entrepriseStore.employes"
                :providers="entrepriseStore.fournisseurs"
                :newSytelineProjectNumber="newSytelineProjectNumber"
                :tryToCreateProjectFail="tryToCreateProjectFail"
                :isSpinnerLoading="entrepriseStore.showSpinnerLoading"
                @setProjectInfo="setProjectInfo"
                @searchNoAssemblage="searchNoAssemblage($event)"
                @changeNoAssemblage="changeNoAssemblage($event)"
                @addNewProject="addNewProjectToSyteline('GNC')"
                @applyTransition="appliquerTransition"
                @uploadImage="uploadImage"
                @uploadImageSolution="uploadImageSolution"
                @popUpImage="popUpImage"
                @removeImage="removePhoto"
                @uploadFile="uploadFichiers"
                @uploadFileSolution="uploadFichiersSolution"
                @removeFile="removeFile"
                @abonnementActionCorrective="setCorrectiveActionMembers"
                @checkUp="checkUp"
                @resolutionMembersList="setResolutionMembersList($event)"
            />

            <!-- NOTES SECTION -->
            <Notes
                v-if="!!nonConformite.id"
                :readOnly="nonConformiteEstTerminee"
                :isDisabled="nonConformiteEstTerminee"
                :notes="nonConformite.notes"
                @addNewNote="ajouterNote"
                @noteTextChange="noteTextChange"
                :note="newNote"
            />

            <!-- MEMBERS SECTION
            <div class="shadow pa-2 my-3">
                <h2>Ajout d'utilisateur en suivi </h2>
                <NCAbonnements
                    :non-conformite="nonConformite"
                    :items="nonConformite.abonnes"
                    :readonly="nonConformiteEstTerminee"
                    :users="entrepriseStore.users"
                    @change="nonConformite.abonnes = $event"
                />
            </div>-->
        </v-form>

        <!-- HISTORY SECTION -->
        <div class="shadow my-3">
            <Histo
                :non-conformite="nonConformite"
            />
        </div>

        <!-- DIALOGS -->
        <!-- images dialog -->
        <DialogPhotos
            :image="image"
            @remove="removePhoto($event)"
            :can-delete="(isOwner || isGNCAdmin) && image.origin !== 'client'"
        />
    </v-container>
</template>

<script>
    import { LOGIN_STORE, NON_CONFORMITES_STORE, ENTREPRISE_STORE, APPLICATION_STORE } from "../../constants";
    import { mapState, mapGetters, mapActions } from 'vuex';
    import isEqual from 'lodash/isEqual';
    import utils from '../../utils';

    //controls
    import GallupInfos from '../../components/controls/GallupInfos';
    import ImagesUpload from "../../components/controls/ImagesUpload";
    import FilesUpload from "../../components/controls/FilesUpload";
    import Notes from '../../components/controls/Notes';
    import Histo from "../../components/controls/Histo";
    import DialogPhotos from "../../components/controls/DialogPhotos";

    //create layouts (Qualite, Maintenance, SAV)
    import CreateQualite from '../../components/create/CreateQualite';
    import CreateMaintenance from '../../components/create/CreateMaintenance';
    import CreateSav from '../../components/create/CreateSav';
    import NCAbonnements from "../../components/controls/NC-Abonnements";

    export default {

        name: "create",
        components: {
            NCAbonnements,
            //controls
            DialogPhotos,
            Histo,
            GallupInfos,
            ImagesUpload,
            FilesUpload,
            Notes,

            //create layouts
            CreateQualite,
            CreateMaintenance,
            CreateSav
        },
        //actions à la construction
        created() {
            try {
                Promise.all([
                    this.fetchUsers(),
                    this.fetchParamsNonConformite(),
                    this.fetchDepartments(),
                    this.fetchStageUnits(),
                    this.fetchStages(),

                    //Anciennement SIGM
                    //this.fetchEmployees(),
                    //this.fetchSuppliers(),
                    //this.fetchPartNos(),

                    //Changer vers Syteline
                    this.fetchEmployeesFromSyteline(),
                    this.fetchSuppliersFromSyteline(),
                ]).then(response => {
                    if (this.$router.currentRoute.name === 'edit') {
                        this.setParamsEditNC();
                    } else {
                        this.setParamsCreateNC();
                    }
                });
            } catch (e) {
                this.alert('error', e.message());
            }
        },
        //données
        computed: {
            //VUEX
            ...mapState({
                nonConformiteStore: state => state.nonConformiteStore,
                loginStore: state => state.loginStore,
                entrepriseStore: state => state.entrepriseStore,
            }),
            ...mapGetters([
                //entreprise store
                'getUsers',

                //departments store
                'getAllDepartments',

                //correctives actions store
                'getCorrectivesActions',
            ]),

            //COMPUTED PROPERTY
            //shows current page layout based on NC type
            currentCreateLayout() {
                return this.nonConformite.type === 'qualite' ? 'CreateQualite' :  this.nonConformite.type === 'maintenance' ? 'CreateMaintenance' : 'CreateSav';
            },

            nonConformiteEstTerminee(){
                return (this.nonConformite.etat === 'demande_rejetee' || this.nonConformite.etat === 'travail_approuve') && !this.isGNCAdmin;
            },
            loggedUser() {
                return this.$store.getters[LOGIN_STORE.getters.LOGGED_USER];
            },

            isOwner() {
                return this.$store.getters[LOGIN_STORE.getters.LOGGED_USER].id === this.nonConformite.userId;
            },

            isGNCAdmin() {
                return this.$store.getters[LOGIN_STORE.getters.IS_GNC_ADMIN];
            },

            isGNCQualite() {
                return this.$store.getters[LOGIN_STORE.getters.IS_GNC_QUALITE];
            },

            isGNCSuperviseur() {
                return this.$store.getters[LOGIN_STORE.getters.IS_GNC_SUPERVISOR];
            },

            isGNCUser() {
                return this.$store.getters[LOGIN_STORE.getters.IS_GNC_USER];
            },
            //tells if page is currently in create mode or not
            isCreating(){
                return this.$router.currentRoute.name === 'create';
            },

            //abonnements
            estAbonne(){
                let estAbonne = false;
                let tabAbonnements = this.nonConformite.abonnes.concat(this.nonConformite.abonnesActionCorrective);

                if(tabAbonnements.length > 0 && this.loginStore.loggedUser != null){
                    let res = tabAbonnements.find( u => u != null && u.id === this.loginStore.loggedUser.id);

                    if(res != null){
                        estAbonne = true;
                    }
                }

                return estAbonne;
            },

            abonnementIconeColor(){
                return this.estAbonne ? 'white' : 'black';
            },

            abonnementBackgroundIconeColor(){
                return this.estAbonne ? 'yellow darken-2' : 'blue-grey lighten-5';
            },
            isNcQualite() {
                return this.nonConformite.type === 'qualite';
            },
            isNcMaintenance() {
                return this.nonConformite.type === 'maintenance';
            },
            isNcSAV() {
                return this.nonConformite.type === 'sav';
            },

            stageUnitsAutocompleteItems() {
                if (this.projectAvailableUnits.length > 0) {
                    return this.projectAvailableUnits;
                }
                return this.entrepriseStore.units;
            },
        },
        data() {
            return {
                NON_CONFORMITES_STORE: NON_CONFORMITES_STORE,

                //add member autocomplete v-model
                abonnementNouvelUtilisateur: null,
                cycle: false,

                //form v-model
                formValid: true,
                id: null,

                //image for images dialog
                image: {
                    visible: false,
                    src: '',
                },

                //nc images
                imageStructure: [],
                //nc solution images
                imagesSolution: [],

                loading: false,
                loadingTransition: false,
                message: {
                    type: "",
                    text: ""
                },

                //Gallup assignation infos
                gallupInfos: {},

                //NC
                nonConformite: {
                    id: null,
                    titre: '',
                    description: '',
                    tags: [],
                    projet: '',
                    noAssemblage: '',
                    ncParentDepartementId: null,
                    ncDepartementId: null,
                    stageModel: '',
                    stageId: null,
                    stageUnit: '',
                    stageUnitId: null,
                    ncCategorieId: null,
                    ncCauseId: null,
                    causeFournisseurId: null,
                    causeEmployeId: null,
                    solutionRequise: false,
                    solutionProposee: '',
                    solutionAlternative: '',
                    raisonRefus: '',
                    history: [],
                    dateEcheance: new Date().toISOString().substr(0, 10),
                    estimeTemps: '',
                    estimeHeures: null,
                    estimeMinutes: null,
                    gravite: 1,
                    frequence: 1,
                    assignationEmployeSigm: null,
                    actionCorrectiveRequise: false,
                    actionCorrectiveProposee: '',
                    actionCorrectiveComplete: false,
                    numeroNc: null,
                    abonnes: [],
                    abonnesActionCorrective: [],
                    type: '',
                    estime: [],
                    //files
                    ncFichiers: [],
                    //solution files
                    ncFichiersSolution: [],
                    isReception: false,
                },

                panel: [true, true, true],
                currentPartSelected:'',

                //project number
                projectIncludesUnit: false,
                projectErrorMessages: [],
                projectMessages:[],

                projectAvailableParts: [],
                projectAvailableUnits: [],
                //page title
                title: "",

                //new note
                newNote: "",

                //Syteline Data
                lastGNCProjectsNumber:null,
                newSytelineProjectNumber:null,
                tryToCreateProjectFail:null
            }
        },
        methods: {
            //VUEX
            ...mapActions([
                //correctives actions store
                'fetchCorrectivesActionsByType',

                //non conformite store
                'supprimerFichierNonConformite',
                'ajouterNoteNonConformites',
                //'fetchPartSuppliers',
                'fetchParamsNonConformite',
                'fetchNonConformiteById',

                //departments store
                'fetchDepartments',

                //entreprise store
                'fetchStageUnits',
                'fetchStages',
                'fetchEmployees',
                'fetchEmployeesFromSyteline',
                'fetchSuppliers',
                'fetchSuppliersFromSyteline',
                'fetchUsers',
                'fetchPartNos',
                'fetchPartNoFromSyteline',
            ]),

            //NOTES
            //adds a new note
            async ajouterNote(newNote) {
                //saves new note and returns all notes of NC if the new one
                const notes = await this.ajouterNoteNonConformites({ nonConformite: this.nonConformite, note: newNote});

                //if the new note has been saved successfully
                if(!!notes) {
                    //updates NC
                    this.nonConformite.notes = notes;

                    //updates NC copy
                    this.nonConformiteStore.nonConformite.notes = notes;

                    this.newNote = "";
                }
            },

            noteTextChange(newNote) {
                this.newNote = newNote
            },

            //POP UP ALERT
            //creates pop up messages
            alert(type, message){
                this.$store.dispatch(APPLICATION_STORE.actions.AFFICHER_MESSAGE,
                    {
                        type: type,
                        text: message
                    }
                );
                if(this.loading){
                    this.loading = false;
                }

                if(this.loadingTransition){
                    this.loadingTransition = false;
                }
            },

            //TRANSITIONS
            //executes transition
            async appliquerTransition(transition, fromType) {
                const data = {
                    nc: this.nonConformite,
                    transition: transition
                };

                const processTransition = async () => {
                    this.loadingTransition = true;

                    try {
                        const response = await this.$store.dispatch('applyTransition', data);
                        this.loadingTransition = false;
                        this.showMessage('success', 'Mise à jour effectuée avec succès');
                        this.nonConformite.history = response.history;
                        this.nonConformite.etat = response.history[0].to;
                    } catch (error) {
                        console.log(error);
                        this.showMessage('error', error);
                        this.loadingTransition = false;
                    }
                };

                if (transition === 'QUALITE_REJECT_DEMAND') {
                    await processTransition();
                    return;
                }

                try {
                    await this.submit();
                    await processTransition();

                    this.copyNonConformite(utils.copyObject(this.nonConformite));

                    const response = await this.$store.dispatch(NON_CONFORMITES_STORE.actions.FETCH_LISTE_NOTIFICATIONS);
                    if (fromType === "QUALITE_APPROUVED_SUP") {
                        this.addNewProjectToSyteline('GNC');
                    }
                } catch (error) {
                    this.showMessage('error', "Erreur lors de l'envoi");
                    this.loadingTransition = false;
                }
            },

            showMessage(type, text) {
                this.message.type = type;
                this.message.text = text;
                this.$store.dispatch(APPLICATION_STORE.actions.AFFICHER_MESSAGE, this.message);
            },

            //PAGE UTILS
            //creates a copy of NC
            copyNonConformite(nonConformite){
                this.nonConformiteStore.nonConformite = utils.copyObject(nonConformite);
                this.nonConformiteStore.nonConformite.imageStructure = utils.copyObject(nonConformite.imagesFolder);
                this.nonConformiteStore.nonConformite.imagesSolution = utils.copyObject(nonConformite.imagesSolutionFolder);
            },

            //verifies if the page has unsaved modifications
            hasUnsavedChanges(){
                let nc_courante = utils.copyObject(this.nonConformite);

                nc_courante.imageStructure = utils.copyObject(this.imageStructure);
                nc_courante.imagesSolution = utils.copyObject(this.imagesSolution);

                nc_courante.imagesFolder = null;
                nc_courante.imagesSolutionFolder = null;

                if(this.nonConformiteStore.nonConformite){
                    this.nonConformiteStore.nonConformite.imagesFolder = null;
                }

                if(this.nonConformiteStore.nonConformite){
                    this.nonConformiteStore.nonConformite.imagesSolutionFolder = null;
                }

                return !isEqual(nc_courante, this.nonConformiteStore.nonConformite);
            },

            //CREATE/EDIT PAGE PARAMS
            //sets page params when creating a new NC
            async setParamsCreateNC(){
                this.title = "Création - Non conformité";
                this.abonnementNouvelUtilisateur = null;
                this.cycle = false;
                this.formValid = true;
                this.id = null;
                //sets images
                this.imageStructure = [];
                //sets solution images
                this.imagesSolution = [];
                this.nonConformite.id = null;
                this.nonConformite.titre = '';
                this.nonConformite.description = '';
                //sets tags
                this.nonConformite.tags = [];
                this.nonConformite.projet = '';
                this.nonConformite.noAssemblage = '';
                this.nonConformite.ncParentDepartementId = null;
                this.nonConformite.ncDepartementId = null;
                this.nonConformite.stageModel = '';
                this.nonConformite.stageId = null;
                this.nonConformite.stageUnit = '';
                this.nonConformite.stageUnitId = null;
                this.nonConformite.ncCategorieId = null;
                this.nonConformite.ncCauseId = null;
                this.nonConformite.causeFournisseurId = null;
                this.nonConformite.causeEmployeId = null;
                this.nonConformite.solutionRequise = false;
                this.nonConformite.solutionProposee = '';
                this.nonConformite.solutionAlternative = '';
                this.nonConformite.raisonRefus = '';
                this.nonConformite.history = [];
                this.nonConformite.dateEcheance = new Date().toISOString().substr(0, 10);
                this.nonConformite.estimeTemps = '';
                this.nonConformite.estimeHeures = null;
                this.nonConformite.estimeMinutes = null;
                this.nonConformite.gravite = 1;
                this.nonConformite.frequence = 1;
                this.nonConformite.assignationEmployeSigm = null;
                this.nonConformite.actionCorrectiveRequise = false;
                this.nonConformite.actionCorrectiveProposee = '';
                this.nonConformite.actionCorrectiveComplete = false;
                this.nonConformite.numeroNc = '';
                this.nonConformite.abonnes = [];
                this.nonConformite.createdAt = '';
                this.nonConformite.user = null;
                //sets files
                this.nonConformite.ncFichiers = [];
                //sets solution files
                this.nonConformite.ncFichiersSolution = [];
                this.nonConformite.estime = [];
                this.nonConformite.isReception = false;

                //gets NC type depending on the query string passed in the route
                this.nonConformite.type = !!this.$route.query.mode ? this.$route.query.mode : 'qualite';
                this.nonConformite.showInQualite = this.nonConformite.type === 'qualite';
                this.nonConformite.showInMaintenance = this.nonConformite.type === 'maintenance';
                this.nonConformite.showInSav = this.nonConformite.type === 'sav';
                this.nonConformite.urgent = false;
                this.nonConformite.resolutionMembersList = null;

                //gets correctives actions list by type
                //this function doesnt return anything, it sets the array of correctives actions of the store
                await this.fetchCorrectivesActionsByType(this.nonConformite.type);
            },

            //sets page params when editing an NC
            async setParamsEditNC(){
                this.title = "Modification - Non conformité";
                const response = await this.fetchNonConformiteById(this.$router.currentRoute.params.ncId);

                if(!!response) {
                    //feeds NC images
                    this.imageStructure = utils.copyObject(response.nonConformite.imagesFolder);
                    //feeds NC solution images
                    this.imagesSolution = utils.copyObject(response.nonConformite.imagesSolutionFolder);
                    //creates a copy of NC
                    this.nonConformite = response.nonConformite;

                    //on veut avoir les bonnes informations de projet
                    //this.setProjectInfo(); //Acienne logique SIGM

                    //sets Gallup infos (only if NC has a questionAnswerId)
                    this.gallupInfos = utils.copyObject(response.gallupInfos);

                    this.projectIncludesUnit = !!this.nonConformite.projet && !!this.nonConformite.stageUnitId;

                    //On set la copie de la nc pour les unsaved changes
                    this.copyNonConformite(utils.copyObject(response.nonConformite));

                    //gets correctives actions list by type
                    //this function doesnt return anything, it sets the array of correctives actions of the store
                    await this.fetchCorrectivesActionsByType(response.nonConformite.type);
                }
            },

            //PROJECT NUMBER
            //gets infos associated with project number (stage unit, stage model, etc...)
            setProjectInfo() {
                if (!!this.nonConformite.projet) {
                    this.projectMessages = [];
                    if (this.nonConformite.projet.includes('-')) {
                        //on cherche à valider un projet qui vient de SMART (locations)
                        this.$store.dispatch('validateSMARTProjectNumber', this.nonConformite.projet).then(valid => {
                            this.projectIncludesUnit = false;
                            if (valid === true) {
                                this.projectErrorMessages = [];
                                this.projectMessages = ["Il s'agit d'un projet de location, l'unité doit être spécifiée manuellement"];
                            } else {
                                this.projectErrorMessages = ["Numéro de projet inexistant dans SMART"];
                                this.projectMessages = [];
                            }
                        })
                    } else {
                        let data = {
                            projectNumber: this.nonConformite.projet
                        };
                        //***CHANGEMENT***
                            //this.$store.dispatch('fetchDataProjectNumber', data).then(response => {
                            this.$store.dispatch('fetchDataProjectNumberFromSyteline', data).then(response => {
                            let validProject = (response.data.projectNumber !== null);
                            this.projectErrorMessages = validProject ? [] : ['Numéro de projet inexistant dans SIGM'];
                            this.projectAvailableParts = response.data.availableParts;
                            this.projectAvailableUnits = response.data.stageUnits;
                            this.projectIncludesUnit = !!response.data.stageUnits && this.projectAvailableUnits.length === 1;
                            this.projectMessages = (validProject && (this.projectIncludesUnit || this.projectAvailableUnits.length > 0)) || !validProject ? [] : ["Le projet n'est pas lié à une unité dans SIGM"];

                            if (this.projectIncludesUnit) {
                                if(response.data.stageUnit){
                                    this.nonConformite.stageUnitId = response.data.stageUnit.id;
                                }
                                if(response.data.stageUnit && response.data.stageUnit.stage){
                                    this.nonConformite.stageId = response.data.stageUnit.stage.id;
                                }
                                if (this.nonConformite.stageId == null && response.data.stageUnit.genericModel != null) {
                                    let genericStage = this.entrepriseStore.stages.find(stage => stage.proposalModelName === response.data.stageUnit.genericModel);
                                    if (genericStage != null) {
                                        this.nonConformite.stageId = genericStage.id;
                                    }
                                }
                            }
                            else {
                                // this.nonConformite.stageUnitId = null;
                                // this.nonConformite.stageId = null;
                            }
                        });
                    }
                }
                else {
                    this.projectMessages = [];
                    this.projectErrorMessages = [];
                    this.projectAvailableParts = [];
                    this.projectIncludesUnit = false;
                    //this.nonConformite.stageUnitId = null;
                    //this.nonConformite.stageId = null;
                    //this.nonConformite.noAssemblage = '';
                }

                //parce que les changements affectent les autocompletes unit et stage mais ces derniers ne se reloadent pas toujours comme il faut
                this.$forceUpdate();
            },

            setResolutionMembersList(value){
                console.log(value);
                this.nonConformite.resolutionMembersList = value;
            },

            changeNoAssemblage(value){
                if(this.nonConformite.projet.length > 0 && value !== undefined){
                    var modelName = '_' + (this.getNcStageModelName() === null ? '' : this.getNcStageModelName());
                    var unitName =  '_' + (this.getNcStageUnitName() === null ? '' : this.getNcStageUnitName());
                    let parts = this.nonConformite.projet.split('C');
                    let formatNumber = Number(parts[1]);
                    let projDesc = formatNumber + '_' + value.trim() + (unitName !== '_' ? unitName : (modelName !== '_' ? modelName : ''));

                    //Insertion des propriétés de dataProject
                    let dataProject = {
                        fromApp : 'GNC',
                        projNum : this.nonConformite.projet,
                        description : projDesc,
                    };

                    //Update Project Description
                    this.$store.dispatch('fetchUpdateProjectDescriptionToSyteline', dataProject).then(response => {
                        this.submit();
                    }).catch( error => {
                        this.alert('error', error.response.data.errors.message);
                    });
                }
                this.currentPartSelected = value;
            },

            //Recherche des pièces correspondante
            searchNoAssemblage(searchChar){
                this.fetchPartNoFromSyteline(searchChar);
            },

            async addNewProjectToSyteline(fromApp){
                let completed = false;
                let partSelected = this.currentPartSelected.trim();

                if(partSelected.length > 0 || this.nonConformite.noAssemblage.length > 0){
                    //1-Trouver le dernier numéro de projet
                    var newNumber = '0000001';
                    var modelName = '_' + (this.getNcStageModelName() === null ? '' : this.getNcStageModelName());
                    var unitName =  '_' + (this.getNcStageUnitName() === null ? '' : this.getNcStageUnitName());
                    var model = this.getNcStageModelName();
                    var productCode = null;

                    //2-Fabriquer le projDesc
                    await this.$store.dispatch('fetchLastProjectNumberFromSyteline', fromApp).then(response => {
                        this.lastGNCProjectsNumber = response.data.data;
                    });
                    if(this.lastGNCProjectsNumber !== null){
                        let lastCharFromKey = fromApp.substring(fromApp.length - 1);
                        let parts = this.lastGNCProjectsNumber.split(lastCharFromKey);
                        newNumber = Number(parts[1]) + 1;
                    }
                    (partSelected.length === 0 ? partSelected = this.nonConformite.noAssemblage.trim() : 0);
                    let projDesc = newNumber + '_' + partSelected + (unitName !== '_' ? unitName : (modelName !== '_' ? modelName : ''));

                    //3-Fabriquer le productCode
                    if(modelName.length === 0){
                        productCode = '0MOI_QAL';
                    }
                    else{
                        switch(model){
                            case 'SAM450': productCode = '0QAL' + model;
                                break;
                            case 'SAM575': productCode = '0QAL' + model;
                                break;
                            case 'SL075': productCode = '0QAL' + model;
                                break;
                            case 'SL100': productCode = '0QAL' + model;
                                break;
                            case 'SL260': productCode = '0QAL' + model;
                                break;
                            case 'SL320': productCode = '0QAL' + model;
                                break;
                            case 'SP100': productCode = '0QAL' + model;
                                break;
                            case 'ST100': productCode = '0QAL' + model;
                                break;
                            case 'SEA': productCode = '0QAL' + model + "775";
                                break;
                            default: productCode = '0Qualite';
                        }
                    }

                    //4-Insertion des propriétés de dataProject
                    let dataProject = {
                        fromApp : fromApp,
                        description : projDesc,
                        productCode : productCode
                    };

                    //5-Création d'un nouveau projet
                    await this.$store.dispatch('fetchAddNewProjectToSyteline', dataProject).then(response => {
                        let results = response.data.data;
                        this.newSytelineProjectNumber = results.projet[0].Value;
                        this.tryToCreateProjectFail = false;
                        completed = true;

                        let dataNonConformite = {
                            Id : this.nonConformite.id,
                            Projet : this.newSytelineProjectNumber
                        }

                        //6-Sauvegarde du nouveau numéro de projet dans la nonConformite
                        this.$store.dispatch('fetchAddNewProjectNumberToNonConformite', dataNonConformite).then(response => {
                            this.alert('success', "Le projet numéro # " + this.newSytelineProjectNumber +" a été créé avec succès!");
                        }).catch( error => {
                            this.tryToCreateProjectFail = true;
                            this.alert('error', error.response.data.errors.message);
                        });

                    }).catch( error => {
                        this.tryToCreateProjectFail = true;
                        this.alert('error', error.response.data.errors.message);
                    });
                }
                else{
                    this.tryToCreateProjectFail = true;
                    this.alert('error', 'Vous devez au minimum avoir choisi un numéro de pièce ou d\'assemblage');
                }

                return completed;
            },

            checkUp(){
                this.$store.dispatch('fetchCheckUpTypeFromSyteline').then(response => {

                }).catch( error => {
                    this.alert('error', error.response.data.errors.message);
                });
            },

            //FORM SUBMITION (VALIDATIONS)
            //submits form
            async submit() {
                try {
                    let vm = this;
                    if (this.newNote !== "") {
                        await this.ajouterNote(this.newNote);
                    }
                        return new Promise((resolve, reject) => {
                            try {
                                if (vm.$refs.form.validate() && vm.formValid && vm.imageStructure.length > 0) {
                                    vm.loading = true;

                                    //initializing formData objects for all images (images and solution images) and files (files and solution files)
                                    let formData = new FormData();
                                    let formDataFiles = new FormData();

                                    //NC data to save
                                    const data = {
                                        id: vm.nonConformite.id,
                                        titre: vm.nonConformite.titre,
                                        description: vm.nonConformite.description,
                                        tags: vm.nonConformite.tags,
                                        projet: vm.nonConformite.projet,
                                        noAssemblage: vm.nonConformite.noAssemblage,
                                        ncParentDepartementId: vm.nonConformite.ncParentDepartementId,
                                        ncDepartementId: vm.nonConformite.ncDepartementId,
                                        stageModel: vm.getNcStageModelName(),
                                        stageId: vm.nonConformite.stageId,
                                        stageUnit: vm.getNcStageUnitName(),
                                        stageUnitId: vm.nonConformite.stageUnitId,
                                        ncCategorieId: vm.nonConformite.ncCategorieId,
                                        ncCauseId: vm.nonConformite.ncCauseId,
                                        causeFournisseurId: vm.nonConformite.causeFournisseurId,
                                        causeFournisseur: !!vm.nonConformite.causeFournisseurId ? vm.entrepriseStore.fournisseurs.find(f => {
                                            return f.VendNum === vm.nonConformite.causeFournisseurId
                                        }).Name : null,
                                        causeEmployeId: vm.nonConformite.causeEmployeId,
                                        causeEmploye: !!vm.nonConformite.causeEmployeId ? vm.entrepriseStore.employes.find(e => {
                                            return e.EmpNum === vm.nonConformite.causeEmployeId
                                        }).Name : null,
                                        solutionRequise: vm.nonConformite.solutionRequise,
                                        solutionProposee: vm.nonConformite.solutionProposee,
                                        solutionAlternative: vm.nonConformite.solutionAlternative,
                                        raisonRefus: vm.nonConformite.raisonRefus,
                                        dateEcheance: vm.nonConformite.dateEcheance,
                                        estimeTemps: vm.nonConformite.estimeTemps,
                                        estimeHeures: vm.nonConformite.estimeHeures,
                                        estimeMinutes: vm.nonConformite.estimeMinutes,
                                        gravite: vm.nonConformite.gravite,
                                        frequence: vm.nonConformite.frequence,
                                        assignationEmployeSigm: vm.nonConformite.assignationEmployeSigm,
                                        actionCorrectiveRequise: vm.nonConformite.actionCorrectiveRequise,
                                        actionCorrectiveProposee: vm.nonConformite.actionCorrectiveProposee,
                                        actionCorrectiveComplete: vm.nonConformite.actionCorrectiveComplete,
                                        abonnes: vm.nonConformite.abonnes,
                                        abonnesActionCorrective: vm.nonConformite.abonnesActionCorrective,
                                        type: vm.nonConformite.type,
                                        questionAnswerId: vm.nonConformite.questionAnswerId,
                                        estime: vm.nonConformite.estime,
                                        isReception: vm.nonConformite.isReception,
                                        showInQualite: vm.nonConformite.showInQualite,
                                        showInMaintenance: vm.nonConformite.showInMaintenance,
                                        showInSav: vm.nonConformite.showInSav,
                                        urgent: vm.nonConformite.urgent,
                                        of:vm.nonConformite.of,
                                        resolutionMembersList:vm.nonConformite.resolutionMembersList,
                                    };

                                    //gets NC images to upload
                                    let imagesToUpload = [];
                                    if (vm.imageStructure != null) {
                                        imagesToUpload = vm.imageStructure.filter(image => image.toUpload === true);
                                        if (imagesToUpload.length > 0) {
                                            //adds images to upload to images form data
                                            imagesToUpload.map((image, index) => {
                                                formData.append('images[' + index + ']', image.file);
                                            });
                                        }
                                    }

                                    //gets NC solution images to upload
                                    let imagesSolutionToUpload = [];
                                    if (vm.imagesSolution != null) {
                                        imagesSolutionToUpload = vm.imagesSolution.filter(image => image.toUpload === true);
                                        if (imagesSolutionToUpload.length > 0) {
                                            //adds solution images to upload to images form data
                                            imagesSolutionToUpload.map((image, index) => {
                                                formData.append('imagesSolution[' + index + ']', image.file);
                                            });
                                        }
                                    }

                                    //gets NC files to upload
                                    let fichiersToUpload = [];
                                    if (vm.nonConformite.ncFichiers != null) {
                                        fichiersToUpload = vm.nonConformite.ncFichiers.filter( fichier => fichier.toUpload === true);
                                        if (fichiersToUpload.length > 0 ) {
                                            //adds files to upload to files form data
                                            fichiersToUpload.map( (fichier, index) => {
                                                formDataFiles.append(`fichiers[${index}]`, fichier.file);
                                            });
                                        }
                                    }


                                    //gets NC solution files to upload
                                    let fichiersSolutionToUpload = [];
                                    if (vm.nonConformite.ncFichiersSolution != null) {
                                        fichiersSolutionToUpload = vm.nonConformite.ncFichiersSolution.filter(fichier => fichier.toUpload === true);
                                        if (fichiersSolutionToUpload.length > 0 ) {
                                            //adds solution files to upload to files form data
                                            fichiersSolutionToUpload.map( (fichier, index) => {
                                                formDataFiles.append(`fichiersSolution[${index}]`, fichier.file);
                                            });
                                        }
                                    }

                                    //SAVES NC DATA
                                    vm.$store.dispatch(NON_CONFORMITES_STORE.actions.AJOUTER_NON_CONFORMITE, data).then(response => {
                                        vm.nonConformite = response.nonConformite;

                                        //objects with all images to store (images and solution images)
                                        let dataImages = {
                                            nbImages: imagesToUpload.length + imagesSolutionToUpload.length,
                                            nonConformite: response.nonConformite,
                                            images: formData,
                                        };

                                        //SAVES NC IMAGES (images and solution images)
                                        vm.$store.dispatch(NON_CONFORMITES_STORE.actions.AJOUTER_IMAGES_NON_CONFORMITE, dataImages).then(response => {
                                            //Feeder les carousel avec les photos
                                            vm.imageStructure = response.nonConformite.imagesFolder;
                                            vm.imagesSolution = response.nonConformite.imagesSolutionFolder;
                                            vm.nonConformite = response.nonConformite;

                                            //objects with all files to store (files and solution files)
                                            let uploadFiles = {
                                                nbFichiers: fichiersToUpload.length + + fichiersSolutionToUpload.length,
                                                nonConformite: response.nonConformite,
                                                fichiers: formDataFiles,
                                            };

                                            //SAVES NC FILES (files and solution files)
                                            vm.$store.dispatch(NON_CONFORMITES_STORE.actions.AJOUTER_FICHIERS_NON_CONFORMITES, uploadFiles).then(response => {
                                                //feeds NC files
                                                vm.nonConformite.ncFichiers = utils.copyObject(response.nonConformite.ncFichiers);
                                                //feeds NC solution files
                                                vm.nonConformite.ncFichiersSolution = utils.copyObject(response.nonConformite.ncFichiersSolution);

                                                //if user was creating a new NC
                                                if (vm.$router.currentRoute.name === 'create') {
                                                    //redirects user to edit page
                                                    vm.$router.push(
                                                        {
                                                            name: 'edit',
                                                            params: {ncId: response.nonConformite.id}
                                                        }
                                                    );

                                                    //feeds création confirmation message
                                                    vm.message.type = 'success';
                                                    vm.message.text = 'Création de la non-conformité complété avec succès';
                                                //else, if user was editing an existing NC
                                                } else {
                                                    //feeds update confirmation message
                                                    vm.message.type = 'success';
                                                    vm.message.text = 'Mise à jour complété avec succès';
                                                }

                                                //shows confirmation message to user
                                                vm.$store.dispatch(APPLICATION_STORE.actions.AFFICHER_MESSAGE, vm.message);

                                                vm.loading = false;

                                                //creates a copy of NC
                                                vm.copyNonConformite(utils.copyObject(vm.nonConformite));
                                                resolve(true);
                                            }).catch(error => {
                                                vm.loading = false;
                                                throw error;
                                            });

                                        }).catch(error => {
                                            vm.loading = false;
                                            throw error;
                                        });

                                    }).catch(error => {
                                        vm.message.type = 'error';
                                        vm.message.text = 'Erreur lors de la création de la non-conformité.';
                                        vm.$store.dispatch(APPLICATION_STORE.actions.AFFICHER_MESSAGE, vm.message);
                                        reject(error);
                                        vm.loading = false;
                                    });
                                }
                                else{
                                    resolve(true);
                                }
                            } catch (e) {
                                vm.alert('error', e.message);
                            }
                        });
                    } catch (e){
                        vm.alert('error', e.message);
                }
            },

            //gets stage model name when submitting form
            getNcStageModelName(){
                return !!this.nonConformite.stageId ? this.$store.getters[ENTREPRISE_STORE.getters.GET_STAGE_MODEL_NAME](this.nonConformite.stageId) : null;
            },

            //gets stage unit name when submitting form
            getNcStageUnitName(){
                return !!this.nonConformite.stageUnitId ? this.$store.getters[ENTREPRISE_STORE.getters.GET_STAGE_UNIT_NAME](this.nonConformite.stageUnitId) : null;
            },

            //IMAGES
            //uploads images
            uploadImage(imageList) {
                this.imageStructure = this.imageStructure.concat(imageList);
            },

            //uploads solution images
            uploadImageSolution(imageList){
                this.imagesSolution = this.imagesSolution.concat(imageList);
            },

            //sets image to be opened in images dialog
            popUpImage({ img, origin }){
                this.image.src = img.src;
                this.image.visible = true;
                this.image.title = !!img.file ? img.file.name : img.title;
                this.image.origin = origin;
            },

            //deletes images
            removePhoto(image){
                this.$confirm("Voulez-vous vraiment supprimer cette image").then(res => {
                    if (res) {
                        if (image.origin === 'solution') {
                            if (image.src.startsWith('https')) {
                                this.$store.dispatch('supprimerImageNonConformite', {nonConformite: this.nonConformite, image: image}).then(response => {
                                    if(!!response.nc) {
                                        this.imagesSolution = this.imagesSolution.filter(i => {
                                            return i.title !== image.title;
                                        });
                                    } else {
                                        console.log(response);
                                    }
                                });
                            } else {
                                this.imagesSolution = this.imagesSolution.filter(i => {
                                    return i.title !== image.title;
                                })
                            }
                        } else if (image.origin === 'structure') {
                            if (image.src.startsWith('https')) {
                                this.$store.dispatch('supprimerImageNonConformite', {nonConformite: this.nonConformite, image: image}).then(response => {
                                    if(!!response.nc) {
                                        this.imageStructure = this.imageStructure.filter(i => {
                                            return i.title !== image.title;
                                        });
                                    } else {
                                        console.log(response);
                                    }
                                });
                            } else {
                                this.imageStructure = this.imageStructure.filter(i => {
                                    return i.title !== image.title;
                                })
                            }
                        }
                        this.image.visible = false;
                    }
                });
            },

            //FILES
            //uploads files
            uploadFichiers(fileList){
                this.nonConformite.ncFichiers = this.nonConformite.ncFichiers.concat(fileList);
            },

            //uploads solution files
            uploadFichiersSolution(fileList){
                this.nonConformite.ncFichiersSolution = this.nonConformite.ncFichiersSolution.concat(fileList);
            },

            //deletes files
            async removeFile({ file, fileOrigin} ) {
                //if user really want to delete the file
                if(confirm('Êtes-vous sûre de vouloir supprimer ce fichier ?')) {
                    //if the solution file has not been uploaded yet
                    if(!!file.toUpload) {
                        //if file is a solution file
                        if(fileOrigin === 'solution') {
                            this.nonConformite.ncFichiersSolution = this.nonConformite.ncFichiersSolution.filter(f => f.name !== file.name);
                        }
                        //else
                        else if(fileOrigin === 'structure') {
                            this.nonConformite.ncFichiers = this.nonConformite.ncFichiers.filter(f => f.name !== file.name);
                        }
                    }
                    //else, if file is already uploaded
                    else {
                        //deletes file
                        const nc = await this.supprimerFichierNonConformite({
                            nonConformite: this.nonConformite,
                            file: file,
                            fileOrigin: fileOrigin,
                        });

                        //if file has been deleted successfully
                        if(!!nc) {
                            //if deleted file was a solution file
                            if(fileOrigin === 'solution') {
                                //puts all solution files to upload in the updated array of solution files
                                for(let solutionFile of this.nonConformite.ncFichiersSolution) {
                                    if(!!solutionFile.toUpload) {
                                        nc.ncFichiersSolution.push(solutionFile);
                                    }
                                }

                                this.nonConformite.ncFichiersSolution = nc.ncFichiersSolution;
                            }
                            //else
                            else if(fileOrigin === 'structure') {
                                //puts all files to upload in the updated array of files
                                for(let file of this.nonConformite.ncFichiers) {
                                    if(!!file.toUpload) {
                                        nc.ncFichiers.push(file);
                                    }
                                }

                                this.nonConformite.ncFichiers = nc.ncFichiers;

                            }
                        }
                    }
                }
            },

            //MEMBERS

            //sets corrections actions members list
            setCorrectiveActionMembers(members) {
                this.nonConformite.abonnesActionCorrective = members;
            },

            //adds selected user to NC members list
            setAbonnementNonconformite(userId){

                let data = {
                    nonConformiteId: this.nonConformite.id,
                    userId: userId
                };

                if (this.estAbonne) {
                    this.$store.dispatch('desabonnerUserNonConformite', data).then(response => {
                        this.nonConformite.abonnes = response.data.nonConformite.abonnes;
                        this.nonConformite.abonnesActionCorrective = response.data.nonConformite.abonnesActionCorrective;
                        //this.nonConformiteStore.nonConformite.abonnes = utils.copyObject(response.data.nonConformite.abonnes);
                    });
                }
                else {
                    this.$store.dispatch('abonnerUserNonConformite', data).then(response => {
                        this.nonConformite.abonnes = response.data.nonConformite.abonnes;
                        this.nonConformite.abonnesActionCorrective = response.data.nonConformite.abonnesActionCorrective;
                        //this.nonConformiteStore.nonConformite.abonnes = utils.copyObject(response.data.nonConformite.abonnes);
                    });
                }
            },
        },

        beforeRouteLeave (to, from, next) {
            if( !(to.name === 'edit' && from.name === 'create')){
                if (this.hasUnsavedChanges() ){
                    let width = 0;
                    switch (this.$vuetify.breakpoint.name) {
                        case 'xs':
                            width = 290;
                            break;
                        case 'sm':
                            width = 290;
                            break;
                        case 'md':
                            width = this.$vuetify.breakpoint.width * 0.2;
                            break;
                        case 'lg':
                            width = this.$vuetify.breakpoint.width * 0.3;
                            break
                        case 'xl':
                            width = this.$vuetify.breakpoint.width * 0.4;
                            break;
                    }
                    this.$confirm('Êtes-vous sûr de vouloir quitter, vous avez des changements qui n\'ont pas été sauvegardés ?',
                        {
                            title: 'Quitter ?',
                            width: width,
                            persistent: true,
                            buttonTrueText: 'Quitter',
                            buttonFalseText: 'Rester',
                            icon: 'warning'
                        }).then(res => {
                            if(res){
                                next();
                            } else {
                                next(false);
                            }
                    });
                } else {
                    next();
                }
            } else {
                next();
            }
        }
    }
</script>

<style scoped>
    .upload-btn i {
        margin: 0;
    }
    .upload-btn {
        padding-left: 0;
    }
    #btn_save{
        background-color: green !important;
    }

    #btn_save[disabled="disabled"]{
        background-color: rgba(25, 155, 9, 0.95) !important;
    }
</style>
