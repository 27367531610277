<template>
    <v-container fluid>
        <h2 class="mb-4"> Non-Conformité </h2>
        <v-card>
            <v-card-title class="headline">Numéro de projets</v-card-title>
            <v-card-text>
                <label class="label">Prochain numéro de projet : </label> {{ entrepriseStore.futureProjects.projets.maxNC + 1 }}
                <br/>
                <label class="label">Limite des numéro de projets NC : </label> {{ entrepriseStore.futureProjects.projets.donneesSIGM.maximum }}
                <br/>
                <label class="label">Nombre de numéro de projets SIGM disponible : </label>
                <span :class="critical"> {{ entrepriseStore.futureProjects.projets.donneesSIGM.compte }}</span>
            </v-card-text>
        </v-card>

    </v-container>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        name: "AdminNonConformite",
        computed: {
            ...mapState({
                entrepriseStore: state => state.entrepriseStore,
            }),

            critical(){
                return this.entrepriseStore.futureProjects.projets.donneesSIGM.compte < 50 ? 'critical' : 'normal';
            }
        },
    }
</script>

<style scoped>
    .critical{
        color: white;
        background-color: red;
        padding: 5px;
    }
    .normal{
        color: white;
        background-color: green;
        padding: 5px;
    }
</style>
