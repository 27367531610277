<template>
    <v-container fluid>
        <CarteNonConformite v-for="nc in this.initialListe" :key="nc.id" :initialNc="nc"></CarteNonConformite>
    </v-container>
</template>

<script>
    import CarteNonConformite from './CarteNonConformite';
    import CarteNonConformitePaginator from './CarteNonConformitePaginator';

    export default {
        components: {CarteNonConformite, CarteNonConformitePaginator},
        name: "ListeCarteNonConformite",
        props: ['initialListe', 'initialEtat'],

        created(){
        },

        data(){
            return {
            }
        },
    }
</script>

<style scoped>

</style>
