import $axios from '../../api';

const state = {
    //all tags (same as Gallup)
    tags: [],
    //current active tag
    activeTag: {},
    //default tag object
    defaultTag: {
        id: null,
        title: '',
    },
};

const getters = {
    //gets list of all tags (same as Gallup)
    getTags(state) {
        return state.tags;
    },
    //gets current active tag
    getActiveTag(state) {
        return state.activeTag;
    },
};

const mutations = {
    //sets list of all tags (same as Gallup)
    setTags(state, tags) {
        state.tags = tags;
    },
    //sets the current tag
    setActiveTag(state, id) {
        if(!!id) {
            state.activeTag = state.tags.find(t => t.id === id);
        }
        else {
            state.activeTag = JSON.parse(JSON.stringify(state.defaultTag));
        }
    },
    //adds tag to list when storing a new one
    addTag(state, tag) {
        state.tags.push(tag);
    },
    //modifies a tag in the list
    modifyTag(state, tag) {
        const tags = [...state.tags];
        const index = state.tags.findIndex(t => t.id === tag.id);
        tags[index] = tag;
        state.tags = tags;
    },
    //removes tag of lists after deleting one
    removeTag(state, tag) {
        state.tags = state.tags.filter(t => t.id !== tag.id);
    },
};

const actions = {
    //fetch list of all tags (same as Gallup)
    async fetchTags({ commit }) {
        try {
            const { data } = await $axios.get('tags');
            commit('setTags', data.tags);
        } catch(error) {
            console.log(error);
        }
    },
    async storeTag({ commit }, tag) {
        try {
            const { data } = await $axios.post('tags', tag);
            commit('addTag', data.tag);
        } catch (error) {
            console.log(error);
        }
    },
    async editTag({ commit }, tag) {
        try {
            const requestBody = {...tag};
            delete requestBody.id;

            const { data } = await $axios.put(`tags/${tag.id}`, requestBody);
            commit('modifyTag', data.tag);
        } catch (error) {
            console.log(error);
        }
    },
    async destroyTag({ commit }, id) {
        try {
            const { data } = await $axios.delete(`tags/${id}`);
            commit('removeTag', data.tag);
        } catch (error) {
            console.log(error);
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
