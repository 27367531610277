import $axios from '../../api';

const state = {
    //all categories
    categories: [],

    //current active category
    activeCategory: {},

    //default category object
    defaultCategory: {
        nom: '',
    },
};

const getters = {
    //gets list of all categories
    getAllCategories(state) {
        return state.categories;
    },
    //gets current active category
    getActiveCategory(state) {
        return state.activeCategory;
    },
};

const mutations = {
    //sets list of all categories
    setCategories(state, categories) {
        state.categories = categories;
    },
    //sets the current category
    setActiveCategory(state, id) {
        if(!!id) {
            state.activeCategory = state.categories.find(c => c.id === id);
        }
        else {
            state.activeCategory = JSON.parse(JSON.stringify(state.defaultCategory));
        }
    },
    //adds category to list when storing a new one
    addCategory(state, category) {
        state.categories.push(category);
    },
    //modifies a category in the list
    modifyCategory(state, category) {
        const categories = [...state.categories];
        const index = state.categories.findIndex(c => c.id === category.id);
        categories[index] = category;
        state.categories = categories;
    },
    //removes category of lists after deleting one
    removeCategory(state, category) {
        state.categories = state.categories.filter(c => c.id !== category.id);
    },
};

const actions = {
    //fetches list of all categories
    async fetchCategories({ commit }) {
        try {
            const { data } = await $axios.get('nc/categories');
            commit('setCategories', data.categories);
        } catch(error) {
            console.log(error);
        }
    },

    //stores a new category
    async storeCategory({ commit }, category) {
        try {
            const { data } = await $axios.post('nc/categories', category);
            commit('addCategory', data.category);
        } catch (error) {
            console.log(error);
        }
    },

    //modifies an existing category
    async editCategory({ commit }, category) {
        try {
            const { data } = await $axios.put(`nc/categories/${category.id}`, category);
            commit('modifyCategory', data.category);
        } catch (error) {
            console.log(error);
        }
    },

    //deletes a category
    async destroyCategory({ commit }, id) {
        try {
            const { data } = await $axios.delete(`nc/categories/${id}`);
            commit('removeCategory', data.category);
        } catch (error) {
            console.log(error);
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
