<template>
    <div class="text-xs-center">
        <v-pagination
            v-model="page"
            :length="Math.ceil(this.initialEtat.visible.length / 5)"
            :total-visible="5"
            @input="onPageChange"
        ></v-pagination>
    </div>
</template>

<script>
    export default {
        name: "CarteNonConformitePaginator",
        props: ['initialEtat'],
        data(){
            return {
                etat : this.initialEtat,
                page: 1
            }
        },

        methods: {
            onPageChange(page){
                console.log(['change to page', this.page, this.etat, page]);
            },
        }
    }
</script>

<style scoped>

</style>
