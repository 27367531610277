<template>
    <div>
        <v-container fluid fill-height>
            <v-layout align-center justify-center>
                <v-flex xs12 sm8 md6 lg6>
                    <v-card class="elevation-10">
                        <v-toolbar dark color="login">
                            <img :src="images.logo" alt="" width="60px">
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn flat disabled style="color: white !important">Connexion</v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-card-text>
                            <v-alert :value="loginError" type="error">{{ loginErrorMessage }}</v-alert>
                            <v-form>
                                <v-text-field v-model='email' prepend-icon="person" name="login" id="login"
                                              @keyup.enter.native="validateLogin" label="Courriel :" type="text"></v-text-field>
                                <v-text-field v-model='password' prepend-icon="lock" name="password" id="password" label="Mot de passe :"
                                              type="password" @keyup.enter.native="validateLogin"></v-text-field>
                                <v-checkbox v-model="remember" :label="`Se souvenir de moi`"></v-checkbox>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn dark color="login" @click="validateLogin" id="btn_login">Login</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { LOGIN_STORE, NON_CONFORMITES_STORE, ENTREPRISE_STORE } from '../../constants';

    export default {
        name: "Login",

        computed: {
          ...mapState({
            loginStore: state => state.loginStore
          })
        },
        data(){
          return {
              email: '',
              password: '',
              remember: false,
              loginError: false,
              loginErrorMessage: null,
              images: {
                  logo: require("../../assets/gnc_fulltext_logo_50px.png")
              }
          }
        },

        components: {

        },

        methods: {
            validateLogin() {
                this.$store.dispatch(LOGIN_STORE.actions.VALIDATE_LOGIN, {
                    email: this.email,
                    password: this.password,
                    remember: this.remember
                })
                .then( response => {
                    this.loginError = false;
                    this.$store.dispatch(NON_CONFORMITES_STORE.actions.FETCH_LISTE_NOTIFICATIONS).then( response => {
                        this.$router.push({ name: 'dashboard'});
                    })

                })
                .catch( error => {
                    this.loginError = true;
                    this.loginErrorMessage = error.message;
                })
            },
        }
    }
</script>

<style scoped>
    .login-background{
        background-image: url('../../assets/login-background.png');
        background-size: cover;
    }
</style>
