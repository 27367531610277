import { render, staticRenderFns } from "./Histo.vue?vue&type=template&id=5b894349&scoped=true"
import script from "./Histo.vue?vue&type=script&lang=js"
export * from "./Histo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b894349",
  null
  
)

export default component.exports