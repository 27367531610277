<template>
    <v-container fluid>
        <div class="viewTitle mb-2 pa-2">Administration</div>

        <v-tabs v-model="active" color="cyan" dark slider-color="yellow darken-2">

            <v-tab v-for="tab in tabs" :key="tab.id" ripple>
                {{ tab.name }}
            </v-tab>
            <v-tab-item v-for="tab in tabs" :key="tab.id">
                <v-card flat>
                    <component v-bind:is="currentComponent"/>
                </v-card>
            </v-tab-item>

        </v-tabs>

    </v-container>
</template>

<script>
    import ListeUsagers from '../components/admin/ListeUsagers';
    import ListeCategories from '../components/admin/ListeCategories';
    import ListeCauses from '../components/admin/ListeCauses';
    import ListeActionsCorrectives from "../components/admin/ListeActionsCorrectives";
    import ListeDepartements from "../components/admin/ListeDepartements";
    import Listetags from "../components/admin/IndexTags";
    import AdminNonConformite from '../components/admin/AdminNonConformite'
    import { ENTREPRISE_STORE, NON_CONFORMITES_STORE } from "../constants";
    import { mapState } from 'vuex';

    export default {
        name: "Admin",
        computed: {
            ...mapState({
                entrepriseStore: state => state.entrepriseStore,
            }),

            currentComponent(){
                if(this.active){
                    return this.tabs[this.active].component;
                } else {
                    return this.tabs[0].component;
                }

            }
        },
        components: {
            ListeUsagers,
            ListeCategories,
            ListeCauses,
            ListeActionsCorrectives,
            ListeDepartements,
            Listetags,
            AdminNonConformite,
        },
        created() {
            Promise.all([
                    this.$store.dispatch(ENTREPRISE_STORE.actions.FETCH_GROUPS),
                    this.$store.dispatch(ENTREPRISE_STORE.actions.FETCH_USERS),
                    this.$store.dispatch(ENTREPRISE_STORE.actions.FETCH_NONE_GNC_USERS),
                    this.$store.dispatch(ENTREPRISE_STORE.actions.FETCH_FUTURE_PROJECTS),
                    this.$store.dispatch(NON_CONFORMITES_STORE.actions.FETCH_PARAMS_NON_CONFORMITE)
            ])
            .then( response => {});
        },

        data(){
            return {
                active: null,
                tabs: [
                    {
                        id: 0,
                        name: 'Usagers',
                        component: 'ListeUsagers'
                    },
                    {
                        id: 1,
                        //NOTE: Categories have been renamed to Détection Des Non-Conformités but are still called categories in the code(we only changed the labels)
                        name: 'Détection des non-conformités',
                        component: 'ListeCategories'
                    },
                    {
                        id: 2,
                        name: 'Causes',
                        component: 'ListeCauses'
                    },
                    {
                        id: 3,
                        name: 'Actions Correctives',
                        component: 'ListeActionsCorrectives'
                    },
                    {
                        id: 4,
                        name: 'Départements',
                        component: 'ListeDepartements'
                    },
                    {
                        id: 5,
                        name: 'Tags',
                        component: 'Listetags',
                    },
                    {
                        id: 6,
                        name: 'Non-Conformité',
                        component: 'AdminNonConformite'
                    },
                ]
            }
        },

    }
</script>

<style scoped>
</style>
