<template>
    <div>
        <v-card>
            <v-card-title>
                <h2>{{ !useAsSelector ? 'Index tags' : 'Ajouter tags' }}</h2>

                <v-divider
                    v-if="!useAsSelector"
                    class="mx-4"
                    inset
                    vertical
                />

                <v-btn
                    v-if="!useAsSelector"
                    color="green darken-2"
                    dark
                    @click="openEditDialog(null)"
                >
                    Nouveau Tag
                </v-btn>
            </v-card-title>

            <v-card-text>
                <!-- V-DATA-TABLE TO SHOW IF USE THE COMPONENT TO SELECT TAGS(we show it with checkbox) -->
                <v-data-table
                    v-if="useAsSelector"
                    v-model="ncTags"
                    :headers="tagsHeaders"
                    :items="getTags"
                    :custom-filter="filter"
                    @input="emitSetTagsToAddSelection"
                >
                    <!-- headers -->
                    <template v-slot:headers="props">
                        <tr>
                            <th>
                                <v-checkbox
                                    :input-value="props.all"
                                    :indeterminate="props.indeterminate"
                                    primary
                                    hide-details
                                    @click.stop="toggleAll"
                                />
                            </th>
                            <th>
                                <v-text-field
                                    v-model="props.headers[0].search"
                                    class="text-field-width"
                                    label="Id"
                                    @input="filter"
                                />
                            </th>
                            <th>
                                <v-text-field
                                    v-model="props.headers[1].search"
                                    class="text-field-width"
                                    label="Titre"
                                    @input="filter"
                                />
                            </th>
                        </tr>
                    </template>

                    <!-- items -->
                    <template v-slot:items="props">
                        <tr
                            :active="props.selected"
                            @click="props.selected = !props.selected"
                        >
                            <td>
                                <v-checkbox
                                    :input-value="props.selected"
                                    primary
                                    hide-details
                                />
                            </td>
                            <td>{{ props.item.id }}</td>
                            <td>{{ props.item.title }}</td>
                            <td>
                                <v-icon class="mr-2"@click="openEditDialog(props.item.id)"  title="Éditer">edit</v-icon>
                                <v-icon @click="deleteTag(props.item.id)" title="Supprimer">delete</v-icon>
                            </td>
                        </tr>
                    </template>
                </v-data-table>

                <!-- V-DATA-TABLE TO SHOW IN INDEX TAGS TO CREATE, DELETE OR EDIT TAGS (we show it without checkbox) -->
                <v-data-table
                    v-if="!useAsSelector"
                    :headers="tagsHeaders"
                    :items="getTags"
                    :custom-filter="filter"
                >
                    <!-- headers -->
                    <template v-slot:headers="props">
                        <tr>
                            <th>
                                <v-text-field
                                    v-model="props.headers[0].search"
                                    class="text-field-width"
                                    label="Id"
                                    @input="filter"
                                />
                            </th>
                            <th>
                                <v-text-field
                                    v-model="props.headers[1].search"
                                    class="text-field-width"
                                    label="Titre"
                                    @input="filter"
                                />
                            </th>
                        </tr>
                    </template>

                    <!-- items -->
                    <template v-slot:items="props">
                        <tr
                            :active="props.selected"
                            @click="props.selected = !props.selected"
                        >
                            <td>{{ props.item.id }}</td>
                            <td>{{ props.item.title }}</td>
                            <td>
                                <v-icon class="mr-2"@click="openEditDialog(props.item.id)"  title="Éditer">edit</v-icon>
                                <v-icon @click="deleteTag(props.item.id)" title="Supprimer">delete</v-icon>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <!-- edit tag dialog -->
        <v-dialog v-model="editTagDialog" width="400" persistent>
            <v-card>
                <v-card-title>
                    {{ !!getActiveTag.id ? 'Modifier Tag' : 'Nouveau Tag' }}
                </v-card-title>

                <v-card-text>
                    <v-text-field v-model="title"/>
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>

                    <v-btn @click="closeEditDialog">
                        Annuler
                    </v-btn>

                    <v-btn @click="saveTag" >
                        Sauvegarder
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { mapGetters, mapMutations, mapActions } from 'vuex';

    export default {
        name: "TagsTable",
        props: ['nonConformiteTags', 'useAsSelector'],
        async mounted() {
            await this.fetchTags();

            this.originalTags = this.getTags;

            //current route name
            this.currentRouteName = this.$router.history.current.name;
        },
        data: function() {
            return {
                //tags headers
                tagsHeaders: [
                    { text: 'Id', value: 'id', search: '' },
                    { text: 'Titre', value: 'title', search: '' },
                    { text: 'Actions', value: 'actions' },
                ],

                //original copy of tags list
                originalTags: [],
                //edit dialog
                editTagDialog: false,
                //tag title
                title: '',

                //the name of the current route
                currentRouteName: '',

                //selected v-data-table row if checkbox are showed
                selected: [],
            }
        },
        computed: {
            //VUEX
            ...mapGetters(['getTags', 'getActiveTag']),

            //manages nonComformiteTags prop
            ncTags: {
                //getter for the props use as tags v-data-table (v-data-table with checkbox) v-model
                get() {
                    return this.nonConformiteTags;
                },

                //setter to set this computed property to a new value every times the nonConformiteTags prop is modified
                //we set selected (which is the property that manages the select v-data-table rows) to the new value as well
                set(newVal) {
                    this.selected = newVal;
                    return newVal
                },
            },
        },
        methods: {
            //VUEX
            ...mapMutations(['setActiveTag', 'setTags']),
            ...mapActions(['fetchTags', 'storeTag', 'editTag', 'destroyTag']),

            //TAGS
            //opens edit dialog
            openEditDialog(id) {
                this.setActiveTag(id);
                this.title = !!id ? this.getActiveTag.title : '';
                this.editTagDialog = true;
            },

            //closes edit dialog
            closeEditDialog() {
                this.title = '';
                this.editTagDialog = false;
            },

            //saves tag
            async saveTag() {
                //editing tag
                !!this.getActiveTag.id && await this.editTag({ id: this.getActiveTag.id, title: this.title });
                //storing tag
                !this.getActiveTag.id && await this.storeTag({ title: this.title });

                //updates original tags list
                this.originalTags = this.getTags;

                this.editTagDialog = false;
            },

            //delete tag
            async deleteTag(id) {
                if(confirm("Êtes-vous sûre de vouloir supprimer ce tag ?")) {
                    await this.destroyTag(id);

                    //updates original tags list
                    this.originalTags = this.getTags;
                }
            },

            //emit tags to add to current non conformité
            emitSetTagsToAddSelection() {
                this.$emit('setTagsToAddSelection', this.selected);
            },

            //TABLE CHECKBOX
            toggleAll () {
                if (this.selected.length > 0) {
                    this.selected = [];
                    this.emitSetTagsToAddSelection()
                }
                else {
                    this.selected = this.getTags.slice();
                    this.emitSetTagsToAddSelection()
                }
            },

            //TABLE FILTER (all the glory goes to LOUIS JASMIN)
            filter() {
                //cloning tags list
                let filteredData = [...this.originalTags];

                //for each different column we can search with
                this.tagsHeaders.forEach(header => {
                    //we filter the tags by looking at each tag (item)
                    filteredData = filteredData.filter(item => {
                        //if the column has a search header and there is data to search for
                        if (!!header.search && header.search.length > 0) {
                            //we get the item we are searching in the list
                            const property = item[header.value];

                            //we returns all items that match the search
                            return property.toString().toLowerCase().includes(header.search.toLowerCase())
                        }
                        //filteredData.filter must always return true
                        return true;
                    });
                });

                this.setTags(filteredData);
            },
        },
    }
</script>

<style scoped>
    .text-field-width {
        width: 70%;
    }
</style>
