<template>
    <v-container fluid>
        <div class="viewTitle mb-3 pa-2">
            Notifications
        </div>


        <v-tabs
            v-model="active"
            dark
        >
            <v-tab>
                Notifications
            </v-tab>
            <v-tab>
                Paramètres
            </v-tab>
            <v-tab-item>
                <v-card flat>
                    <v-card-text>
                        <div class="text-xs-right" v-if="selected.length > 0">
                        <v-btn flat icon color="blue darken-2" v-if="showBtnRead">
                            <v-icon @click="updateNotificationsReadStatus(false)">mail</v-icon>
                        </v-btn>
                        <v-btn flat icon color="blue darken-2" v-if="!showBtnRead">
                            <v-icon @click="updateNotificationsReadStatus(true)">mail_outline</v-icon>
                        </v-btn>
                    </div>

                        <v-data-table
                            v-model="selected"
                            :headers="headers"
                            :items="nonConformiteStore.listeNotifications"
                            class="elevation-1"
                            hide-actions
                            item-key="id"
                            select-all
                        >
                            <template v-slot:items="props">
                                <td>
                                    <v-checkbox
                                        v-model="props.selected"
                                        color="blue darken-3"
                                        hide-details
                                    ></v-checkbox>
                                </td>
                                <td :class="getClassTextDecoration(props.item)">
                                    <a :href="`/nc/edit/${props.item.data.nonConformiteId}`">{{ props.item.data.description }}</a>
                                </td>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card flat>
                    <v-card-text>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="pa-2">Paramètre</th>
                                    <th class="pa-2">Dans l'application</th>
                                    <th class="pa-2">Par Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="transition in transitions">
                                    <td>{{transitionDictionnary[transition].inApp.setting.label}}</td>
                                    <td class="px-2">
                                        <v-switch v-model="transitionDictionnary[transition].inApp.value" @change="updateUserSetting(transitionDictionnary[transition].inApp)"></v-switch>
                                    </td>
                                    <td class="px-2">
                                        <v-switch v-model="transitionDictionnary[transition].email.value" @change="updateUserSetting(transitionDictionnary[transition].email)"></v-switch>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs>




    </v-container>
</template>

<script>
    import { mapState } from 'vuex';
    import { NON_CONFORMITES_STORE } from '../constants';

    export default {
        name: "Notifications",

        computed:{

            ...mapState({
                nonConformiteStore: state => state.nonConformiteStore,
                loginStore: state => state.loginStore
            }),

            showBtnRead(){
                if(this.selected.length > 0){
                    let hasReadItems = false;
                    for (let i = 0; i < this.selected.length; i++) {
                        if (this.selected[i].read_at) {
                            hasReadItems = true;
                            break;
                        }
                    }
                    return hasReadItems;
                } else {
                    return false;
                }
            },

            transitions(){
                return Object.keys(this.transitionDictionnary);
            }

        },

        created(){
            let vm = this;
            this.$store.dispatch(NON_CONFORMITES_STORE.actions.FETCH_LISTE_NOTIFICATIONS).then( response => {});
            this.currentUser = this.$store.getters['loggedUser'];

            this.transitionDictionnary = {};
            this.currentUser.userSettings.forEach(userSetting => {
                let currentTransition = '';
                if (userSetting.setting.name.includes('email_for_')) {
                    currentTransition = userSetting.setting.name.substring(10);
                    if (!this.transitionDictionnary.hasOwnProperty(currentTransition)) {
                        this.transitionDictionnary[currentTransition] = {
                            email: {},
                            inApp: {}
                        }
                    }
                    this.transitionDictionnary[currentTransition].email = userSetting;
                } else if (userSetting.setting.name.includes('in_app_for_')) {
                    currentTransition = userSetting.setting.name.substring(11);
                    if (!this.transitionDictionnary.hasOwnProperty(currentTransition)) {
                        this.transitionDictionnary[currentTransition] = {
                            email: {},
                            inApp: {}
                        }
                    }
                    this.transitionDictionnary[currentTransition].inApp = userSetting;
                }
            });
        },

        data(){
            return {
                active: null,
                currentUser: null,
                headers: [
                    { text: 'Description', value: 'description', sortable: false}
                ],
                selected: [],
                transitionDictionnary: null
            }
        },
        methods: {
            getClassTextDecoration(item){
                return item.read_at ? '' : 'notification-unread';
            },

            updateNotificationsReadStatus(status){
                let data = {
                    notifications: this.selected,
                    status: status
                };
                this.$store.dispatch(NON_CONFORMITES_STORE.actions.SET_NOTIFICATIONS_READ_STATUS, data).then( response => {
                });
            },

            updateUserSetting(setting) {
                this.$store.dispatch('updateUserSetting', setting).then(response => {
                    this.currentUser = this.$store.getters['loggedUser'];
                })
            }
        }
    }
</script>

<style scoped>
    .notification-unread {
        font-weight: bold;
    }
    a{
        text-decoration: none;
        color: #000000;
    }
    a:hover{
        color: blue;
    }
</style>
