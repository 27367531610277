<template>
    <v-dialog
        v-if="image != null"
        v-model="image.visible"
        max-width="1024"
    >
        <v-card>
            <v-card-title class="headline"/>
            <v-card-text>
                <v-img
                    class="images"
                    :src="image.src"
                    contain
                    height="80vh"
                />
            </v-card-text>
            <v-card-actions>
                <v-layout align-end justify-end row fill-height>
                    <!-- if image is a client image, it cannot be deleted in GNC (can be deleted from Gallup only) -->
                    <v-btn color="error" @click="removePhoto(image)" v-if="canDelete">Supprimer</v-btn>
                    <v-btn color="primary" @click="image.visible = false">Fermer</v-btn>
                </v-layout>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        props: [
            'image',
            'canDelete'
        ],
        name: "DialogPhotos",
        methods: {
            removePhoto(image) {
                this.$emit('remove', image);
            }
        }
    }
</script>

<style scoped>

</style>
