<template>
    <v-layout row wrap>
        <!-- NC add tags button -->
        <v-flex xs3>
            <v-btn @click="emitOpenTagsDialog">Ajouter tags</v-btn>
        </v-flex>

        <!-- NC tags -->
        <v-flex xs9>
            <v-chip
                v-for="(tag, index) in tags"
                :key="index"
                dark
                class="mr-1"
            >
                {{tag.title}}
            </v-chip>
        </v-flex>
    </v-layout>
</template>

<script>
    export default {
        name: "TagsSelector",
        props: ['tags'],
        methods: {
            //emits open dialog
            emitOpenTagsDialog() {
                this.$emit('openTagsDialog');
            },
        },
    }
</script>

<style scoped>

</style>
