<template>
    <v-container class="container" fluid>
        <v-card>
            <v-layout ma-1 row wrap>
                <v-flex xs12 lg3>
                    <PopUpImage
                        :popUpImageSrc="getImageSrc(nc)"
                        :width="150"
                        :height="100"
                        :thumbnailSrc="getSmallThumbsnailSrc(nc)"
                        :toRoute="{ name:'edit', params: {ncId: nc.id}}"
                        :non-conformite="nc"
                    />
                </v-flex>
                <v-flex xs9 lg8>
                    <v-layout ma-3 row wrap>
                        <v-flex xs12>
                            <span class="font-weight-bold mr-1">Numero NC: </span>{{ nc.numeroNc }}
                        </v-flex>
                        <v-flex xs12 lg6>
                            <span class="font-weight-bold mr-1">Projet: </span>{{ nc.projet }}
                        </v-flex>
                        <v-flex xs12 lg6>
                            <span class="font-weight-bold mr-1">Département: </span>{{ departement.nom }}
                        </v-flex>
                        <v-flex xs12 lg6>
                            <span class="font-weight-bold mr-1">Cause: </span>{{ nc.cause.nom }}
                        </v-flex>
                        <v-flex xs12 lg6>
                            <span class="font-weight-bold mr-1">Titre: </span>{{ nc.titre }}
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex xs3 lg1>
                    <v-card-text class="text-xs-right">
                        <v-btn flat small fab class="success" :to="{ name:'edit', params: {ncId: nc.id}}"><v-icon>edit</v-icon></v-btn>
                    </v-card-text>
                </v-flex>
            </v-layout>
        </v-card>
    </v-container>
</template>

<script>
    import PopUpImage from './controls/PopUpImage';

    export default {
        name: "CarteNonConformite",
        components: {PopUpImage},
        props: ['initialNc'],

        computed: {
            /*Computed propeties, on affiche soit le parentDepartement ou le sousDepartement
            Si la nc n'a pas de sousDeparement, on affiche alors le parentDepartement
            * */
            departement(){
                if(this.nc.sousDepartement){
                    return this.nc.sousDepartement
                } else {
                    return this.nc.parentDepartement
                }
          }
        },
        created() {
        },

        data(){
            return {
                nc: this.initialNc,
            };
        },

        methods: {
            getImageSrc(nc){
                if(nc.imagesFolder){
                    if (nc.imagesFolder.length > 0){
                        return  nc.imagesFolder[0].src;
                    } else {
                        return "https://gnc.groupestageline.com/files/non-conformites/no_photo.png";
                    }
                } else {
                    return "https://gnc.groupestageline.com/files/non-conformites/no_photo.png";
                }
            },

            getSmallThumbsnailSrc(item){
                let retval = null;
                if(item.imagesFolder.length > 0){
                    if(item.imagesFolder[0].smThumbnail !== null) {
                        retval =  item.imagesFolder[0].smThumbnail.src;
                    } else {
                        retval =  "https://gnc.groupestageline.com/files/non-conformites/no_photo.png";
                    }
                } else {
                    retval =  "https://gnc.groupestageline.com/files/non-conformites/no_photo.png";
                }
                return retval;
            },
        }
    }
</script>

<style scoped>
    .container{
        padding: 0px;
        margin: 0px;
        margin-top: 5px;
    }
</style>
