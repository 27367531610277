<template>
    <div class="shadow pa-2 my-3">
        <h2>Notes</h2>

        <!-- SHOWS ALL NOTES -->
        <v-flex
            xs12
            wrap
            v-for="note in notes"
            :key="note.id"
        >
            <label>{{ note.createdAt }}<span v-if="!!note.user"> - {{ note.user.fullName }}</span></label>

            <p>{{ note.note }}</p>
        </v-flex>

        <!-- ADDS A NEW NOTE -->
        <v-flex
            xs12
            wrap
        >
            <!--note input-->
            <v-textarea
                v-model="newNote"
                label="Ajouter une note"
                required
                :readonly="readOnly"
                @change="emitNoteTextChange"
            />


            <!-- add note button -->
            <v-btn
                :disabled="isDisabled"
                color="info"
                @click="emitAddNewNote"
            >
                Ajouter une note
            </v-btn>
        </v-flex>
    </div>
</template>

<script>
    export default {
        name: "Notes",
        props: ['notes', 'readOnly', 'isDisabled', 'note'],
        mounted() {
            this.newNote = this.note;
        },
        watch: {
            note: function(newVal, oldVal) {
                this.newNote = newVal;
            }
        },
        data: function() {
            return {
                //new note text area v-model
                newNote: '',
            }
        },
        methods: {
            //emits add new note
            emitAddNewNote() {
                //if a new note has been entered
                if(!!this.newNote) {
                    this.$emit('addNewNote', this.newNote);

                    //resets note text area input
                    this.newNote = '';
                }
            },
            emitNoteTextChange() {
                this.$emit('noteTextChange', this.newNote);
            }
        },
    }
</script>

<style scoped>

</style>
