export default {
    methods: {
        //emits upload file
        emitUploadFile(file) {
            this.$emit('uploadFile', file);
        },

        //emits upload file solution
        emitUploadFileSolution(file) {
            this.$emit('uploadFileSolution', file);
        },

        //emits remove file
        emitRemoveFile(file) {
            this.$emit('removeFile', file);
        },
    },
};
