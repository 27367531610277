<template>
    <v-container>
        <Login></Login>
    </v-container>
</template>

<script>
  import Login from "../components/controls/Login";

    export default {
        name: "Home",
        components: {
          Login
        }
    }
</script>

<style scoped>

</style>
