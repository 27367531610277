<template>
    <div>
        <!--Rapport Cout TopCoutNcParDépartement-->
        <v-flex xs12 sm6 d-flex>
            <v-select
                v-model="departement"
                :items="listeDepartements"
                item-text="nom"
                item-value="id"
                label="Sélectionnez un département :"
                box
            ></v-select>
        </v-flex>

        Filtres : <label v-if="errorDates"> <span class="errorMessage">{{ errorMessage }}</span></label>
        <v-layout row wrap>
            <v-flex xs12 sm4 md8 lg8>
                <DatesDebutFin></DatesDebutFin>
            </v-flex>
            <v-spacer></v-spacer>
            <v-btn color="success" :loading="loading" @click="fetchData">Appliquer</v-btn>
        </v-layout>

        <v-layout row wrap>
            <v-flex xs12>
                <v-card>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            class="elevation-1 mt-4"
                            :hide-actions="hideActions"
                        >
                            <template v-slot:items="props">
                                <td>{{ props.item.departement }}</td>
                                <td>{{ props.item.sousDepartement }}</td>
                                <td>{{ props.item.modele }}</td>
                                <td>{{ props.item.unite }}</td>
                                <td>{{ props.item.total | numberFormat }} $</td>
                                <td>{{ props.item.titre }}</td>
                            </template>

                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
        <!--Fin Rapport nombre par produit-->
    </div>
</template>

<script>
    import $axios from '../../api';
    import utils from '../../utils';
    import { mapState } from 'vuex';
    import DatesDebutFin from '../controls/DatesDebutFin';
    import { ENTREPRISE_STORE} from "../../constants";

    export default {
        name: "TopCoutNcParDepartement",
        components: { DatesDebutFin },
        computed: {
            ...mapState({
                applicationStore: state => state.applicationStore,
            }),

            listeDepartements(){
                return this.$store.getters[ENTREPRISE_STORE.getters.GET_PARENT_DEPARTEMENTS];
            }
        },
        data() {
            return {
                loading: false,
                errorDates: false,
                errorMessage: "",
                items: [],
                headers: [
                    {
                        text: 'Département',
                        align: 'left',
                        value: 'departement'
                    },
                    {
                        text: 'Sous-Département',
                        align: 'left',
                        value: 'sousDepartement'
                    },
                    {
                        text: 'Modèle',
                        align: 'left',
                        value: 'modele'
                    },
                    {
                        text: 'Unité',
                        align: 'left',
                        value: 'unite'
                    },
                    {
                        text: 'Total',
                        align: 'left',
                        value: 'total'
                    },
                    {
                        text: 'Titre',
                        align: 'left',
                        value: 'titre'
                    }
                ],
                hideActions: true,
                departement: null
            }
        },
        methods: {
            async fetchData(){
                this.loading = true;

                if(!this.applicationStore.datesDebutFin.dateDebut || !this.applicationStore.datesDebutFin.dateFin){
                    this.errorDates = true;
                    this.errorMessage = 'Veuillez sélectionner vos dates.';
                    this.loading = false;
                    return;
                } else {
                    this.errorDates = false;
                }

                try{
                    const dataRapportTopCoutNcParDepartement = await $axios.get('/nc/rapports/topCoutNcParDepartement',
                        {
                            params: {
                                startDate: this.applicationStore.datesDebutFin.dateDebut,
                                endDate: this.applicationStore.datesDebutFin.dateFin,
                                departementId: this.departement
                            }
                        });
                    this.items = dataRapportTopCoutNcParDepartement.data;
                    this.loading = false;

                } catch (e){
                    console.error(e);
                    this.loading = false;
                }
            },
        }
    }
</script>

<style scoped>

</style>
