<template>
    <div>
        <v-layout row>
            <v-flex xs12>
                <v-img class="images" :src="thumbnailSrc" :width="width" height="height" @click="singleOrDoubleClick"/>
            </v-flex>
            <v-flex xs12>
                <v-dialog v-model="popUpImage" max-width="1024">
                    <v-card v-if="popUpImage">
                        <v-card-title class="headline"/>
                        <v-card-text>
                            <v-img class="images" :src="src"/>
                            <NcInfos
                                v-if="nonConformite != null"
                                :non-conformite="nonConformite"
                                selected-lang="fr"
                                :show-abonnes="false"
                                :show-tags="false"
                                :show-header="false"
                                :show-link="false"
                                :show-answer="false"
                                :show-id="nonConformite.type !== 'qualite'"
                            />
                        </v-card-text>
                        <v-card-actions>
                            <v-layout align-end justify-end row fill-height>
                                <v-btn color="primary" @click="doubleClick">Ouvrir NC</v-btn>
                                <v-btn color="primary" @click="popUpImage = false">Fermer</v-btn>
                            </v-layout>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    import NcInfos from "../../../../../shared/vue_components/NcInfos";
    export default {
        name: "PopUpImage",
        props: ['popUpImageSrc', 'width', 'height', 'thumbnailSrc', 'toRoute', 'nonConformite'],
        components: { NcInfos },
        data(){
            return {
                popUpImage: false,
                src: this.popUpImageSrc,
                clickCount: 0,
                timer: null,
            }
        },
        methods: {
            singleOrDoubleClick() {
                var vm = this;
                vm.clickCount++;
                if (vm.clickCount === 1) {
                    //attendre voir si c'est un double click
                    vm.timer = setTimeout(function() {
                        vm.clickCount = 0;
                        vm.singleClick();
                    }, 250);
                } else if (vm.clickCount === 2) {
                    clearTimeout(vm.timer);
                    vm.clickCount = 0;
                    vm.doubleClick();
                }
            },
            singleClick() {
                this.popUpImage = true;
            },
            doubleClick() {
                this.$router.push(this.toRoute);
            }
        }
    }
</script>

<style scoped>
    .images:hover{
        cursor: pointer;
    }
</style>
