<template>
    <!-- NOTES:
            - This component is controlled by the correctives actions module of the store
            - some others have their own module in store as well or are controlled by the non conformite module of the store
     -->
    <v-container fluid>
        <v-select
            v-model="selectedCorrectivesActionsType"
            :items="correctivesActionsTypes"
            label="Type actions correctives"
            style="width: 35%"
            @change="fetchCorrectiveActionByChosenType"
        >
            <template v-slot:selection="{ item }">
                <h2>{{ item.text }}</h2>
            </template>
        </v-select>

        <!-- button new corrective action -->
        <div class="mb-2 mt-5">
            <v-layout row justify-left>
                <v-btn
                    color="green darken-2"
                    dark
                    @click="openCorrectivesActionsDialog(null)"
                >
                    Nouvelle action corrective
                </v-btn>
            </v-layout>
        </div>

        <!-- correctives actions table -->
        <v-data-table
            :headers="correctivesActionsHeaders"
            :items="getCorrectivesActions"
            class="elevation-1"
        >
            <template v-slot:items="props">
                <td>{{ props.item.id }}</td>
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.type }}</td>
                <td>{{ props.item.language === 'fr' ? 'Français' : 'Anglais' }}</td>
                <td>{{ props.item.createdAt }}</td>
                <td>{{ props.item.updatedAt }}</td>
                <td>
                    <v-icon class="mr-2" title="Éditer" @click="openCorrectivesActionsDialog(props.item.id)">edit</v-icon>
                    <v-icon title="Supprimer" @click="deleteCorrectiveAction(props.item.id)">delete</v-icon>
                </td>
            </template>
        </v-data-table>

        <!-- DIALOGS -->
        <v-dialog v-model="correctivesActionsDialog" persistent max-width="500">
            <v-card>
                <v-card-title class="headline">Ajout - Action Corrective {{ getCorrectiveActionTypeLabel() }}</v-card-title>

                <v-card-text>
                    <v-select
                        v-model="selectedCorrectiveActionLanguage"
                        :items="correctivesActionsLanguages"
                        label="Langue"
                    />

                    <v-text-field
                        label="Nom de l'action corrective"
                        v-model="activeCorrectiveActionName"
                        box
                    />
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>

                    <v-btn color="green darken-1" flat @click="saveCorrectiveAction">Enregistrer</v-btn>
                    <v-btn color="red darken-1" flat @click="closeCorrectivesActionsDialog">Fermer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import { mapGetters, mapMutations, mapActions } from 'vuex';

    export default {
        name: "ListeActionsCorrectives",
        async mounted() {
            //fetching all correctives actions of type qualite by default
            await this.fetchCorrectivesActionsByType('qualite');
        },
        data: function() {
            return {
                //dialog v-model
                correctivesActionsDialog: false,
                //name of correction action being created or modified (text field v-model)
                activeCorrectiveActionName: '',
                //selected corrective action type (default is qualite)
                selectedCorrectivesActionsType: 'qualite',
                //selected corrective action language (create/edit dialog)
                selectedCorrectiveActionLanguage: 'fr',

                //correctives actions language
                correctivesActionsLanguages: [
                    { text: 'Francais', value: 'fr' },
                    { text: 'Anglais', value: 'en' },
                ],

                //correctives actions types
                correctivesActionsTypes: [
                    { text: 'Actions Correctives Qualité', value: 'qualite' },
                    { text: 'Actions Correctives Maintenance', value: 'maintenance' },
                    { text: 'Actions Correctives SAV', value: 'sav' },
                ],

                //v-data-table headers
                correctivesActionsHeaders: [
                    {
                        text: 'Id',
                        value: 'id',
                        sortable: true,
                        class: 'headers-lightgray'
                    },
                    {
                        text: 'Nom',
                        value: 'name',
                        sortable: true,
                        class: 'headers-lightgray'
                    },
                    {
                        text: 'Langue',
                        value: 'language',
                        sortable: true,
                        class: 'headers-lightgray'
                    },
                    {
                        text: 'Type',
                        value: 'type',
                        sortable: true,
                        class: 'headers-lightgray'
                    },
                    {
                        text: 'Date création',
                        value: 'createdAt',
                        sortable: true,
                        class: 'headers-lightgray'
                    },
                    {
                        text: 'Mise à jour',
                        value: 'updatedAt',
                        sortable: true,
                        class: 'headers-lightgray'
                    },
                    {
                        text: 'Actions',
                        value: 'actions',
                        class: 'headers-lightgray'
                    },
                ],
            }
        },
        computed: {
            //VUEX
            ...mapGetters(['getCorrectivesActions', 'getActiveCorrectiveAction']),
        },
        methods: {
            //VUEX
            ...mapMutations(['setActiveCorrectiveAction']),
            ...mapActions(['fetchCorrectivesActionsByType', 'storeCorrectiveAction', 'editCorrectiveAction', 'destroyCorrectiveAction']),

            //CORRECTIVES ACTIONS
            //gets label of selected corrective action type
            getCorrectiveActionTypeLabel() {
                return this.selectedCorrectivesActionsType === 'qualite' ? 'Qualité' : this.selectedCorrectivesActionsType === 'maintenance' ? 'Maintenance' : 'SAV';
            },

            //opens edit dialog
            openCorrectivesActionsDialog(id) {
                this.setActiveCorrectiveAction(id);

                if(!!id) {
                    this.activeCorrectiveActionName = this.getActiveCorrectiveAction.name;
                    this.selectedCorrectiveActionLanguage = this.getActiveCorrectiveAction.language;
                }

                this.correctivesActionsDialog = true;
            },

            //closes edit dialog
            closeCorrectivesActionsDialog() {
                //resets dialog fields
                this.activeCorrectiveActionName = '';
                this.selectedCorrectiveActionLanguage = 'fr';

                //closes dialog
                this.correctivesActionsDialog = false;
            },

            //fetches all correctives actions of chosen type
            async fetchCorrectiveActionByChosenType(type) {
                await this.fetchCorrectivesActionsByType(type);
            },

            //saves corrective action
            async saveCorrectiveAction() {
                let correctiveAction = {
                    id: this.getActiveCorrectiveAction.id,
                    name: this.activeCorrectiveActionName,
                    language: this.selectedCorrectiveActionLanguage,
                    type: this.selectedCorrectivesActionsType,
                };

                //editing corrective action
                !!this.getActiveCorrectiveAction.id && await this.editCorrectiveAction(correctiveAction);
                //storing corrective action
                !this.getActiveCorrectiveAction.id && await this.storeCorrectiveAction(correctiveAction);

                //closes dialog
                this.closeCorrectivesActionsDialog();
            },

            //delete tag
            async deleteCorrectiveAction(id) {
                if(confirm("Êtes-vous sûre de vouloir supprimer cette action corrective ?")) {
                    await this.destroyCorrectiveAction(id);
                }
            },
        },
    }
</script>

<style scoped>

</style>
