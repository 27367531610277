export default {
    methods: {
        //emits upload image
        emitUploadImage(img) {
            this.$emit('uploadImage', img);
        },

        //emits upload image solution
        emitUploadImageSolution(img) {
            this.$emit('uploadImageSolution', img);
        },

        //emits pop up image
        emitPopUpImage(img) {
            this.$emit('popUpImage', img);
        },

        //emits remove image
        emitRemoveImage(img) {
            this.$emit('removeImage', img);
        },
    },
};
