<template>
    <div>
        <v-card elevation="0">
            <!-- PAGE HEADER -->
            <div class="print-none position-sticky">
                <!-- dialog title -->
                <h1 class="primary white--text text-xs-center py-3">Mise en page de l'impression</h1>

                <!-- tool bar -->
                <v-toolbar flat dark class="primary pb-3">
                    <!-- checkbox title -->
                    <v-checkbox
                        v-model="title"
                        label="Titre"
                        color="white"
                    />

                    <!-- checkbox description -->
                    <v-checkbox
                        v-model="description"
                        label="Description"
                        color="white"
                    />

                    <!-- checkbox state -->
                    <v-checkbox
                        v-model="state"
                        label="État"
                        color="white"
                    />

                    <!-- checkbox project number -->
                    <v-checkbox
                        v-model="projectNumber"
                        label="Numéro projet"
                        color="white"
                    />

                    <!-- checkbox assembly number -->
                    <v-checkbox
                        v-model="assemblyNumber"
                        label="Numéro d'assemblage"
                        color="white"
                    />

                    <!-- checkbox nc number -->
                    <v-checkbox
                        v-model="ncNumber"
                        label="Numéro NC"
                        color="white"
                    />

                    <!-- checkbox stage unit -->
                    <v-checkbox
                        v-model="stageUnit"
                        label="Unité de scène"
                        color="white"
                    />

                    <!-- checkbox stage model -->
                    <v-checkbox
                        v-model="stageModel"
                        label="Modèle de scène"
                        color="white"
                    />

                    <!-- checkbox category -->
                    <v-checkbox
                        v-model="category"
                        label="Catégorie"
                        color="white"
                    />

                    <!-- checkbox department -->
                    <v-checkbox
                        v-model="department"
                        label="Département"
                        color="white"
                    />

                    <!-- checkbox sub department -->
                    <v-checkbox
                        v-model="subDepartment"
                        label="Sous-département"
                        color="white"
                    />

                    <!-- checkbox cause -->
                    <v-checkbox
                        v-model="cause"
                        label="Cause"
                        color="white"
                    />

                    <v-spacer />

                    <v-toolbar-items>
                        <!-- print button -->
                        <v-btn
                            title="Impression"
                            dark
                            flat
                            @click="openPrintBrowserWindow"
                        >
                            <v-icon large class="mr-2">print</v-icon>Imprimer
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <!-- nc list title -->
                <v-layout row wrap justify-center class="primary pb-3">
                    <v-flex xs3>
                        <v-text-field
                            v-model="ncListTitle"
                            dark
                            title="Saisir un titre pour la liste à imprimer"
                            label="Titre liste"
                            color="white"
                        />
                    </v-flex>
                </v-layout>
            </div>

            <!-- NC LIST -->
            <div class="ml-5 mt-5">
                <h1 class="text-xs-center mb-5">{{ncListTitle}}</h1>

                <!-- NC -->
                <div
                    v-for="(nc, index) in ncList"
                    :key="index"
                    class="mb-3 mx-auto"
                >
                    <!-- image -->
                    <img
                        alt="no photo"
                        class="d-inline-block img"
                        :src="!!nc.imagesFolder && nc.imagesFolder.length > 0 ? nc.imagesFolder[0].smThumbnail.src : 'https://gnc.groupestageline.com/files/non-conformites/no_photo.png'"
                    />

                    <!-- infos -->
                    <div class="d-inline-block infos">
                        <div v-if="ncNumber" class="px-1"><strong>#NC: </strong>{{ nc.numeroNc || 'N/A' }}</div>

                        <div v-if="title" class="px-1"><strong>Titre: </strong>{{ nc.titre || 'N/A' }}</div>

                        <div v-if="description" class="px-1"><strong>Description: </strong>{{ nc.description || 'N/A' }}</div>

                        <table>
                            <tr>
                                <td v-if="state" class="px-1"><strong>État: </strong>{{ getStateLabel(nc.etat) || 'N/A' }}</td>
                                <td v-if="projectNumber" class="px-1"><strong>Projet: </strong>{{ nc.projet || 'N/A' }}</td>
                            </tr>

                            <tr>
                                <td v-if="stageUnit" class="px-1"><strong>Unité de scène: </strong>{{ nc.stageUnit || 'N/A' }}</td>
                                <td v-if="stageModel" class="px-1"><strong>Modèle de scène: </strong>{{ nc.stageModel || 'N/A' }}</td>
                            </tr>

                            <tr>
                                <td v-if="category" class="px-1"><strong>Categorie: </strong>{{ getCategoryName(nc.ncCategorieId) || 'N/A' }}</td>
                                <td v-if="cause" class="px-1"><strong>Cause: </strong>{{ getCauseName(nc.ncCauseId) || 'N/A' }}</td>
                            </tr>

                            <tr>
                                <td v-if="department" class="px-1"><strong>Département: </strong>{{ getDepartmentName(nc.ncParentDepartementId) || 'N/A' }}</td>
                                <td v-if="subDepartment" class="px-1"><strong>Sous-département: </strong>{{ getSubDepartmentName(nc.ncDepartementId) || 'N/A' }}</td>
                            </tr>

                            <tr>
                                <td v-if="assemblyNumber" class="px-1"><strong>Assemblage: </strong>{{ nc.noAssemblage || 'N/A' }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
    //VUEX
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: "PrintNCList",
        async mounted() {
            await Promise.all([
                this.fetchParamsNonConformite(),
                this.fetchDataDepartementsEntrepriseStore(),
            ]);

            this.ncList = window.ncList;
        },
        data: function() {
            return {
                //nc list
                ncList: [],

                //nc list title v-model
                ncListTitle: '',

                //checkboxes v-model
                title: true,
                description: false,
                state: true,
                projectNumber: false,
                assemblyNumber: false,
                ncNumber: true,
                stageUnit: true,
                stageModel: false,
                category: false,
                department: false,
                subDepartment: false,
                cause: false,
            }
        },
        computed: {
            //VUEX
            ...mapGetters([
                //non conformite store
                'getCauses',
                'getCategories',
                'getStates',

                //entreprise store
                'getParentDepartements',
                'getListeSousDepartement',
            ]),
        },
        methods: {
            //VUEX
            ...mapActions([
                //non conformite store
                'fetchParamsNonConformite',

                //entreprise store
                'fetchDataDepartementsEntrepriseStore',
            ]),

            //opens print browser window
            openPrintBrowserWindow() {
                window.print()
            },

            //gets state label
            getStateLabel(stateName) {
                let state = this.getStates.find(state => state.name === stateName);

                return state ? state.label_fr : '';
            },

            //gets cause name
            getCauseName(causeId) {
                let cause = this.getCauses.find(cause => cause.id === causeId);

                return cause ? cause.nom : '';
            },

            //gets category name
            getCategoryName(categoryId) {
                let category = this.getCategories.find(category => category.id === categoryId);

                return category ? category.nom : '';
            },

            //gets department name
            getDepartmentName(departmentId) {
                let department = this.getParentDepartements.find(department => department.id === departmentId);

                return department ? department.nom : '';
            },

            //gets sub department name
            getSubDepartmentName(subDepartmentId) {
                let subDepartment = this.getListeSousDepartement.find(subDepartment => subDepartment.id === subDepartmentId);

                return subDepartment ? subDepartment.nom : '';
            },
        },
    }
</script>

<style scoped>
    .img {
        width: 100px;
        max-height: 100px;
    }

    .infos {
        vertical-align: top;
        width: 550px;
    }

    .position-sticky {
        position: sticky;
        z-index: 1000;
        top: 0%;
        left: 0%;
    }

    /* print stylesheet */
    @media print {
        .print-none {
            display: none;
        }
    }
</style>
