<template>
    <div>
        <v-layout row wrap>
            <v-flex xs6>
                <v-btn @click="addLine"><v-icon>add</v-icon></v-btn>
            </v-flex>
            <v-flex xs6>
                <p class="text-xs-right headline font-weight-medium mr-4">Total: ${{ total }}</p>
            </v-flex>
        </v-layout>
        <v-layout row wrap v-for="(line, index) in lines" :key="index">
            <v-flex xs12 lg6>
                <v-text-field @change="emitChange" class="ma-1" label="Description" v-model="line.description"/>
            </v-flex>
            <v-flex xs4 lg2>
                <v-text-field @change="emitChange" class="ma-1" label="Quantité" type="number" v-model="line.quantity"/>
            </v-flex>
            <v-flex xs4 lg2>
                <v-text-field @change="emitChange" class="ma-1" label="Prix" type="number" v-model="line.amount"/>
            </v-flex>
            <v-flex xs4 lg2 class="text-xs-right">
                <v-btn class="ma-3" @click="removeLine(index)"><v-icon>delete</v-icon></v-btn>
            </v-flex>
        </v-layout>
    </div>

</template>

<script>
    export default {
        name: "EstimeTable",
        props: ['items'],
        mounted() {
            //make a fresh copy
            if (this.items != null) {
                this.lines = JSON.parse(JSON.stringify(this.items));
            }
        },
        data() {
            return {
                tableHeaders: [

                ],
                lines: []
            }
        },
        computed: {
            total() {
                return this.lines.map(elem => elem.quantity * elem.amount).reduce((accumulator, amount) => {return accumulator + amount}, 0);
            }
        },
        methods: {
            emitChange() {
                this.$emit('change', this.lines);
            },
            addLine() {
                this.lines.push({
                    description: '',
                    quantity: 1,
                    amount: 0
                });
                this.emitChange();
            },
            removeLine(index) {
                this.lines.splice(index, 1);
                this.emitChange();
            }
        },
        watch: {
            items(newVal, oldVal) {
                if(!!newVal) {
                    this.lines = JSON.parse(JSON.stringify(newVal));
                }
            }
        }
    }
</script>

<style scoped>

</style>
