<template>
    <div>
        <h4>{{ label }}</h4>

        <!-- upload images button -->
        <uploadButton
            v-if="!!showUploadButton"
            :title="buttonLabel"
            class="my-3 white--text"
            color="green"
            :fileChangedCallback="emitUploadImage"
            :uniqueId="true"
            accept=".gif, .svg, image/*"
            :disabled="isDisabled"
            multiple
        >
            <template slot="icon">
                <v-icon class="ml-2" dark>image</v-icon>
            </template>
        </uploadButton>

        <p v-if="!imagePresent" class="ml-4 error--text">Il faut obligatoirement ajouter une image</p>

        <!-- uploaded images -->
        <div
            v-for="(img, index) in images"
            :key="index"
            class="d-inline-block"
        >
            <v-img
                class="mx-auto"
                :src="img.src"
                width="75px"
                style="cursor: pointer;"
                @click="emitPopUpImage(img)"
            />

            <p class="caption text-xs-center">Image {{ index + 1 }}</p>

            <!-- uploaded image delete button -->
            <v-btn
                v-if="!!showDeleteButton"
                class="error"
                small
                @click="emitRemoveImage(img)"
            >
                supprimer
            </v-btn>
        </div>
    </div>
</template>

<script>
    import UploadButton from 'vuetify-upload-button';

    export default {
        name: "ImagesUpload",
        props: ['images', 'isDisabled', 'showUploadButton', 'showDeleteButton', 'label', 'buttonLabel', 'imagesOrigin'],
        components: { UploadButton },
        methods: {
            //emits upload image
            emitUploadImage(files) {
                if(!!files) {
                    let _this = this;
                    let fileList = [];
                    let promises = [];


                    Object.keys(files).forEach((propertyName, index) => {
                        if(propertyName === index.toString()) {
                            let promise = new Promise(resolve => {
                                let file = files[propertyName];
                                let fReader = new FileReader();
                                fReader.readAsDataURL(files[propertyName]);

                                fReader.onloadend = function () {
                                    fileList.push({ src: this.result, title: file.name, file: file, toUpload: true });

                                    resolve();
                                };
                            });

                            promises.push(promise);
                        }
                    });

                    Promise.all(promises).then(response => _this.$emit('uploadImage', fileList));
                }
            },

            //emits pop up image
            emitPopUpImage(img) {
                this.$emit('popUpImage', { img: img, origin: this.imagesOrigin });
            },

            //emits remove image
            emitRemoveImage(img) {
                this.$emit('removeImage', {
                    src: img.src,
                    title: !!img.file ? img.file.name : img.title,
                    origin: this.imagesOrigin,
                });
            },
        },
        computed: {
            imagePresent() {
                return Array.isArray(this.images) && this.images.length > 0;
            }
        }
    }
</script>

<style scoped>

</style>
