import { render, staticRenderFns } from "./NombreNcParDepartement.vue?vue&type=template&id=239a9e1b&scoped=true"
import script from "./NombreNcParDepartement.vue?vue&type=script&lang=js"
export * from "./NombreNcParDepartement.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "239a9e1b",
  null
  
)

export default component.exports