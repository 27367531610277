<template>
    <div>
        <!--Rapport Cout par Département-->
        Filtres : <label v-if="errorDates"> <span class="errorMessage">{{ errorMessage }}</span></label>
        <v-layout row wrap>
            <v-flex xs12 sm4 md8 lg8>
                <DatesDebutFin></DatesDebutFin>
            </v-flex>
            <v-spacer></v-spacer>
            <v-btn color="success" :loading="loading" @click="fetchData">Appliquer</v-btn>
        </v-layout>

        <v-layout row wrap>
            <v-flex xs12>
                <v-card>
                    <v-card-text>
                        <GChart
                            type="ColumnChart"
                            :data="rapportCoutParDepartement.data"
                            :options="rapportCoutParDepartement.options"
                        />
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
        <!--Fin Rapport nombre par produit-->
    </div>
</template>

<script>
    import { GChart } from 'vue-google-charts';
    import $axios from '../../api';
    import utils from '../../utils';
    import { mapState } from 'vuex';
    import DatesDebutFin from '../controls/DatesDebutFin';

    export default {
        name: "CoutParDepartement",
        components: { GChart, DatesDebutFin },
        computed: {
            ...mapState({
                applicationStore: state => state.applicationStore,
            }),
        },
        data() {
            return {
                loading: false,
                errorDates: false,
                errorMessage: "",
                rapportCoutParDepartement: {
                    data: [['Departement', 'Total', { role: 'annotation' }]],
                    options: {
                        title: 'Coût par département',
                        height: 550,
                    }
                }
            }
        },
        methods: {
            async fetchData(){
                let vm = this;
                this.loading = true;

                if(!this.applicationStore.datesDebutFin.dateDebut || !this.applicationStore.datesDebutFin.dateFin){
                    this.errorDates = true;
                    this.errorMessage = 'Veuillez sélectionner vos dates.';
                    this.loading = false;
                    return;
                } else {
                    this.errorDates = false;
                }

                try{
                    this.rapportCoutParDepartement.data = [['Departement', 'Total', { role: 'annotation' }]];
                    const dataRapportCoutParDepartement = await $axios.get('/nc/rapports/coutParDepartement',
                        {
                            params: {
                                startDate: this.applicationStore.datesDebutFin.dateDebut,
                                endDate: this.applicationStore.datesDebutFin.dateFin
                            }
                        });
                    vm.rapportCoutParDepartement.data = vm.rapportCoutParDepartement.data
                        .concat(dataRapportCoutParDepartement.data.map(e => [e.departement , e.total, e.total]));
                    this.loading = false;
                } catch (e){
                    console.error(e);
                }
            },
        }
    }
</script>

<style scoped>

</style>
