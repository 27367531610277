<template>
    <!-- NOTES:
            - This component is controlled by the departments module of the store
            - some others have their own modules in store or are controlled by the non conformite module of the store
     -->
    <v-container fluid>
        <h2>Départements et sous-départements</h2>

        <!-- button new department -->
        <div class="mb-2 mt-5">
            <v-layout row justify-left>
                <v-btn
                    color="green darken-2"
                    dark
                    @click="openDepartmentsDialog(null)"
                >
                    Nouveau département
                </v-btn>
            </v-layout>
        </div>

        <!-- causes table -->
        <v-data-table
            :headers="departmentsHeaders"
            :items="getAllDepartments"
            class="elevation-1"
        >
            <template v-slot:items="props">
                <td>{{ props.item.id }}</td>
                <td>{{ getParentDepartmentName(props.item.parentId) }}</td>
                <td>{{ props.item.nom }}</td>
                <td>{{ props.item.createdAt }}</td>
                <td>
                    <v-icon class="mr-2" title="Éditer" @click="openDepartmentsDialog(props.item.id)">edit</v-icon>
                    <v-icon title="Supprimer" @click="deleteDepartment(props.item.id)">delete</v-icon>
                </td>
            </template>
        </v-data-table>

        <!-- DIALOGS -->
        <v-dialog v-model="departmentsDialog" persistent max-width="500">
            <v-card>
                <v-card-title class="headline">Ajout - Département</v-card-title>

                <v-card-text>
                    <v-select
                        v-model="selectedParentDepartmentId"
                        :items="getParentDepartments()"
                        item-text="nom"
                        item-value="id"
                        label="Département parent"
                    />

                    <v-text-field
                        label="Nom de la cause"
                        v-model="activeDepartmentName"
                        box
                    />
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>

                    <v-btn color="green darken-1" flat @click="saveDepartment">Enregistrer</v-btn>
                    <v-btn color="red darken-1" flat @click="closeDepartmentsDialog">Fermer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import { mapGetters, mapMutations, mapActions } from 'vuex';

    export default {
        name: "ListeDepartements",
        async mounted() {
            //fetching all departments (sub departments included)
            await this.fetchDepartments();
        },
        data: function() {
            return {
                //dialog v-model
                departmentsDialog: false,

                //name of department being created or modified (text field v-model)
                activeDepartmentName: '',

                //id of the selected parent department
                selectedParentDepartmentId: null,

                //v-data-table headers
                departmentsHeaders: [
                    {
                        text: 'Id',
                        value: 'id',
                        sortable: true,
                        class: 'headers-lightgray'
                    },
                    {
                        text: 'Département Parent',
                        value: 'parentId',
                        sortable: true,
                        class: 'headers-lightgray'
                    },
                    {
                        text: 'Nom',
                        value: 'nom',
                        sortable: true,
                        class: 'headers-lightgray'
                    },
                    {
                        text: 'Date création',
                        value: 'createdAt',
                        sortable: true,
                        class: 'headers-lightgray'
                    },
                    {
                        text: 'Actions',
                        value: 'actions',
                        class: 'headers-lightgray'
                    },
                ],
            }
        },
        computed: {
            //VUEX
            ...mapGetters(['getAllDepartments', 'getActiveDepartment']),
        },
        methods: {
            //VUEX
            ...mapMutations(['setActiveDepartment']),

            ...mapActions([
                'fetchDepartments',
                'storeDepartment',
                'editDepartment',
                'destroyDepartment']
            ),

            //DEPARTMENTS
            //gets all parent departments
            getParentDepartments() {
                //creating an array with the option of selecting nothing at all
                return [{ id: null, nom: 'Aucun' }, ...this.getAllDepartments.filter(d => !d.parentId)];
            },

            //gets parent department name
            getParentDepartmentName(parentDepartmentId) {
                let parentDepartment = this.getAllDepartments.find(d => d.id === parentDepartmentId);

                return !!parentDepartment ? parentDepartment.nom : ''
            },

            //opens edit dialog
            openDepartmentsDialog(id) {
                this.setActiveDepartment(id);

                if(!!id) {
                    this.activeDepartmentName = this.getActiveDepartment.nom;
                    this.selectedParentDepartmentId = this.getActiveDepartment.parentId;
                }

                //opens dialog
                this.departmentsDialog = true;
            },

            //closes edit dialog
            closeDepartmentsDialog() {
                //resets dialog fields
                this.activeDepartmentName = '';
                this.selectedParentDepartmentId = null;

                //closes dialog
                this.departmentsDialog = false;
            },

            //saves department
            async saveDepartment() {
                let category = {
                    id: this.getActiveDepartment.id,
                    parentId: this.selectedParentDepartmentId,
                    nom: this.activeDepartmentName,
                };

                //editing department
                !!this.getActiveDepartment.id && await this.editDepartment(category);
                //storing department
                !this.getActiveDepartment.id && await this.storeDepartment(category);

                //closes dialog
                this.closeDepartmentsDialog();
            },

            //deletes departments
            async deleteDepartment(id) {
                if(confirm("Êtes-vous sûre de vouloir supprimer cette categorie ?")) {
                    await this.destroyDepartment(id);
                }
            },
        },
    }
</script>

