<template>
    <div>
        <h2>Détails actions correctives</h2>

        <!-- corrective action completion message -->
        <!--
            NOTES:
                - shows when isCorrectiveActionCompleted prop is true
                - nothing is showed when isCorrectiveActionCompleted prop is false
        -->
        <v-flex
            v-if="isCorrectiveActionCompleted"
            xs12
        >
            <h2 class="success--text">Action corrective complété</h2>
        </v-flex>

        <!-- switch button to choose if we need corrective actions or not -->
        <v-flex xs12>
            <v-switch
                v-model="correctiveActionRequired"
                label="Action corrective requise"
                :disabled="isDisabled"
                @change="emitSetCorrectiveActionRequired"
            />
        </v-flex>

        <v-layout
            v-if="correctiveActionRequired"
            row
            wrap
        >
            <!-- autocomplete to choose a corrective action (can choose as much as needed but only one at the time)-->
            <v-flex xs6 v-if="showAutocomplete">
                <v-autocomplete
                    v-model="chosenCorrectiveAction"
                    label="Actions correctives"
                    :items="correctivesActions"
                    item-text="name"
                    item-value="name"
                />
            </v-flex>

            <!-- button inserts chosen corrective action from autocomplete above into proposed corrective action(actionCorrectiveProposee) text below -->
            <v-flex xs6 v-if="showAutocomplete">
                <v-btn
                    fab
                    class="primary"
                    @click="emitInsertIntoProposedCorrectiveActions"
                >
                    <v-icon color="white">add</v-icon>
                </v-btn>
            </v-flex>

            <!-- text area to write all corrections actions (free text) -->
            <!-- everything selected in the autocomplete above will appear here as well -->
            <v-flex xs12>
                <v-textarea
                    v-model="proposedAction"
                    label="Action corrective proposée"
                    :readonly="readOnly"
                    @input="emitSetProposedCorrectiveAction"
                />
            </v-flex>

            <!-- completed button -->
            <v-flex
                xs6
                class="mt-5"
                v-if="showCompletionButtons"
            >
                <v-btn
                    class="success"
                    large
                    @click="emitSetCorrectiveActionCompleted(true)"
                >
                    Complété
                </v-btn>
            </v-flex>

            <!-- cancel button -->
            <v-flex
                xs6
                class="mt-5"
                v-if="showCompletionButtons"
            >
                <v-btn
                    class="error"
                    large
                    @click="emitSetCorrectiveActionCompleted(false)"
                >
                    Annuler
                </v-btn>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    export default {
        name: "CorrectivesActions",
        props: ['isDisabled', 'readOnly', 'showAutocomplete', 'isCorrectiveActionRequired', 'isCorrectiveActionCompleted', 'proposedCorrectiveAction', 'correctivesActions', 'showCompletionButtons'],
        data: function() {
            return {
                //chosen corrective action from autocomplete
                chosenCorrectiveAction: '',
            }
        },
        computed: {
            //computed property for isCorrectiveActionRequired prop
            correctiveActionRequired: {
                get() {
                    return this.isCorrectiveActionRequired;
                },

                set(newVal) {
                    return newVal
                }
            },

            //computed property for proposedCorrectiveAction prop
            proposedAction: {
                get() {
                    return this.proposedCorrectiveAction;
                },

                set(newVal) {
                    return newVal
                }
            },
        },
        methods: {
            //emits insert into proposed corrective action text
            emitInsertIntoProposedCorrectiveActions() {
                //if there is a chosen corrective action to insert
                if(!!this.chosenCorrectiveAction) {
                    this.$emit('insertIntoProposedCorrectiveActions', this.chosenCorrectiveAction);

                    //reinitializing autocomplete
                    this.chosenCorrectiveAction = '';
                }
            },

            //emits set corrective action required
            emitSetCorrectiveActionRequired(isCorrectiveActionRequired) {
                this.$emit('setCorrectiveActionRequired', isCorrectiveActionRequired);
            },

            //emits set proposed corrective action (when user enter text in text area)
            emitSetProposedCorrectiveAction(proposedCorrectiveAction) {
                this.$emit('setProposedCorrectiveAction', proposedCorrectiveAction);
            },

            //emits set corrective action completed
            emitSetCorrectiveActionCompleted(isCorrectiveActionCompleted) {
                this.$emit('setCorrectiveActionCompleted', isCorrectiveActionCompleted);
            },
        },
    }
</script>

<style scoped>

</style>
