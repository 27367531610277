import $axios from '../../api';

const state = {
    //all correctives actions
    correctivesActions: [],
    //current active correction action
    activeCorrectiveAction: {},
    //default corrective action object
    defaultCorrectiveAction: {
        name: '',
        language: 'fr',
        type: '',
    },
};

const getters = {
    //gets list of all correctives actions
    getCorrectivesActions(state) {
        return state.correctivesActions;
    },
    //gets current active corrective action
    getActiveCorrectiveAction(state) {
        return state.activeCorrectiveAction;
    },
};

const mutations = {
    //sets list of all correctives actions
    setCorrectivesActions(state, correctivesActions) {
        state.correctivesActions = correctivesActions;
    },
    //sets the current corrective action
    setActiveCorrectiveAction(state, id) {
        if(!!id) {
            state.activeCorrectiveAction = state.correctivesActions.find(c => c.id === id);
        }
        else {
            state.activeCorrectiveAction = JSON.parse(JSON.stringify(state.defaultCorrectiveAction));
        }
    },
    //adds corrective action to list when storing a new one
    addCorrectiveAction(state, correctiveAction) {
        state.correctivesActions.push(correctiveAction);
    },
    //modifies a corrective action in the list
    modifyCorrectiveAction(state, correctiveAction) {
        const correctivesActions = [...state.correctivesActions];
        const index = state.correctivesActions.findIndex(c => c.id === correctiveAction.id);
        correctivesActions[index] = correctiveAction;
        state.correctivesActions = correctivesActions;
    },
    //removes corrective action of lists after deleting one
    removeCorrectiveAction(state, correctiveAction) {
        state.correctivesActions = state.correctivesActions.filter(c => c.id !== correctiveAction.id);
    },
};

const actions = {
    //fetch list of all correctives actions
    async fetchCorrectivesActionsByType({ commit }, type) {
        try {
            const { data } = await $axios.get(`nc/correctives/${type}`);
            commit('setCorrectivesActions', data.correctivesActions);
        } catch(error) {
            console.log(error);
        }
    },
    async storeCorrectiveAction({ commit }, correctiveAction) {
        try {
            const { data } = await $axios.post('nc/correctives', correctiveAction);
            commit('addCorrectiveAction', data.correctiveAction);
        } catch (error) {
            console.log(error);
        }
    },
    async editCorrectiveAction({ commit }, correctiveAction) {
        try {
            const { data } = await $axios.put(`nc/correctives/${correctiveAction.id}`, correctiveAction);
            commit('modifyCorrectiveAction', data.correctiveAction);
        } catch (error) {
            console.log(error);
        }
    },
    async destroyCorrectiveAction({ commit }, id) {
        try {
            const { data } = await $axios.delete(`nc/correctives/${id}`);
            commit('removeCorrectiveAction', data.correctiveAction);
        } catch (error) {
            console.log(error);
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
