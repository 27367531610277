<template>
    <div>
        <!--Rapport cout par Sous Departement-->
        <v-flex xs12 sm6 d-flex>
            <v-select
                v-model="sousDepartement"
                :items="listeSousDepartements"
                item-text="nom"
                item-value="id"
                label="Sélectionnez un sous-département :"
                box
            />
        </v-flex>

        Filtres : <label v-if="errorDates"> <span class="errorMessage">{{ errorMessage }}</span></label>
        <v-layout row wrap>
            <v-flex xs12 sm4 md8 lg8>
                <DatesDebutFin/>
            </v-flex>
            <v-spacer/>
            <v-btn color="success" :loading="loading" @click="fetchData">Appliquer</v-btn>
        </v-layout>

        <v-layout row wrap>
            <v-flex xs12>
                <v-card>
                    <v-card-text>
                        Nombre de non-conformites : {{ rapportCoutParSousDepartement.nombre }} <br/>
                        Total des coûts : {{ rapportCoutParSousDepartement.totalCouts | numberFormat }} $ <br/>

                        <v-data-table
                            :headers="rapportCoutParSousDepartement.dataItemsCausesHeaders"
                            :items="rapportCoutParSousDepartement.dataItemsCauses"
                            class="elevation-1 mt-4"
                            :hide-actions="hideActions"
                        >
                            <template v-slot:items="props">
                                <td>{{ props.item.nom }}</td>
                                <td>{{ props.item.nombre }}</td>
                                <td>{{ props.item.total | numberFormat }} $</td>
                            </template>
                        </v-data-table>

                        <GChart
                            type="ColumnChart"
                            :data="rapportCoutParSousDepartement.data"
                            :options="rapportCoutParSousDepartement.options"
                        />
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
        <!--Fin Rapport nombre par produit-->
    </div>
</template>

<script>
    import { GChart } from 'vue-google-charts';
    import $axios from '../../api';
    import utils from '../../utils';
    import { mapState } from 'vuex';
    import DatesDebutFin from '../controls/DatesDebutFin';
    import { ENTREPRISE_STORE} from "../../constants";

    export default {
        name: "CoutParSousDepartement",
        components: { GChart, DatesDebutFin },
        computed: {
            ...mapState({
                applicationStore: state => state.applicationStore,
            }),

            listeSousDepartements(){
                return this.$store.getters[ENTREPRISE_STORE.getters.GET_LISTE_SOUS_DEPARTEMENT];
            }
        },
        data() {
            return {
                loading: false,
                errorDates: false,
                errorMessage: "",
                sousDepartement: null,
                hideActions: true,

                rapportCoutParSousDepartement: {
                    data: [['Cause', 'Total']],
                    dataItemsCauses: [],
                    dataItemsCausesHeaders: [
                        {
                            text: 'Nom de la cause',
                            align: 'left',
                            value: 'nom'
                        },
                        {
                            text: 'Nombre',
                            value: 'nombre'
                        },
                        {
                            text: 'Total',
                            value: 'total'
                        }
                    ],
                    options: {
                        title: 'Coût par cause',
                        height: 500,
                        legend:{
                            position: 'right'
                        },
                    },
                    nombre : null,
                    totalCouts: null
                }
            }
        },
        methods: {
            async fetchData(){
                let vm = this;
                this.loading = true;
                this.rapportCoutParSousDepartement.data = [['Cause', 'Total']];

                //checks if dates have been selected
                if(!this.applicationStore.datesDebutFin.dateDebut || !this.applicationStore.datesDebutFin.dateFin){
                    this.errorDates = true;
                    this.errorMessage = 'Veuillez sélectionner vos dates.';
                    this.loading = false;
                    return;
                } else {
                    this.errorDates = false;
                }

                try{
                    this.rapportCoutParSousDepartement.data = [['Sous-Departement', 'Total']];
                    //calling backend for under department data
                    const dataRapportCoutParSousDepartement = await $axios.get('/nc/rapports/nombreCoutParSousDepartement',
                        {
                            params: {
                                startDate: this.applicationStore.datesDebutFin.dateDebut,
                                endDate: this.applicationStore.datesDebutFin.dateFin,
                                departementId: this.sousDepartement
                            }
                        });

                    //if under department was found and the data contains at least one cause
                    if(!!dataRapportCoutParSousDepartement.data.reportData && !!dataRapportCoutParSousDepartement.data.reportData.causes){
                        //gets data for google chart
                        vm.rapportCoutParSousDepartement.data = vm.rapportCoutParSousDepartement.data
                            .concat(dataRapportCoutParSousDepartement.data.reportData.causes.map(e => [e.nom, e.total]));

                        //gets total of NC for the selected under department
                        vm.rapportCoutParSousDepartement.nombre = dataRapportCoutParSousDepartement.data.reportData.nombre;
                        //get total cost of every NC for the selected department
                        vm.rapportCoutParSousDepartement.totalCouts = dataRapportCoutParSousDepartement.data.reportData.total;
                        //gets array of all causes with related info for v-data-table
                        vm.rapportCoutParSousDepartement.dataItemsCauses = dataRapportCoutParSousDepartement.data.reportData.causes;
                    } else {
                        vm.rapportCoutParSousDepartement.nombre = null;
                        vm.rapportCoutParSousDepartement.totalCouts = null;
                        vm.rapportCoutParSousDepartement.dataItemsCauses = [];
                    }
                    this.loading = false;
                } catch (e){
                    console.error(e);
                    this.loading = false;
                }
            },
        }
    }
</script>

<style scoped>

</style>
