export default {
    methods: {
        //emits set project info
        emitSetProjectInfo() {
            this.$emit('setProjectInfo');
        },
        emitChangeNoAssemblage(value) {
            this.$emit('changeNoAssemblage', value);
        },
        emitAddNewProject() {
            this.$emit('addNewProject');
        },
        emitSearchNoAssemblage(event) {
            this.$emit('searchNoAssemblage', event);
        },
        emitCheckUp(event) {
            this.$emit('checkUp', event);
        },
        emitResolutionMembersList(event) {
            this.$emit('resolutionMembersList', event);
        },
    },
};
