<template>
    <div>
        <!--Rapport Cout par Département-->
        Filtres :
        <v-layout row wrap>
            <v-flex xs12 sm4 md8 lg8>
                <DatesDebutFin></DatesDebutFin>
            </v-flex>
            <v-spacer></v-spacer>
            <v-btn color="success" :loading="loading" @click="fetchData">Appliquer</v-btn>
        </v-layout>

        <v-layout row wrap>
            <v-flex xs12>
                <v-card>
                    <v-card-text>
                        <GChart
                            type="PieChart"
                            :data="rapportCoutParDepartement.data"
                            :options="rapportCoutParDepartement.options"
                        />
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
        <!--Fin Rapport nombre par produit-->
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { GChart } from 'vue-google-charts';
    import DatesDebutFin from '../controls/DatesDebutFin';
    import $axios from '../../api';
    import utils from '../../utils';

    export default {
        name: "CoutParDepartement",
        components: { GChart, DatesDebutFin },
        computed: {
            ...mapState({
                applicationStore: state => state.applicationStore,
            }),
        },
        data() {
            return {
                loading: false,
                rapportCoutParDepartement: {
                    data: [['Departement', 'Nombre']],
                    options: {
                        title: 'Coût par département',
                        is3D: true,
                        height: 500,
                        legend:{
                            position: 'right'
                        },
                        sliceVisibilityThreshold: .01
                    }
                }
            }
        },
        methods: {
            async fetchData() {
                let vm = this;
                this.loading = true;
                try{
                    let url = `/nc/rapports/coutParDepartement?startDate=${vm.applicationStore.datesDebutFin.dateDebut}&endDate=${vm.applicationStore.datesDebutFin.dateFin}`;
                    const dataRapportCoutParDepartement = await $axios.get(url);
                    vm.rapportCoutParDepartement.data = [['Departement', 'Nombre']];
                    vm.rapportCoutParDepartement.data = vm.rapportCoutParDepartement.data
                        .concat(dataRapportCoutParDepartement.data.map(e => [e.departement + ` | ${ utils.numberFormat(e.total) } $`, e.total]));
                    vm.loading = false;
                } catch (e){
                    console.error(e);
                }
            }
        }
    }
</script>

<style scoped>

</style>
