<template>
    <div>
        <h4>{{ label }}</h4>

        <!-- upload files button -->
        <uploadButton
            v-if="!!showUploadButton"
            :title="buttonLabel"
            class="my-3 white--text"
            color="info"
            :fileChangedCallback="emitUploadFile"
            :uniqueId="true"
            accept=".txt, .rtf, .html, .pdf, .xls, .xlsx, .doc, .docx, video/*, audio/*"
            :disabled="isDisabled"
            multiple
        >
            <template slot="icon">
                <v-icon class="ml-2" dark>fas fa-file-alt</v-icon>
            </template>
        </uploadButton>

        <!-- uploaded files -->
        <div
            v-for="(file, index) in files"
            :key="index"
            class="d-inline-block mt-2 mr-3 text-xs-center"
        >
            <v-icon
                v-if="!!filesIcons[file.fileExtension]"
                :color="filesIcons[file.fileExtension].color"
                @click="openFile(file)"
            >
                {{ filesIcons[file.fileExtension].icon }}
            </v-icon>
            <p class="caption">{{ file.name }}</p>

            <!-- uploaded solution file delete button -->
            <v-btn
                class="error"
                small
                @click="emitRemoveFile(file)"
            >
                supprimer
            </v-btn>
        </div>
    </div>
</template>

<script>
    import UploadButton from 'vuetify-upload-button';
    import utils from "../../utils";

    export default {
        name: "FilesUpload",
        props: ['files', 'isDisabled', 'showUploadButton', 'showDeleteButton', 'label', 'buttonLabel', 'filesOrigin'],
        components: { UploadButton },
        data: function() {
            return {
                //files icons
                filesIcons: {
                    html: {
                        icon : 'fas fa-code',
                        color: 'blue'
                    },
                    pdf: {
                        icon: 'fas fa-file-pdf',
                        color: 'red'
                    },
                    txt: {
                        icon: 'fas fa-file-alt',
                        color: 'black'
                    },
                    rtf: {
                        icon: 'fas fa-file-alt',
                        color: 'black'
                    },
                    xls: {
                        icon: 'fas fa-file-excel',
                        color: 'green'
                    },
                    xlsx: {
                        icon: 'fas fa-file-excel',
                        color: 'green'
                    },
                    doc: {
                        icon: 'fas fa-file-word',
                        color: 'blue'
                    },
                    docx: {
                        icon : 'fas fa-file-word',
                        color: 'blue'
                    }
                },
            }
        },
        methods: {
            //emits upload file
            emitUploadFile(files) {
                if(!!files) {
                    let fileList = [];

                    Object.keys(files).forEach((propertyName, index) => {
                        if(propertyName === index.toString()) {
                            let file = files[propertyName];

                            fileList.push({ name: file.name, fileExtension: utils.getFileExtension(file.name), toUpload: true, file: file, });
                        }
                    });

                    this.$emit('uploadFile', fileList);
                }
            },

            //emits open file
            openFile(file) {
                //if file has a URL (it has to be saved to have one)
                if(!!file.url) {
                    window.open(file.url.replace('#', '%23'), '_blank');
                }
                //else, if file does not have a URL
                else {
                    alert("Vous devez sauvegarder le fichier avant de pouvoir l'ouvrir dans le navigateur.");
                }
            },

            //emits remove file
            emitRemoveFile(file) {
                this.$emit('removeFile', { file: file, fileOrigin: this.filesOrigin });
            },
        },
    }
</script>

<style scoped>

</style>
