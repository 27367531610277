const state = {
    snackbar : {
        visible: false,
        color: "",
        timeout: 10000,
        text: "",
        type: "success"
    },
    datesDebutFin: {
        dateDebut: null,
        dateFin: null
    }
};

const mutations = {
    afficherMessage(state, data){

        state.snackbar.type = data.type;
        if(data.type === 'success'){
            state.snackbar.color = 'green';
        } else if (data.type === 'error'){
            state.snackbar.color = 'red';
            state.snackbar.timeout = 0;
        }

        if(data.text){
            state.snackbar.text = data.text;
        }
        state.snackbar.visible = true;
    },
};

const actions = {
    afficherMessage( { commit }, data){
        commit('afficherMessage', data);
    },
};

const getters = {
};

export default {
    state, actions, mutations, getters
};
