<template>
    <v-expansion-panel v-if="panel != null" v-model="panel" expand>
        <v-expansion-panel-content>
            <template v-slot:header>
                <h2>Historique</h2>
            </template>
            <v-layout row wrap>
                <v-flex xs12>
                    <v-list>
                        <v-list-tile v-for="item in nonConformite.history" :key="item.id">
                            <v-list-tile-content>
                                <v-list-tile-title>{{ item.description.title }}</v-list-tile-title>
                                <v-list-tile-sub-title>{{ item.created_at }} : {{ item.description.text }}</v-list-tile-sub-title>
                            </v-list-tile-content>
                        </v-list-tile>
                    </v-list>
                </v-flex>
            </v-layout>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    export default {
        name: "Histo",
        props: ['nonConformite'],
        data: function() {
            return {
                panel: [true, true, true]
            }
        }
    }
</script>

<style scoped>

</style>
