<template>
    <v-expansion-panel>
        <v-expansion-panel-content>
            <template v-slot:header>
                <v-card elevation="0">
                    <v-card-title>
                        <h2>Gallup assignation infos</h2>

                        <v-btn
                            class="primary ml-4"
                            @click="goToGallup"
                        >
                            Lien Gallup
                        </v-btn>
                    </v-card-title>

                    <v-card-text>
                        <!-- assignation -->
                        <table>
                            <tbody>
                                <!-- assignation -->
                                <tr>
                                    <td class="px-2"><strong>Id assignation: </strong>{{ gallupInfos.assignationId }}</td>
                                    <td class="px-2"><strong>Type: </strong>{{ gallupInfos.assignationType }}</td>
                                    <td class="px-2"><strong>Soumit le: </strong>{{ gallupInfos.submissionDate }}</td>
                                    <td class="px-2"><strong>Assigné par: </strong>{{ gallupInfos.userFullName }}</td>
                                    <!-- assigned name can be the name of an assigned contact or assigned user -->
                                    <td class="px-2"><strong>Assigné à: </strong>{{ gallupInfos.assignedUserFullName || gallupInfos.assignedContactFullName || 'N/A' }}</td>
                                </tr>

                                <!-- options -->
                                <tr>
                                    <td class="px-2"><strong>Modèle scène: </strong>{{ gallupInfos.stageModel || 'N/A' }}</td>
                                    <td class="px-2"><strong>Unité de scène: </strong>{{ gallupInfos.stageUnit || 'N/A' }}</td>
                                    <td class="px-2"><strong>Date fabrication: </strong>{{ gallupInfos.stageUnitManufacturingDate || 'N/A' }}</td>
                                    <td class="px-2"><strong>Client: </strong>{{ gallupInfos.client || 'N/A' }}</td>
                                    <td class="px-2"><strong>État/Province client: </strong>{{ gallupInfos.clientStateOrProvince || 'N/A' }}</td>
                                    <td class="px-2"><strong>Ville client: </strong>{{ gallupInfos.clientCity || 'N/A' }}</td>
                                </tr>
                            <tr>

                            </tr>
                            </tbody>
                        </table>
                    </v-card-text>
                </v-card>
            </template>
            <v-card>
                <v-card-text>
                    <!-- questions tree (question with all parents and section as the main parent) -->
                    <div v-if="!!gallupInfos.questionsTree">
                        <h1 class="mt-3 mb-2">Arbre hiérarchique de la question</h1>

                        <v-treeview
                            :items="gallupInfos.questionsTree"
                            item-text="title"
                            open-all
                        >
                            <template v-slot:label="{ item }">
                                <div class="pa-2 mb-3 grey lighten-2" style="border-radius: 10px; width: 200px">
                                    <h3 class="mb-2">{{ !!item.isSection ? 'Section' : !item.children ? 'Question' : 'Question parent' }}</h3>

                                    <p class="ma-0"><strong>Id: </strong>{{ item.id }}</p>
                                    <p class="ma-0"><strong>Titre: </strong>{{ item.title }}</p>
                                    <!-- question answer -->
                                    <!-- only the NC question (last child) will have an answer -->
                                    <p v-if="item.answer" class="ma-0"><strong>Réponse: </strong>{{ item.answer }}</p>
                                </div>
                            </template>
                        </v-treeview>
                    </div>
                    <h1 v-else>La question n'a pas de parents</h1>
                </v-card-text>
            </v-card>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    export default {
        name: "GallupInfos",
        props: ['gallupInfos'],
        methods: {
            //brings user to linked Gallup report
            goToGallup() {
                window.open(this.gallupInfos.gallupURL, '_blank');
            },
        },
    }
</script>

<style scoped>

</style>
