<template>
    <!-- NOTE: readOnly and isDisabled props don't apply if user is ADMIN -->
    <div>
        <!-- DEMANDE DETAILS SECTION -->
        <div class="shadow pa-2 my-3">
            <!-- details -->
            <!-- NOTE:
                - all project methods are in the mixin projectMixin.js
                - all transitions methods are in the mixin transitionsMixin.js
            -->
            <div>
                <v-layout row wrap>
                    <!-- NC project number -->
                    <v-flex xs6>
                        <v-checkbox
                            label="Cette non-conformité ne contient pas de # projet"
                            v-model="nonConformite.isReception"
                        />
                    </v-flex>
                    <v-flex xs6>
                        <div style="text-align: right;margin-top: 15px;margin-right: 10px;">
                            <h3>Numéro du project créé par Syteline</h3>
                            <h4 style="color:green;" v-if="nonConformite.projet">{{ nonConformite.projet }}</h4>
                            <h4 style="color:darkred;" v-else>*Projet inexistant</h4>
                        </div>

                    </v-flex>
                </v-layout>
            </div>
            <DemandDetails
                :nonConformite="nonConformite"
                titleLabel="Titre"
                descriptionLabel="Description"
                :readOnly="readOnly"
                :isDisabled="isDisabled"
                :showNcParams="true"
                :showSolutionRequired="true"
                :showStatesAndCriticalitySelectBox="false"
                :isGNCQualite="isGNCQualite"
                :isGNCAdmin="isGNCAdmin"
                :isGNCSupervisor="isGNCSupervisor"
                :projectIncludesUnit="projectIncludesUnit"
                :projectMessages="projectMessages"
                :projectErrorMessages="projectErrorMessages"
                :stageUnits="stageUnits"
                :stageModels="stageModels"
                :partNos="partNos"
                :projectPartNos="projectPartNos"
                :categories="categories"
                :departments="departments"
                :causes="causes"
                :employeeCause="employeeCause"
                :providerCause="providerCause"
                :employees="employees"
                :providers="providers"
                :newSytelineProjectNumber="newSytelineProjectNumber"
                :isSpinnerLoading="isSpinnerLoading"
                @setProjectInfo="emitSetProjectInfo"
                @changeNoAssemblage="emitChangeNoAssemblage($event)"
                @addNewProject="emitAddNewProject"
                @applyTransition="emitApplyTransition"
                @searchNoAssemblage="emitSearchNoAssemblage($event)"
                @checkUp="emitCheckUp"
                @resolutionMembersList="emitResolutionMembersList($event)"
            />

            <!-- images and files upload -->
            <!-- NOTE:
               - all images methods are in the mixin imagesMixin.js
               - all files methods are in the mixin filesMixin.js
           -->
            <v-layout>
                <!-- images -->
                <v-flex xs6 wrap>
                     <ImagesUpload
                         :showUploadButton="true"
                         :showDeleteButton="true"
                         imagesOrigin="structure"
                         label="Téléchargement Images"
                         buttonLabel="Images"
                         :isDisabled="isDisabled"
                         :images="imagesStructure"
                         @uploadImage="emitUploadImage"
                         @popUpImage="emitPopUpImage"
                         @removeImage="emitRemoveImage"
                     />
                 </v-flex>

                <!-- files -->
                <v-flex xs6 wrap>
                    <FilesUpload
                        :showUploadButton="true"
                        :showDeleteButton="true"
                        filesOrigin="structure"
                        label="Téléchargement Fichiers"
                        buttonLabel="Fichiers"
                        :isDisabled="isDisabled"
                        :files="nonConformite.ncFichiers"
                        @uploadFile="emitUploadFile"
                        @removeFile="emitRemoveFile"
                    />
                </v-flex>
            </v-layout>
        </div>

        <!-- APPROVE OR REJECT DEMAND SECTION -->
        <div class="shadow pa-2 my-3">
            <h2>Décision demande / Superviseur</h2>

            <v-layout row wrap v-if="nonConformite.projet === null || tryToCreateProjectFail || nonConformite.projet?.length === 0 && nonConformite.etat === NON_CONFORMITES_STORE.etats.SOLUTION_ACCEPTEE">
                <v-flex xs12 md6>
                    <v-btn style="background-color:black;color:white;" @click="emitAddNewProject()">(&nbsp;<span style="color:pink">Bouton d'urgence</span>&nbsp;)&nbsp;&nbsp;Création projet dans syteline</v-btn>
                    <p class="pl-2 pb-0 mb-0" style="color:red">*Ce bouton apparait lors d'un échec de création de projet après l'abbrobation d'une demande par un superviseur. </p>
                    <p class="pl-2 mt-0 pt-0" style="color:red"> Si le problème persite, contactez rapidement le support informatique de Stageline.</p>
                </v-flex>
            </v-layout>

            <v-layout row wrap v-if="isGNCSupervisor && nonConformite.etat === NON_CONFORMITES_STORE.etats.DEMANDE">
                <!-- reject demand button -->
                <!-- you have to enter a reject reason in the text area below for the button to be enabled -->
                <v-flex xs12 md6>
                    <v-btn
                        color="error"
                        @click="emitApplyTransition(NON_CONFORMITES_STORE.transitions.REJETER_DEMANDE, 'QUALITE_REJECT_DEMAND')"
                        :loading="isLoadingTransition"
                        class="mx-0"
                        :disabled="!canApplyTransition(NON_CONFORMITES_STORE.transitions.REJETER_DEMANDE) || !nonConformite.raisonRefus || isDisabled"
                    >
                        Refuser la demande
                    </v-btn>
                </v-flex>

                <!-- approve demand button -->
                <v-flex xs12 md6>
                    <v-btn
                        @click="emitApplyTransition(nonConformite.solutionRequise ? NON_CONFORMITES_STORE.transitions.APPROUVER_DEMANDE : NON_CONFORMITES_STORE.transitions.APPROUVER_DEMANDE_NO_SOLUTION, 'QUALITE_APPROUVED_SUP');"
                        :loading="isLoadingTransition"
                        class="mx-0"
                        :disabled="!canApplyTransition(NON_CONFORMITES_STORE.transitions.APPROUVER_DEMANDE) || isDisabled"
                        color="success"
                    >
                        Approuver la demande
                    </v-btn>
                </v-flex>

                <!-- rejection reason -->
                <v-flex xs12>
                    <v-textarea
                        height="100"
                        v-model="nonConformite.raisonRefus"
                        label="Raison du refus"
                        :readonly="readOnly"
                    />
                </v-flex>
            </v-layout>
        </div>

        <!-- APPROVE OR REJECT SOLUTION SECTION-->
        <div class="shadow pa-2 my-3" v-if="isGNCQualite && !!nonConformite.solutionRequise">
            <h2>Décision solution / Qualité</h2>

            <v-layout row wrap>
                <!-- reject solution button -->
                <!-- you have to enter an alternate solution in the text area below for the button to be enabled -->
                <v-flex xs12 md6>
                    <v-btn
                        class="mx-0"
                        @click="emitApplyTransition(NON_CONFORMITES_STORE.transitions.REJETER_SOLUTION, 'QUALITE_REJECT')"
                        :loading="isLoadingTransition"
                        :disabled="!canApplyTransition(NON_CONFORMITES_STORE.transitions.REJETER_SOLUTION) || !nonConformite.solutionAlternative || isDisabled"
                        color="red"
                        dark
                    >
                        Refuser la solution
                    </v-btn>
                </v-flex>

                <!-- approve solution button -->
                <v-flex xs12 md6>
                    <v-btn
                        class="mx-0"
                        @click="emitApplyTransition(NON_CONFORMITES_STORE.transitions.ACCEPTER_SOLUTION, 'QUALITE_APPROUVED')"
                        :loading="isLoadingTransition"
                        :disabled="!canApplyTransition(NON_CONFORMITES_STORE.transitions.ACCEPTER_SOLUTION) || isDisabled"
                        color="success"
                    >
                        Approuver la solution
                    </v-btn>
                </v-flex>

                <!-- alternate solution  -->
                <v-flex xs12>
                    <v-textarea
                        v-model="nonConformite.solutionAlternative"
                        label="Solution alternative"
                        :readonly="readOnly"
                    />
                </v-flex>
            </v-layout>
        </div>

        <!-- SOLUTION DETAILS SECTION -->
        <div class="shadow pa-2 my-3" v-if="isGNCSupervisor">
            <h2>Détails solution / Superviseur</h2>

            <!-- TIME ESTIMATE -->
            <v-layout row wrap>
                <!-- date picker dialog -->
                <v-flex xs12 sm6>
                    Impact délai de production:
                    <v-icon v-show="!showIcons" large class="mr-3 ml-5" @click="setGravite(1)" :color="nonConformite.gravite === 1 ? 'success' : ''">fas fa-thermometer-empty</v-icon>
                    <v-icon v-show="!showIcons" large class="mr-3" @click="setGravite(2)" :color="nonConformite.gravite === 2 ? 'warning' : ''">fas fa-thermometer-half</v-icon>
                    <v-icon v-show="!showIcons" large class="mr-3" @click="setGravite(3)" :color="nonConformite.gravite === 3 ? 'error' : ''">fas fa-thermometer-full</v-icon>
                    <!-- Easter egg :P -->
                    <v-icon v-show="showIcons" medium class="mr-3 ml-5 mt-2" @click="setGravite(1)" :color="nonConformite.gravite === 1 ? 'success' : 'brown'">fas fa-poop</v-icon>
                    <v-icon v-show="showIcons" medium class="mr-3 mt-2" @click="setGravite(2)" :color="nonConformite.gravite === 2 ? 'warning' : 'brown'">fas fa-poop</v-icon>
                    <v-icon v-show="showIcons" medium class="mr-3 mt-2" @click="setGravite(3)" :color="nonConformite.gravite === 3 ? 'error' : 'brown'">fas fa-poop</v-icon>
                </v-flex>

                <v-flex xs12 sm6 md6 lg4>
                    <v-dialog
                        ref="dialog"
                        v-model="datePickerDialog"
                        :return-value.sync="nonConformite.dateEcheance"
                        persistent
                        lazy
                        full-width
                        width="290px"
                        :disabled="isDisabled"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="nonConformite.dateEcheance"
                                label="Date d'échéance"
                                prepend-icon="event"
                                readonly
                                v-on="on"
                            />
                        </template>

                        <v-date-picker v-model="nonConformite.dateEcheance" scrollable locale="fr-CA">
                            <v-spacer/>

                            <v-btn flat color="primary" @click="datePickerDialog = false">Cancel</v-btn>
                            <v-btn flat color="primary" @click="$refs.dialog.save(nonConformite.dateEcheance)">OK</v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-flex>
            </v-layout>

            <!-- SOLUTION IMAGES AND FILES -->
            <!-- NOTE:
               - all images methods are in the mixin imagesMixin.js
               - all files methods are in the mixin filesMixin.js
            -->
            <v-layout mt-4 row wrap v-if="isGNCSupervisor">
                <!-- solution images -->
                <v-flex xs6 wrap>
                    <ImagesUpload
                        :showUploadButton="true"
                        :showDeleteButton="true"
                        imagesOrigin="solution"
                        label="Téléchargement Images Solution"
                        buttonLabel="Images"
                        :isDisabled="isDisabled"
                        :images="imagesSolution"
                        @uploadImage="emitUploadImageSolution"
                        @popUpImage="emitPopUpImage"
                        @removeImage="emitRemoveImage"
                    />
                </v-flex>

                <!-- solution files -->
                <v-flex xs6 wrap>
                    <FilesUpload
                        :showUploadButton="true"
                        :showDeleteButton="true"
                        filesOrigin="solution"
                        label="Téléchargement Fichiers Solution"
                        buttonLabel="Fichiers"
                        :isDisabled="isDisabled"
                        :files="nonConformite.ncFichiersSolution"
                        @uploadFile="emitUploadFileSolution"
                        @removeFile="emitRemoveFile"
                    />
                </v-flex>
            </v-layout>
            <!-- SUBMIT WORK BUTTON -->
            <v-layout row wrap v-if="isGNCSupervisor">
                <v-flex xs6>
                    <v-btn
                        @click="emitApplyTransition(NON_CONFORMITES_STORE.transitions.SOUMETTRE_TRAVAIL, 'QUALITE_COMPLETED')"
                        :loading="isLoadingTransition"
                        :disabled="!canApplyTransition(NON_CONFORMITES_STORE.transitions.SOUMETTRE_TRAVAIL) || isDisabled || imagesSolution.length === 0"
                        color="info"
                    >
                        Travail complété
                    </v-btn>
                </v-flex>
            </v-layout>
        </div>

        <!-- APPROVE OR REJECT WORK SECTION -->
        <div class="shadow pa-2 my-3" v-if="isGNCQualite">
            <h2>Décision finale / Qualité</h2>

            <v-layout row wrap v-if="isGNCQualite">
                <!-- reject work button -->
                <v-flex xs12 md6>
                    <v-btn
                        @click="emitApplyTransition(NON_CONFORMITES_STORE.transitions.REJETER_TRAVAIL, 'QUALITE_REJECT')"
                        :loading="isLoadingTransition"
                        :disabled="!canApplyTransition(NON_CONFORMITES_STORE.transitions.REJETER_TRAVAIL) || isDisabled"
                        color="red darken-2"
                        class="white--text"
                    >
                        Solution complétée refusée
                    </v-btn>
                </v-flex>

                <v-flex xs12 md6>
                    <!-- approved work button -->
                    <v-btn
                        @click="emitApplyTransition(NON_CONFORMITES_STORE.transitions.APPROUVER_TRAVAIL, 'QUALITE_COMPLETED')"
                        :loading="isLoadingTransition"
                        :disabled="!canApplyTransition(NON_CONFORMITES_STORE.transitions.APPROUVER_TRAVAIL) || isDisabled"
                        color="green"
                        class="white--text"
                    >
                        Solution complétée résolue
                    </v-btn>
                </v-flex>
            </v-layout>
        </div>

        <!-- CORRECTIVES ACTIONS SECTION -->
        <div class="shadow pa-2 my-3">
            <!-- correctives actions -->
            <!-- NOTE: all correctives actions methods are in the mixin correctivesActionsMixin.js -->
            <CorrectivesActions
                v-if="isGNCSupervisor"
                :readOnly="readOnly"
                :isDisabled="isDisabled"
                :showAutocomplete="true"
                :showCompletionButtons="isGNCQualite"
                :isCorrectiveActionRequired="nonConformite.actionCorrectiveRequise"
                :proposedCorrectiveAction="nonConformite.actionCorrectiveProposee"
                :isCorrectiveActionCompleted="nonConformite.actionCorrectiveComplete"
                :correctivesActions="correctivesActions"
                @setCorrectiveActionRequired="setCorrectiveActionRequired"
                @setProposedCorrectiveAction="setProposedCorrectiveAction"
                @setCorrectiveActionCompleted="setCorrectiveActionCompleted"
                @insertIntoProposedCorrectiveActions="insertIntoProposedCorrectiveActions"
            />
            <v-layout
                v-if="nonConformite.actionCorrectiveRequise"
                row
                wrap
            >
                <v-flex xs12 class="mt-5">
                    <h2>Utilisateurs en suivi pour action corrective</h2>
                    <NCAbonnements
                        :non-conformite="nonConformite"
                        :items="nonConformite.abonnesActionCorrective"
                        :readonly="readOnly"
                        type="action-corrective"
                        :users="usersActionCorrective"
                        @change="emitAbonnementActionCorrective"
                    />
                </v-flex>
            </v-layout>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    //states and transitions constants
    import {  NON_CONFORMITES_STORE } from "../../constants";

    //controls
    import ImagesUpload from '../controls/ImagesUpload';
    import FilesUpload from "../controls/FilesUpload";
    import CorrectivesActions from "../controls/CorrectivesActions";
    import DemandDetails from "../controls/DemandDetails";
    import NCAbonnements from "../controls/NC-Abonnements";

    //mixins
    import correctivesActionsMixin from '../../mixins/correctivesActionsMixin';
    import transitionsMixin from "../../mixins/transitionsMixin";
    import projectMixin from "../../mixins/projectMixin";
    import imagesMixin from "../../mixins/imagesMixin";
    import filesMixin from "../../mixins/filesMixin";
    import entrepriseStore from "../../store/entreprise/entrepriseStore";

    export default {
        name: "CreateQualite",
        props: [
            'nonConformite',
            'isDisabled',
            'readOnly',
            'isLoadingTransition',
            'correctivesActions',
            'isGNCQualite',
            'isGNCSupervisor',
            'isGNCAdmin',
            'employeeListSIGM',
            'imagesStructure',
            'imagesSolution',
            'projectIncludesUnit',
            'projectMessages',
            'projectErrorMessages',
            'stageUnits',
            'stageModels',
            'categories',
            'departments',
            'causes',
            'employeeCause',
            'providerCause',
            'employees',
            'providers',
            'partNos',
            'projectPartNos',
            'newSytelineProjectNumber',
            'tryToCreateProjectFail',
            'isSpinnerLoading'
        ],
        mixins: [
            correctivesActionsMixin,
            transitionsMixin,
            projectMixin,
            imagesMixin,
            filesMixin,
        ],
        components: {
            NCAbonnements,
            //controls
            ImagesUpload,
            FilesUpload,
            CorrectivesActions,
            DemandDetails,
        },
        created() {
            window.addEventListener('keydown', this.toggleIcons);
            window.addEventListener('keyup', this.keyup);
        },
        beforeDestroy() {
            window.removeEventListener('keydown', this.toggleIcons);
            window.removeEventListener('keyup', this.keyup);
        },
        computed: {
            ...mapState({
                entrepriseStore: state => state.entrepriseStore,
                loginStore: state => state.loginStore
            }),

            isGNCReception() {
                return Array.isArray(this.loginStore.loggedUser.groups) && !!this.loginStore.loggedUser.groups.find(g => {
                    return g.name === 'gnc_admins'
                });
            },

            usersActionCorrective() {
                return this.entrepriseStore.users.filter(user => {
                    return (user.groups.find(group => group.name === 'gnc_superviseurs' || group.name === 'gnc_qualite' || group.name === 'gnc_admins')) != null;
                });
            },
        },
        data: function() {
            return {
                showIcons: false,
                kKeyPressed: false,

                //constant to get states and transitions
                NON_CONFORMITES_STORE: NON_CONFORMITES_STORE,

                //time estimate validation rules
                timeEstimateRules: [
                    v => /^[0-9]{0,3}$/.test(v) || !v || 'temps invalide',
                ],

                //date picker dialog
                datePickerDialog: false,
            }
        },
        methods: {
            toggleIcons(event) {
                if (event.ctrlKey && event.key === 'k') {
                    this.kKeyPressed = true;
                    this.showIcons = !this.showIcons;
                }
            },
            keyup(event) { // To ensure K press flag is reset
                if (event.key === 'k') {
                    this.kKeyPressed = false;
                }
            },

            setGravite(gravite) {
                if (!this.readOnly) {
                    this.nonConformite.gravite = gravite;
                }
            },

            //checks if a transition can be applied (returns a boolean)
            canApplyTransition(transition) {
                let retval = false;
                switch (transition) {
                    case NON_CONFORMITES_STORE.transitions.APPROUVER_DEMANDE:
                        retval = this.isGNCSupervisor && this.nonConformite.etat === NON_CONFORMITES_STORE.etats.DEMANDE;
                        break;
                    case NON_CONFORMITES_STORE.transitions.REJETER_DEMANDE:
                        retval = this.isGNCQualite && this.nonConformite.etat === NON_CONFORMITES_STORE.etats.DEMANDE;
                        break;
                    case NON_CONFORMITES_STORE.transitions.ACCEPTER_SOLUTION:
                        retval = this.isGNCQualite && (this.nonConformite.etat === NON_CONFORMITES_STORE.etats.ATTENTE_SOLUTION);
                        break;
                    case NON_CONFORMITES_STORE.transitions.REJETER_SOLUTION:
                        retval = this.isGNCQualite && (this.nonConformite.etat === NON_CONFORMITES_STORE.etats.ATTENTE_SOLUTION);
                        break;
                    case NON_CONFORMITES_STORE.transitions.SOUMETTRE_TRAVAIL:
                        retval = this.isGNCSupervisor && (this.nonConformite.etat === NON_CONFORMITES_STORE.etats.SOLUTION_ACCEPTEE || this.nonConformite.etat === NON_CONFORMITES_STORE.etats.TRAVAIL_REJETE || this.nonConformite.etat === NON_CONFORMITES_STORE.etats.SOLUTION_REJETEE);
                        break;
                    case NON_CONFORMITES_STORE.transitions.APPROUVER_TRAVAIL:
                        retval = this.isGNCQualite && (this.nonConformite.etat === NON_CONFORMITES_STORE.etats.TRAVAIL_A_APPROUVER);
                        break;
                    case NON_CONFORMITES_STORE.transitions.REJETER_TRAVAIL:
                        retval = this.isGNCQualite && (this.nonConformite.etat === NON_CONFORMITES_STORE.etats.TRAVAIL_A_APPROUVER);
                        break;
                    default:
                        break;
                }
                return retval;
            },

            emitAbonnementActionCorrective(event) {
                this.$emit('abonnementActionCorrective', event);
            }
        }
    }
</script>

<style scoped>

</style>
