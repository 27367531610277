<template>
    <div>
        <!-- DEMANDE DETAILS SECTION -->
        <div class="shadow pa-2 my-3">
            <!-- details -->
            <!-- NOTE:
                - all project methods are in the mixin projectMixin.js
                - all transitions methods are in the mixin transitionsMixin.js
            -->
            <DemandDetails
                :nonConformite="nonConformite"
                titleLabel="Question"
                descriptionLabel="Observation"
                :readOnly="readOnly"
                :isDisabled="isDisabled"
                :showNcParams="false"
                :showSolutionRequired="false"
                :showStatesAndCriticalitySelectBox="true"
                :statesList="statesSAV"
                :criticalityList="criticalitySAV"
                :isGNCQualite="isGNCQualite"
                :isGNCAdmin="isGNCAdmin"
                :isGNCSupervisor="isGNCSupervisor"
                :projectIncludesUnit="projectIncludesUnit"
                :projectMessages="projectMessages"
                :projectErrorMessages="projectErrorMessages"
                :stageUnits="stageUnits"
                :stageModels="stageModels"
                :partNos="partNos"
                :projectPartNos="projectPartNos"
                @setProjectInfo="emitSetProjectInfo"
                @changeNoAssemblage="emitChangeNoAssemblage"
                @applyTransition="emitApplyTransition"
            />

            <!-- images upload -->
            <!-- NOTE: all images methods are in the mixin imagesMixin.js -->
            <v-layout class="mt-5">
                <!-- images -->
                <v-flex xs6 wrap>
                    <ImagesUpload
                        :showUploadButton="true"
                        :showDeleteButton="true"
                        imagesOrigin="structure"
                        label="Images observation"
                        buttonLabel="Images"
                        :isDisabled="isDisabled"
                        :images="imagesStructure"
                        @uploadImage="emitUploadImage"
                        @popUpImage="emitPopUpImage"
                        @removeImage="emitRemoveImage"
                    />
                </v-flex>
            </v-layout>

            <!-- tags -->
            <!-- NOTE: all tags methods are in the mixin tagsMixin.js -->
            <TagsSelector
                class="mt-5"
                :tags="nonConformite.tags"
                @openTagsDialog="tagsDialog = true"
            />
        </div>

        <!-- CORRECTIVES ACTIONS SECTION -->
        <div class="shadow pa-2 my-3">
            <!-- correctives actions -->
            <!-- NOTE: all correctives actions methods are in the mixin correctivesActionsMixin.js -->
            <CorrectivesActions
                v-if="isGNCSupervisor"
                :readOnly="readOnly"
                :isDisabled="isDisabled"
                :showAutocomplete="true"
                :isCorrectiveActionRequired="nonConformite.actionCorrectiveRequise"
                :proposedCorrectiveAction="nonConformite.actionCorrectiveProposee"
                :correctivesActions="correctivesActions"
                @setCorrectiveActionRequired="setCorrectiveActionRequired"
                @setProposedCorrectiveAction="setProposedCorrectiveAction"
                @insertIntoProposedCorrectiveActions="insertIntoProposedCorrectiveActions"
            />

            <!-- solution images and files -->
            <!-- NOTE:
                - all images methods are in the mixin imagesMixin.js
                - all files methods are in the mixin filesMixin.js
            -->
            <v-layout mt-4 row wrap v-if="isGNCSupervisor && nonConformite.actionCorrectiveRequise">
                <!-- solution images -->
                <v-flex xs6 wrap>
                    <ImagesUpload
                        :showUploadButton="true"
                        :showDeleteButton="true"
                        imagesOrigin="solution"
                        label="Images action corrective"
                        buttonLabel="Images"
                        :isDisabled="isDisabled"
                        :images="imagesSolution"
                        @uploadImage="emitUploadImageSolution"
                        @popUpImage="emitPopUpImage"
                        @removeImage="emitRemoveImage"
                    />
                </v-flex>

                <!-- solution files -->
                <v-flex xs6 wrap>
                    <FilesUpload
                        :showUploadButton="true"
                        :showDeleteButton="true"
                        filesOrigin="solution"
                        label="Fichiers action corrective"
                        buttonLabel="Fichiers"
                        :isDisabled="isDisabled"
                        :files="nonConformite.ncFichiersSolution"
                        @uploadFile="emitUploadFileSolution"
                        @removeFile="emitRemoveFile"
                    />
                </v-flex>
            </v-layout>
        </div>

        <!-- ESTIMATE SECTION (SAV) -->
        <div class="shadow pa-2 my-3">
            <h2>Estimé</h2>

            <v-layout row wrap>
                <v-flex xs12>
                    <EstimeTable :items="nonConformite.estime" @change="nonConformite.estime = $event"/>
                </v-flex>
            </v-layout>
        </div>

        <!-- CLIENT IMAGES AND FILES UPLOADS (clients upload files and images from Gallup. In GNC, we only show what has been uploaded) -->
        <!-- NOTE:
               - all images methods are in the mixin imagesMixin.js
               - all files methods are in the mixin filesMixin.js
           -->
        <div class="shadow pa-2 my-3">
            <h2>Résolution</h2>

            <v-layout row wrap>
                <!-- client images -->
                <v-flex xs12 md6>
                    <ImagesUpload
                        :showUploadButton="false"
                        :showDeleteButton="false"
                        imagesOrigin="client"
                        label="Images client"
                        buttonLabel="Images"
                        :isDisabled="true"
                        :images="nonConformite.imagesClientFolder"
                        @popUpImage="emitPopUpImage"
                    />
                </v-flex>

                <!-- client files -->
                <v-flex xs6 wrap>
                    <FilesUpload
                        :showUploadButton="false"
                        :showDeleteButton="false"
                        filesOrigin="client"
                        label="Fichiers client"
                        buttonLabel="Fichiers"
                        :isDisabled="true"
                        :files="nonConformite.ncFichiersClient"
                    />
                </v-flex>
            </v-layout>
        </div>

        <!-- DIALOGS -->
        <!-- tags dialog -->
        <v-dialog v-model="tagsDialog">
            <TagsTable
                :useAsSelector="true"
                :nonConformiteTags="nonConformite.tags"
                @setTagsToAddSelection="setTagsToAddSelection"
            />
            <v-spacer/>

            <v-btn @click="tagsDialog = false">Fermer</v-btn>
        </v-dialog>
    </div>
</template>

<script>
    import EstimeTable from "../EstimeTable";

    //controls
    import DemandDetails from "../controls/DemandDetails";
    import ImagesUpload from '../controls/ImagesUpload';
    import FilesUpload from "../controls/FilesUpload";
    import CorrectivesActions from "../controls/CorrectivesActions";
    import TagsTable from "../controls/TagsTable";
    import TagsSelector from "../controls/TagsSelector";

    //mixins
    import correctivesActionsMixin from '../../mixins/correctivesActionsMixin';
    import transitionsMixin from "../../mixins/transitionsMixin";
    import projectMixin from "../../mixins/projectMixin";
    import imagesMixin from "../../mixins/imagesMixin";
    import filesMixin from "../../mixins/filesMixin";
    import tagsMixin from "../../mixins/tagsMixin";

    export default {
        name: "CreateSav",
        props: [
            'nonConformite',
            'isDisabled',
            'readOnly',
            'correctivesActions',
            'isGNCQualite',
            'isGNCAdmin',
            'isGNCSupervisor',
            'imagesStructure',
            'imagesSolution',
            'projectIncludesUnit',
            'projectMessages',
            'projectErrorMessages',
            'stageUnits',
            'stageModels',
            'partNos',
            'projectPartNos'
        ],
        mixins: [
            correctivesActionsMixin,
            transitionsMixin,
            projectMixin,
            imagesMixin,
            filesMixin,
            tagsMixin
        ],
        components: {
            EstimeTable,

            //controls
            DemandDetails,
            ImagesUpload,
            FilesUpload,
            CorrectivesActions,
            TagsTable,
            TagsSelector,
        },
        data: function() {
            return {
                //tags dialog v-model
                tagsDialog: false,

                //states SAV
                statesSAV: [
                    { value:  'sav_new', text: 'Nouveau' },
                    { value:  'sav_verification_required', text: 'Vérification requise' },
                    { value:  'sav_verification_opened', text: 'Vérification ouverte' },
                    { value:  'sav_verification_closed', text: 'Vérification terminé' },
                    { value:  'sav_in_treatment', text: 'En traitement' },
                    { value:  'sav_submit_to_client', text: 'Soumis au client' },
                    { value:   'sav_closed', text: 'Terminé' },
                ],

                //criticality SAV
                criticalitySAV: [
                    { value: 1, text: '1 - Comme neuf' },
                    { value: 2, text: '2 - Esthétique' },
                    { value: 3, text: '3 - Non fonctionnel' },
                    { value: 4, text: '4 - Non fonctionnel, empêche certification' },
                    { value: 5, text: '5 - Réside au sol' },
                ],
            }
        },
    }
</script>

<style scoped>

</style>
