import Vue from 'vue'
import Vuex from 'vuex'

import loginStore from './login/loginStore'
import nonConformiteStore from './nonConformite/nonConformiteStore'
import entrepriseStore from './entreprise/entrepriseStore'          //Store de l'entreprise Stageline
import applicationStore from './applicationStore/applicationStore'  //Store de l'application, infos centrale
import tagsStore from "./tags/tagsStore";
import correctivesActionsStore from "./CorrectivesActions/correctivesActionsStore";
import categoriesStore from "./categories/categoriesStore";
import causesStore from "./causes/causesStore";
import departmentsStore from "./departments/departmentsStore";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
      loginStore,
      nonConformiteStore,
      entrepriseStore,
      applicationStore,
      tagsStore,
      correctivesActionsStore,
      categoriesStore,
      causesStore,
      departmentsStore,
  }
});
