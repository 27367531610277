import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home'
import Dashboards from './views/Dashboards';
import PrintNCList from "./views/PrintNCList";
import Notifications from './views/Notifications';
import Admin from './views/Admin'
import Create from './views/nonConformites/Create'
import store from './store/store.js'
import { LOGIN_STORE, NON_CONFORMITES_STORE, ENTREPRISE_STORE , APPLICATION_STORE, ENV } from "./constants";
import $axios from './api';
import Rapports from "./views/Rapports";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: Dashboards,
            meta: {
            requiresAuth: true
            },
            props: true
        },
        //print NC list
        {
            path: '/print',
            name: 'print',
            component: PrintNCList,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/nc/create',
            name: 'create',
            component: Create,
            meta: {
              requiresAuth: true,
            }
        },
        {
            path: '/nc/edit/:ncId',
            name: 'edit',
            component: Create,
            meta: {
              requiresAuth: true
            }
        },
            {
              path: '/notifications',
              name: 'notifications',
              component: Notifications,
              meta: {
                  requiresAuth: true
              }
            },
        {
            path: '/administration',
            name: 'administration',
            component: Admin,
            meta: {
            requiresAuth: true,
            is_admin : true
            }
        },
        {
            path: '/rapports',
            name: 'rapports',
            component: Rapports,
            meta: {
              requiresAuth: true,
              is_admin: true
            }
        },
    ]
});

//Caller apres router.beforeEach
router.beforeResolve((to, from, next) => {
    if(to.name.toLowerCase() === 'home' && from.name === null){
        let token = store.getters[LOGIN_STORE.getters.GET_ACCESS_TOKEN];
        if(!!token) {
            $axios({
                method: 'get',
                url: `/auth/user`,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            }).then( response => {
                let lastPage = JSON.parse(localStorage.getItem('lastPage'));
                let params = JSON.parse(lastPage.params);
                let query = JSON.parse(lastPage.query);
                let infos = {
                    user: response.data.user
                };

                //Update l'info du user dans le store
                store.commit(LOGIN_STORE.mutations.UPDATE_LOGIN_INFOS, infos);
                router.push(
                    {
                        name: lastPage.name,
                        params: params,
                        query: query
                    });


            }).catch(error => {
                next();
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {

    if(to.name.toLowerCase() !== "home"){
        localStorage.setItem('lastPage', JSON.stringify({
                    fullPath: to.fullPath,
                    name: to.name,
                    params: JSON.stringify(to.params),
                    query: JSON.stringify(to.query)
                }
        ));
    }

    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters[LOGIN_STORE.getters.LOGGED_IN] === false) {
            next({
                path: '/',
                params: { nextUrl: to.fullPath }
            });
        } else if (to.matched.some(record => record.meta.is_admin)) {
            //valid si le user est dans le group ADMIN
            if (store.getters[LOGIN_STORE.getters.IS_GNC_ADMIN] === true) {
                next();
            } else {
                //Usager n'est pas dans groupe admin, on redirect vers dashboard
                next({
                    path: '/dashboard',
                    params: { nextUrl: to.fullPath }
                });
            }
        } else {
            next();
        }
    } else { // la route n'a pas besoin requireAuth, définit dans router
        next();
    }
})

export default router
